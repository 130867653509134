import { Presets, RadiusOptions } from '../../core';

export interface BreadcrumbItemPreset {
  fontWeight?: 'regular' | 'bold';
  radius?: RadiusOptions;
  withoutUnderline?: boolean;
}

export const breadcrumbItemPresets: Presets<BreadcrumbItemPreset> = {
  default: {
    withoutUnderline: true,
  },
  cx: {},
  rx: {},
  hw: {},
};
