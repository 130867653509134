import { Presets } from './../../core';

export interface AlertPreset {}

export const alertPresets: Presets<AlertPreset> = {
  default: {},
  cx: {},
  rx: {},
  hw: {},
};
