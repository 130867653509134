import { property, queryAssignedElements, query, state } from 'lit/decorators.js';
import { SlotController, isValidResizeController, MteContentBase } from '../../core';
import '../icon';
import { ResizeController } from '@lit-labs/observers/resize-controller.js';
import type { MteNavTab } from './nav-tab.element';
import type { MteTab } from './tab.element';

export class MteTabsBase extends MteContentBase {
  /**
   * Manages whether tabList is scrollable
   */
  isScrollable: boolean;

  @state() scrollLeft: number;

  /**
   * Specify whether scroll left button should be visible
   */
  @property({ reflect: true, type: Boolean }) nextVisible = false;

  /**
   * Specify whether scroll right button should be visible
   */
  @property({ reflect: true, type: Boolean }) previousVisible = false;

  /**
   * Specify the orientation of tabs.
   */
  @property({ reflect: true }) orientation: 'vertical' | 'horizontal' = 'horizontal';

  /** Queries for all MteNavTab elements  */
  @queryAssignedElements({ slot: 'nav' }) tabs!: Array<MteNavTab | MteTab>;

  @query('.tab-list') tabList: HTMLDivElement;

  slotController = new SlotController(this, { updateOnChange: ['nav'] });

  observer?: ResizeController<void>;

  connectedCallback() {
    super.connectedCallback();

    this.observer = new ResizeController(this, {
      callback: () => {
        this.handleResize();
      },
    });
    // Wait until first update after connected
    this.updateComplete.then(() => {
      if (this.tabList) {
        this.observer?.observe(this.tabList);
        this.tabList.addEventListener('transitionend', () => {
          this.removeTransition();
        });
      }
    });
  }

  disconnectedCallback() {
    super.disconnectedCallback();

    if (this.tabList && isValidResizeController(this.observer)) {
      this.observer?.unobserve(this.tabList);
    }

    this.tabList?.removeEventListener('transitionend', this.removeTransition);
  }

  updated(changedProperties) {
    super.updated(changedProperties);

    this.setIsScrollable();
    this.setNextVisible();
    this.setPreviousVisible();
  }

  /** Removes classname responsible for transition to prevent animation on resize */
  removeTransition() {
    this.tabList?.classList.remove('selected');
  }

  setScrollLeft(scrollLeft) {
    this.scrollLeft = scrollLeft;
  }

  setIsScrollable() {
    this.isScrollable = this.tabList.scrollWidth > this.tabList.clientWidth;
  }

  setNextVisible() {
    if (
      this.isScrollable &&
      this.tabList.scrollLeft + this.tabList.clientWidth + 16 < this.tabList.scrollWidth
    ) {
      this.nextVisible = true;
    } else {
      this.nextVisible = false;
    }
  }

  setPreviousVisible() {
    if (this.isScrollable && this.tabList.scrollLeft > 0) {
      this.previousVisible = true;
    } else {
      this.previousVisible = false;
    }
  }

  handleNextScrollClick() {
    const averageTabWidth = Math.round(this.tabList.scrollWidth / this.tabs.length);
    this.tabList.scrollLeft += averageTabWidth * 1.5;

    this.setScrollLeft(this.tabList.scrollLeft);
  }

  handlePreviousScrollClick() {
    const averageTabWidth = Math.round(this.tabList.scrollWidth / this.tabs.length);
    this.tabList.scrollLeft -= averageTabWidth * 1.5;
    this.setScrollLeft(this.tabList.scrollLeft);
  }

  handleScroll() {
    if (this.orientation === 'horizontal') {
      let timeoutId = null;

      return () => {
        if (timeoutId !== null) {
          clearTimeout(timeoutId);
        }

        timeoutId = setTimeout(() => {
          this.setScrollLeft(this.tabList.scrollLeft);
          timeoutId = null;
        }, 100);
      };
    }
  }

  handleResize() {
    if (this.orientation !== 'vertical') {
      this.setIsScrollable();
      if (this.isScrollable) {
        this.setNextVisible();
        this.setPreviousVisible();
      }
      return;
    }
  }
}
