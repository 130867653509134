import { html } from 'lit';
import { property } from 'lit/decorators.js';
import { MteElement, defineElement, baseStyles } from '../../core';
import { styles } from './list-header.styles';

let nextUniqueId = 0;

/**
 * @slot - Default slotted content
 */
@defineElement('mte-list-header')
export class MteListHeader extends MteElement {
  static styles = [baseStyles, styles];

  /** Whether or not this item should render with `filled` styling. */
  @property({ type: Boolean, reflect: true }) filled = false;

  /** Whether or not this item should sticky to the top of the list while scrolling. Will render with `filled` styling if set. */
  @property({ type: Boolean, reflect: true }) sticky = false;

  /** A unique ID used to associated this header to a parent mte-list-group */
  @property({ reflect: true }) id = `mte-list-header--${nextUniqueId++}`;

  render() {
    return html`${this.instanceStyles}<slot></slot>`;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'mte-list-header': MteListHeader;
  }
}
