import { html } from 'lit';

import {
  presetProperty,
  CoreColorMixin,
  PresetMixinFactory,
  defineElement,
  buttonStyles,
} from '../../core';
import { MteText } from '../text';
import { ButtonMixin } from '../../core/mixins/button.mixin';
import { styles } from './link.styles';
import { LinkPreset } from './link.presets';
import { classMap } from 'lit/directives/class-map.js';

/**
 * @slot - Default slotted content
 */
@defineElement('mte-link')
export class MteLink extends CoreColorMixin(
  PresetMixinFactory<LinkPreset>('MteLink')(ButtonMixin(MteText))
) {
  static styles = [...MteText.styles, buttonStyles, styles];

  @presetProperty({ reflect: true }) fontWeight: 'regular' | 'bold';

  getButtonClasses() {
    return classMap({ link: true });
  }

  render() {
    return this.renderButtonTemplate(html`<slot></slot>`);
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'mte-link': MteLink;
  }
}
