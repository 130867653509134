import { html } from 'lit';
import { property } from 'lit/decorators.js';
import {
  MteElement,
  defineElement,
  PresetMixinFactory,
  baseStyles,
  RadiusMixin,
  DensityMixin,
  densityStyles,
} from '../../core';
import { styles } from './button-group.styles';
import { ButtonGroupPreset } from './button-group.presets';

/**
 * @slot - Default slotted content
 */
@defineElement('mte-button-group')
export class MteButtonGroup extends DensityMixin(
  RadiusMixin(PresetMixinFactory<ButtonGroupPreset>('MteButtonGroup')(MteElement))
) {
  static styles = [baseStyles, densityStyles, styles];

  /** Whether or not the group should align horizontally or vertically */
  @property({ reflect: true }) orientation: 'horizontal' | 'vertical' = 'horizontal';

  render() {
    return html`${this.instanceStyles}<slot></slot>`;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'mte-button-group': MteButtonGroup;
  }
}
