import { Presets } from './../../core';

export interface AsidePreset {}

export const asidePresets: Presets<AsidePreset> = {
  default: {},
  cx: {},
  rx: {},
  hw: {},
};
