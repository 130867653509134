import { html } from 'lit';
import { property } from 'lit/decorators.js';
import {
  defineElement,
  baseStyles,
  MteContentBase,
  ResponsiveValue,
  ResponsiveValueConverter,
  WaitForFrameworkMixin,
} from '../../core';
import { styles } from './layout-grid.styles';
import { selectorFactory } from '@mortar/styles';

/**
 * @slot - Default slotted content
 */
@defineElement('mte-layout-grid')
export class MteLayoutGrid extends WaitForFrameworkMixin(MteContentBase) {
  static styles = [baseStyles, styles];

  /** Determines the number of columns in the layout grid. Defaults to 12. */
  @property({ reflect: true, converter: ResponsiveValueConverter }) cols?: ResponsiveValue<
    string | number
  >;

  /** Determines the number of rows in the layout grid. */
  @property({ reflect: true, converter: ResponsiveValueConverter }) rows?: ResponsiveValue<
    string | number
  >;

  /** Adjusts `grid-auto-flow`. Defaults to `row`. */
  @property({ reflect: true }) autoFlow: 'row' | 'dense' | undefined;

  protected updateInstanceStyles(changedProps) {
    super.updateInstanceStyles(changedProps);

    if (['cols', 'rows', 'autoFlow'].some((prop) => changedProps.has(prop))) {
      this.setInstanceStyle('mte-grid', selectorFactory(this.instanceStyleSelectorRoot), {
        '--grid-cols': this.cols,
        '--grid-rows': this.rows,
        'grid-auto-flow': this.autoFlow,
      });
    }
  }

  render() {
    return html`${this.instanceStyles}<slot></slot>`;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'mte-layout-grid': MteLayoutGrid;
  }
}
