import { ReactiveController, ReactiveControllerHost } from 'lit';
import { isSsr } from '../utilities';

/**
 * Utilizes a resize observer to watch for changes to an elements size.
 * It will trigger an update whenever the elements size goes from zero width
 * and height to anything else as that signals a display change (from `display: none`).
 *
 * An optional callback can be provided that will also be called when a
 * display change is assumed to have occurred.
 */
export class AutoDisplayUpdateController implements ReactiveController {
  private observer?: ResizeObserver;
  private previousRect: DOMRectReadOnly;

  constructor(private host: ReactiveControllerHost & Element, private callback?: () => any) {
    if (!isSsr()) {
      this.observer = new ResizeObserver((entries: ResizeObserverEntry[]) => {
        try {
          const [e] = entries;
          // If the previous rect existed with no size and the new one does then
          // update because this signifies a potential change in display.
          if (
            this.previousRect &&
            this.previousRect.width === 0 &&
            e.contentRect.width > 0 &&
            this.previousRect.height === 0 &&
            e.contentRect.height > 0
          ) {
            this.callback?.();
            this.host.requestUpdate();
          }
          this.previousRect = e.contentRect;
        } catch (e) {
          // do nothing if there are no entries
        }
      });
    }
    host.addController(this);
  }

  hostConnected() {
    if (!isSsr()) {
      this.previousRect = this.host.getBoundingClientRect();
      this.observer.observe(this.host);
    }
  }

  hostDisconnected() {
    if (!isSsr()) {
      this.observer.disconnect();
    }
  }
}
