import { config } from '@mortar/styles';
import { PropertyMapper, StyleProperty } from './css-in-js.types';

const propertyMap: Record<string, string | string[]> = {
  m: 'margin',
  mx: ['marginLeft', 'marginRight'],
  my: ['marginTop', 'marginBottom'],
  mt: 'marginTop',
  mr: 'marginRight',
  mb: 'marginBottom',
  ml: 'marginLeft',
  p: 'padding',
  px: ['paddingLeft', 'paddingRight'],
  py: ['paddingTop', 'paddingBottom'],
  pt: 'paddingTop',
  pr: 'paddingRight',
  pb: 'paddingBottom',
  pl: 'paddingLeft',
  d: 'display',
  a: 'alignItems',
  j: 'justifyContent',
  direction: 'flexDirection',
  shrink: 'flexShrink',
  grow: 'flexGrow',
  wrap: 'flexWrap',
  w: 'width',
  maxw: 'maxWidth',
  minw: 'minWidth',
  h: 'height',
  maxh: 'maxHeight',
  minh: 'minHeight',
  fs: 'fontSize',
  fw: 'fontWeight',
  bg: 'background',
  c: 'color',
  elevation: 'boxShadow',
};

/** Maps property shorthand values to one or many full css properties */
export const propShorthands: PropertyMapper = (props: StyleProperty[]) => {
  return props.reduce((acc, propMap) => {
    const mappedProps = propertyMap[propMap.prop] ?? propMap.prop;
    return [
      ...acc,
      ...(Array.isArray(mappedProps)
        ? mappedProps.map((prop) => ({ prop, value: propMap.value }))
        : [{ prop: mappedProps, value: propMap.value }]),
    ];
  }, []);
};

/**
 * Returns the @mortar/styles config value for a given dot notated string.
 * Numbers and non-dot notated string will just be returned as is.
 */
export const dotStringToConfigValue = (value: string | number): string | number => {
  if (typeof value === 'string' && value.indexOf('.') !== -1) {
    try {
      const configPath = value.split('.');
      const configValue = configPath.reduce((acc, key) => {
        return acc[key];
      }, config);
      return configValue ?? value;
    } catch (e) {
      return value;
    }
  } else {
    return value;
  }
};

/** Maps dot notated string values to theme config variables */
export const dotStringToConfigValueMapper: PropertyMapper = (props: StyleProperty[]) => {
  return props.reduce((acc, propMap) => {
    const configValue = dotStringToConfigValue(propMap.value);
    if (configValue) {
      propMap.value = configValue ?? propMap.value;
    }
    return [...acc, propMap];
  }, []);
};

export const mapNumberToPx = (val: string | number | null) => {
  if (!val) {
    return val as null;
  }
  return !isNaN(Number(val)) ? `${val}px` : (val as string);
};

export const mapPxToNumber = (val: string | null) => {
  if (!val) {
    return val as null;
  }
  return Number(val.replace('px', ''));
};

/** Maps the values of any given props to px units if it is a number */
export const numberToPx: PropertyMapper = (props: StyleProperty[]) => {
  return props.map((propMap) => {
    const mappedValue = mapNumberToPx(propMap.value);
    return { prop: propMap.prop, value: mappedValue };
  });
};

const spacingMap: Record<string, string> = {
  '0': config.space.none,
  '1': config.space.xs,
  '2': config.space.sm,
  '3': config.space.md,
  '4': config.space.lg,
  '5': config.space.xl,
  '6': config.space.xxl,
  '7': config.space.xxxl,
  'none': config.space.none,
  'xxs': config.space.xxs,
  'xs': config.space.xs,
  'sm': config.space.sm,
  'md': config.space.md,
  'lg': config.space.lg,
  'xl': config.space.xl,
  'xxl': config.space.xxl,
  'xxxl': config.space.xxxl,
};

/** Maps spacing values from designated spacing shorthands */
export const spacingValShorthands: PropertyMapper = (props: StyleProperty[]) => {
  return props.map((propMap) => {
    const mappedValue = spacingMap[propMap.value] ?? propMap.value;
    return { prop: propMap.prop, value: mappedValue };
  });
};

const borderRadiusMap: Record<string, string> = {
  none: config.border.radius.none,
  xs: config.border.radius.xs,
  sm: config.border.radius.sm,
  md: config.border.radius.md,
  lg: config.border.radius.lg,
  xl: config.border.radius.xl,
  xxl: config.border.radius.xxl,
  full: config.border.radius.full,
};

/** Maps border radius values from designated border radius shorthands */
export const borderRadiusValShorthands: PropertyMapper = (props: StyleProperty[]) => {
  return props.map((propMap) => {
    const mappedValue = borderRadiusMap[propMap.value] ?? propMap.value;
    return { prop: propMap.prop, value: mappedValue };
  });
};

const borderWidthMap: Record<string, string> = {
  none: config.border.width.none,
  sm: config.border.width.sm,
  md: config.border.width.md,
  lg: config.border.width.lg,
};

/** Maps border width values from designated border width shorthands */
export const borderWidthValShorthands: PropertyMapper = (props: StyleProperty[]) => {
  return props.map((propMap) => {
    const mappedValue = borderWidthMap[propMap.value] ?? propMap.value;
    return { prop: propMap.prop, value: mappedValue };
  });
};

const opacityMap: Record<string, string> = {
  xxl: config.opacity.xxl,
  xl: config.opacity.xl,
  lg: config.opacity.lg,
  md: config.opacity.md,
  sm: config.opacity.sm,
  xs: config.opacity.xs,
  xxs: config.opacity.xxs,
  xxxs: config.opacity.xxxs,
};

/** Maps opacity values from designated opacity shorthands */
export const opacityValShorthands: PropertyMapper = (props: StyleProperty[]) => {
  return props.map((propMap) => {
    const mappedValue = opacityMap[propMap.value] ?? propMap.value;
    return { prop: propMap.prop, value: mappedValue };
  });
};

const fontWeightMap: Record<string, string> = {
  light: config.font.weight.light,
  regular: config.font.weight.regular,
  medium: config.font.weight.medium,
  bold: config.font.weight.bold,
};

/** Maps font-weight values from designated spacing shorthands */
export const fontWeightValShorthands: PropertyMapper = (props: StyleProperty[]) => {
  return props.map((propMap) => {
    const mappedValue = fontWeightMap[propMap.value] ?? propMap.value;
    return { prop: propMap.prop, value: mappedValue };
  });
};

const fontSizeMap: Record<string, string> = {
  xxs: '0.625rem',
  xs: '0.75rem',
  sm: '0.875rem',
  md: '1rem',
  lg: '1.25rem',
  xl: '1.5rem',
  xxl: '1.75rem',
};

/** Maps font-size values from designated spacing shorthands */
export const fontSizeValShorthands: PropertyMapper = (props: StyleProperty[]) => {
  return props.map((propMap) => {
    const mappedValue = fontSizeMap[propMap.value] ?? propMap.value;
    return { prop: propMap.prop, value: mappedValue };
  });
};

const fontFamilyMap: Record<string, string> = {
  sans: config.font.family.sans,
  serif: config.font.family.serif,
  mono: config.font.family.mono,
};

/** Maps font-family values from designated font-family shorthands */
export const fontFamilyValShorthands: PropertyMapper = (props: StyleProperty[]) => {
  return props.map((propMap) => {
    const mappedValue = fontFamilyMap[propMap.value] ?? propMap.value;
    return { prop: propMap.prop, value: mappedValue };
  });
};

const elevationMap: Record<string, string> = {
  z0: config.elevation.z0,
  z1: config.elevation.z1,
  z2: config.elevation.z2,
  z3: config.elevation.z3,
  z4: config.elevation.z4,
};

/** Maps elevation values from designated elevation shorthands */
export const elevationValShorthands: PropertyMapper = (props: StyleProperty[]) => {
  return props.map((propMap) => {
    const mappedValue = elevationMap[propMap.value] ?? propMap.value;
    return { prop: propMap.prop, value: mappedValue };
  });
};
