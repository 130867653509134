import { Presets, StatusPalette } from './../../core';

export interface ProgressSpinnerPreset {
  color?: StatusPalette;
}

export const progressSpinnerPresets: Presets<ProgressSpinnerPreset> = {
  default: {},
  cx: {
    color: 'secondary',
  },
  rx: {},
  hw: {},
};
