import { LitElement } from 'lit';
import { Constructor } from '../types';
import { onUpdate } from '../decorators';
import { DisabledInterface } from './disabled.mixin';
import { property } from 'lit/decorators.js';
import { isSsr } from '../utilities';

export declare class TabIndexInterface {
  /**
   * Override this adapter to retrieve the current tabindex attribute value from a custom target element
   * @ignore
   */
  getTabIndexAdapter(): string;

  /**
   * Override this adapter to set the tabindex attribute on a custom target element
   * @ignore
   */
  setTabIndexAdapter(value: string): void;

  /**
   * Override this adapter to remove the tabindex attribute from a custom target element
   * @ignore
   */
  removeTabIndexAdapter(): void;

  /** @ignore */
  _preventNextTabIndexCacheChange: boolean;
}

/**
 * Mixin to properly manage the tabindex in a non-intrusive way. When disabled is true,
 * the tabindex will ALWAYS be `-1`. However, any changes to tabindex will be intercepted
 * and cached internally until disabled is false, then tabindex will be restored to the
 * cached value, or removed if that is undefined.
 */
export const TabIndexMixin = <T extends Constructor<LitElement & DisabledInterface>>(
  superClass: T,
  options: { initialTabIndex: number | undefined } = { initialTabIndex: undefined }
) => {
  class TabIndexElement extends superClass {
    @property({ attribute: 'tabindex', type: Number, noAccessor: true })
    public override set tabIndex(tabIndex: number) {
      if (this.disabled) {
        // When setting the tabIndex on the current element to `-1` disabled, we want to keep track of what the tabIndex value before that was
        // as the cachedTabIndex. To do this we must ignore the initial change to `-1` and then cache any future values before keeping the
        // tabIndex as `-1` until no longer disabled. Then we can return the tabIndex to whatever the last set value was from the cached index.
        if (!this._preventNextTabIndexCacheChange) {
          this._cachedTabIndex = tabIndex;
          this._preventNextTabIndexCacheChange = true;
          this.setTabIndexAdapter('-1');
        } else {
          this._preventNextTabIndexCacheChange = false;
        }
      } else {
        this._tabIndex = tabIndex;
        this.setTabIndexAdapter(`${this._tabIndex}`);
      }
    }
    public override get tabIndex() {
      return this._tabIndex;
    }
    private _tabIndex = options?.initialTabIndex;
    private _cachedTabIndex = undefined;
    _preventNextTabIndexCacheChange = false;

    @onUpdate(['disabled'], { on: 'both' })
    private handleTabindexChange(changedProps) {
      if (changedProps.has('disabled')) {
        if (changedProps.get('disabled') === false && this.disabled) {
          this._preventNextTabIndexCacheChange = false;
          this._cachedTabIndex = Number(this.getTabIndexAdapter());
          this._tabIndex = -1;
          this.setTabIndexAdapter('-1', true);
        } else if (changedProps.get('disabled') === true) {
          if (this._cachedTabIndex !== undefined && this._cachedTabIndex !== null) {
            this._tabIndex = this._cachedTabIndex;
            this.setTabIndexAdapter(`${this._cachedTabIndex}`);
            this._cachedTabIndex = undefined;
          } else {
            this._cachedTabIndex = undefined;
            this.removeTabIndexAdapter();
          }
        }
      }
    }

    /** Override this adapter to retrieve the current tabindex attribute value from a custom target element */
    getTabIndexAdapter() {
      return this.getAttribute('tabindex');
    }

    /** Override this adapter to set the tabindex attribute on a custom target element */
    setTabIndexAdapter(value: string, shouldPreventTabIndexChangeOnSelf = false) {
      if (shouldPreventTabIndexChangeOnSelf) {
        this._preventNextTabIndexCacheChange = true;
      }
      this.setAttribute('tabindex', value);
    }

    /** Override this adapter to remove the tabindex attribute from a custom target element */
    removeTabIndexAdapter() {
      this.removeAttribute('tabindex');
    }

    willUpdate(changedProperties) {
      super.willUpdate(changedProperties);

      if (isSsr() && this._tabIndex !== undefined) {
        this.setTabIndexAdapter(`${this._tabIndex}`);
      }
    }

    firstUpdated(changedProperties) {
      super.firstUpdated(changedProperties);

      if (this._tabIndex !== undefined) {
        this.setTabIndexAdapter(`${this._tabIndex}`);
      }
    }
  }
  return TabIndexElement as Constructor<TabIndexInterface> & T;
};
