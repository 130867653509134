import { html } from 'lit';
import { property } from 'lit/decorators.js';
import { baseStyles, defineElement, MteContentBase, onUpdate } from '../../core';
import { styles } from './divider.styles';

/** */
@defineElement('mte-divider')
export class MteDivider extends MteContentBase {
  static styles = [baseStyles, styles];

  /** Label that will appear in MteDivider */
  @property({ reflect: true }) label: string;

  /** Defines where to align the label */
  @property({ reflect: true }) align: 'start' | 'end' | 'center' = 'center';

  /** Defines orientation of MteDivider */
  @property({ reflect: true }) orientation: 'vertical' | 'horizontal' = 'horizontal';

  /** Sets weight or thickness of MteDivider */
  @property({ reflect: true }) weight: 'thin' | 'regular' | 'bold' = 'regular';

  /** Defines role of MteDivider */
  @property({ reflect: true }) role: 'separator' | 'presentation' = 'separator';

  /** Defines aria-orientation */
  @property({ reflect: true, attribute: 'aria-orientation' }) ariaOrientation: 'vertical' | null;

  /** The color of the divider */
  @property({ reflect: true }) color:
    | 'regular'
    | 'dark'
    | 'darker'
    | 'border1'
    | 'border2'
    | 'border3' = 'regular';

  @onUpdate(['label', 'orientation'], { on: 'both' })
  private handleA11yChange() {
    this.role = this.label ? 'presentation' : 'separator';
    this.ariaOrientation = this.orientation === 'vertical' ? this.orientation : null;
  }

  render() {
    return html`${this.instanceStyles}${this.label}`;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'mte-divider': MteDivider;
  }
}
