import { CorePalette, Presets } from './../../core';

export interface LinkPreset {
  color?: CorePalette;
  fontWeight?: 'regular' | 'bold';
}

export const linkPresets: Presets<LinkPreset> = {
  default: {},
  cx: {
    fontWeight: 'bold',
  },
  rx: {},
  hw: {
    fontWeight: 'bold',
  },
};
