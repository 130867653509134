import type { ReactiveController, ReactiveControllerHost } from 'lit';
import { Signal, computed, isSsr, signal } from '../utilities';

export interface SlotControllerOptions {
  /** Names of slots that should trigger a component update if changed */
  updateOnChange?: string[];
}

export class SlotController implements ReactiveController {
  public slotChange = signal<Set<string>>(null);
  public onConnected = signal(false);
  public connectedComplete = false;

  private subs = [];
  private updateTriggerSlots = new Set<string>();

  constructor(
    public host: ReactiveControllerHost & Element,
    public options: SlotControllerOptions = null
  ) {
    this.host.addController(this);
    this.updateOnChange(options?.updateOnChange);
  }

  /** Emits when one of the specified slots has changed and after the firstUpdate after hostConnected */
  public watchSlots(slots: string[] = [], { updateOnConnected } = { updateOnConnected: true }) {
    const returnSignal = signal<Set<string>>();
    this.subs.push(
      computed(
        [this.onConnected, this.slotChange],
        ([connected, changes]: [boolean, Set<string>], set) => {
          if (updateOnConnected && connected && !this.connectedComplete) {
            this.connectedComplete = true;
            set(new Set(slots));
          }
          if (
            changes &&
            Array.from(changes.keys()).some((key) => slots.length === 0 || slots.includes(key))
          ) {
            set(changes);
          }
        }
      ).subscribe((changes: Set<string>) => {
        if (changes) {
          returnSignal.set(changes);
        }
      })
    );
    return returnSignal as Signal<Set<string>>;
  }

  public check(slotName: string) {
    return slotName === 'default' ? this.hasDefaultSlot() : this.hasNamedSlot(slotName);
  }

  public updateOnChange(slots: string | string[]) {
    if (Array.isArray(slots)) {
      slots.forEach((slot) => this.updateTriggerSlots.add(slot));
    } else if (slots) {
      this.updateTriggerSlots.add(slots);
    }
  }

  private hasDefaultSlot() {
    return [...this.host.childNodes].some((node) => {
      if (node.nodeType === node.TEXT_NODE && node.textContent?.trim() !== '') {
        return true;
      }

      if (node.nodeType === node.ELEMENT_NODE) {
        const el = node as HTMLElement;
        const tagName = el.tagName.toLowerCase();

        // Ignore visually hidden elements since they aren't rendered
        if (tagName === 'mte-visually-hidden') {
          return false;
        }

        // If it doesn't have a slot attribute, it's part of the default slot
        if (!el.hasAttribute('slot')) {
          return true;
        }
      }

      return false;
    });
  }

  private hasNamedSlot(name: string) {
    if (!isSsr()) {
      return this.host.querySelector(`:scope > [slot="${name}"]`) !== null;
    } else {
      return false;
    }
  }

  private handleSlotChange = (event: Event) => {
    const slot = event.target as HTMLSlotElement;

    // Setup event map
    const eventSet = new Set<string>();
    // Add duplicate mapping for simplified default name
    if (slot.name === '[default]' || slot.name === '') {
      eventSet.add('default');
    } else {
      eventSet.add(slot.name);
    }

    // Emit slot change reactive event
    this.slotChange.set(eventSet);

    // Automatically request a component update as configuration dictates
    if (
      (this.updateTriggerSlots.has('default') && !slot.name) ||
      (slot.name && this.updateTriggerSlots.has(slot.name))
    ) {
      this.host.requestUpdate();
    }
  };

  hostConnected() {
    this.host.shadowRoot?.addEventListener('slotchange', this.handleSlotChange);

    // Wait until first update after connected
    this.host.updateComplete.then(() => {
      this.onConnected.set(true);
      // TODO(reece): reconsider when a solution exists for https://github.com/lit/lit/issues/1434
      // If after the firstUpdate we're watching slots request another in case ssr-slots was not properly set by the user
      if (this.updateTriggerSlots.size > 0) {
        this.host.requestUpdate();
      }
    });
  }

  hostDisconnected() {
    this.host.shadowRoot?.removeEventListener('slotchange', this.handleSlotChange);
    this.subs.forEach((unsub) => unsub());
    this.subs = [];
    this.connectedComplete = false;
    this.onConnected.set(false);
  }
}

/**
 * Given a slot, this function iterates over all of its assigned element and text nodes and returns the concatenated
 * HTML as a string. This is useful because we can't use slot.innerHTML as an alternative.
 */
export function getInnerHTML(slot: HTMLSlotElement): string {
  const nodes = slot.assignedNodes({ flatten: true });
  let html = '';

  [...nodes].forEach((node) => {
    if (node.nodeType === Node.ELEMENT_NODE) {
      html += (node as HTMLElement).outerHTML;
    }

    if (node.nodeType === Node.TEXT_NODE) {
      html += node.textContent;
    }
  });

  return html;
}

/**
 * Given a slot, this function iterates over all of its assigned text nodes and returns the concatenated text as a
 * string. This is useful because we can't use slot.textContent as an alternative.
 */
export function getTextContent(slot: HTMLSlotElement | undefined | null): string {
  if (!slot) {
    return '';
  }
  const nodes = slot.assignedNodes({ flatten: true });
  let text = '';

  [...nodes].forEach((node) => {
    if (node.nodeType === Node.TEXT_NODE) {
      text += node.textContent;
    }
  });

  return text;
}
