import { config, SelectorOptions } from '@mortar/styles';
import {
  SelectorMetadata,
  SelectorStyles,
  StyleMap,
  StyleMapCallback,
  StyleProperty,
} from './css-in-js.types';
import {
  propShorthands,
  spacingValShorthands,
  numberToPx,
  dotStringToConfigValueMapper,
  borderRadiusValShorthands,
  borderWidthValShorthands,
  opacityValShorthands,
  fontWeightValShorthands,
  fontFamilyValShorthands,
  elevationValShorthands,
  fontSizeValShorthands,
} from './css-in-js.property-mappers';

/** Specifies which property mappers should be called and in what order for a given prop */
const propMappers = {
  height: [numberToPx],
  minHeight: [numberToPx],
  maxHeight: [numberToPx],
  width: [numberToPx],
  minWidth: [numberToPx],
  maxWidth: [numberToPx],
  top: [numberToPx],
  right: [numberToPx],
  bottom: [numberToPx],
  left: [numberToPx],
  margin: [spacingValShorthands, numberToPx],
  marginTop: [spacingValShorthands, numberToPx],
  marginRight: [spacingValShorthands, numberToPx],
  marginBottom: [spacingValShorthands, numberToPx],
  marginLeft: [spacingValShorthands, numberToPx],
  padding: [spacingValShorthands, numberToPx],
  paddingTop: [spacingValShorthands, numberToPx],
  paddingRight: [spacingValShorthands, numberToPx],
  paddingBottom: [spacingValShorthands, numberToPx],
  paddingLeft: [spacingValShorthands, numberToPx],
  gap: [spacingValShorthands, numberToPx],
  rowGap: [spacingValShorthands, numberToPx],
  columnGap: [spacingValShorthands, numberToPx],
  fontSize: [fontSizeValShorthands, numberToPx],
  fontWeight: [fontWeightValShorthands],
  fontFamily: [fontFamilyValShorthands],
  borderRadius: [borderRadiusValShorthands, numberToPx],
  borderWidth: [borderWidthValShorthands, numberToPx],
  borderTopRightRadius: [borderRadiusValShorthands, numberToPx],
  borderTopLeftRadius: [borderRadiusValShorthands, numberToPx],
  borderBottomRightRadius: [borderRadiusValShorthands, numberToPx],
  borderBottomLeftRadius: [borderRadiusValShorthands, numberToPx],
  opacity: [opacityValShorthands],
  boxShadow: [elevationValShorthands],
};

/**
 * Parses properties to their actual property mappings according to the
 * mapping function pipelines defined above
 */
export const parsePropShorthands = (prop: string, value: string | number): StyleProperty[] => {
  // Cast to string values
  if (typeof value === 'number') {
    value = `${value}`;
  }
  // Exec prop transforms
  // The prop shorthands mapper is run against every prop
  const mappedProps = dotStringToConfigValueMapper(propShorthands([{ prop, value }]));
  return mappedProps.reduce((acc, propMap) => {
    const mappers = propMappers[propMap.prop];
    if (mappers) {
      return [
        ...acc,
        ...mappers.reduce(
          (acc, mapper) => mapper(acc),
          [{ prop: propMap.prop, value: propMap.value }]
        ),
      ];
    } else {
      acc.push(propMap);
      return acc;
    }
  }, []);
};

/**
 * Parses styleMap values to selector + style info used for
 * updating CSSStyleSheets or <style> tags.
 */
export const generateSelectorsFromStyleMap = (
  selectorFn: (options?: SelectorOptions) => string,
  styleMap: StyleMap
) => {
  const parsedSelectors: SelectorStyles[] = [];
  const selectorQueue: SelectorMetadata[] = [{ selector: selectorFn(), styleMap }];
  const breakpointKeys = Object.keys(config.grid.breakpoint);

  // Loop through selector queue until they have all been exhausted
  while (selectorQueue.length > 0) {
    const selectorMap = selectorQueue.shift();

    // Push parsed selectors onto the return list
    parsedSelectors.push({
      selector: selectorMap.selector,
      selectorWrapper: selectorMap.selectorWrapper,
      style: Object.keys(selectorMap.styleMap).reduce((acc, prop) => {
        let value = selectorMap.styleMap[prop];
        if (value == null) {
          return acc;
        }

        // Parse callback values
        else if (typeof value === 'function') {
          value = (value as StyleMapCallback)(config);
        }

        // Parse object values
        else if (typeof value === 'object') {
          // Queue up selectors for pseudo-classes
          if (prop.startsWith('&:')) {
            selectorQueue.push({
              selector: selectorFn({ infix: prop.replace('&', '') }),
              styleMap: value,
              selectorWrapper: selectorMap.selectorWrapper,
            });
            return acc;
          }
          // Queue up breakpoint selector shorthands
          else if (breakpointKeys.includes(prop)) {
            selectorQueue.push({
              selector: selectorMap.selector,
              styleMap: value,
              selectorWrapper: `@media (min-width:${config.grid.breakpoint[prop]})`,
            });
            return acc;
          }
          // Queue up selectors for media/container queries
          else if (prop.startsWith('@media') || prop.startsWith('@container')) {
            selectorQueue.push({
              selector: selectorMap.selector,
              styleMap: value,
              selectorWrapper: prop,
            });
            return acc;
          }
          // Queue up selectors for slotted queries
          else if (prop.startsWith('::slotted') || prop.startsWith('& ::slotted')) {
            selectorQueue.push({
              selector: selectorFn({ postfix: ' ' + prop.replace('& ', '') }),
              styleMap: value,
              selectorWrapper: selectorMap.selectorWrapper,
            });
            return acc;
          }
          // Queue up selectors for value breakpoint objects
          else {
            // Loop in order here so they override each other correctly
            breakpointKeys.forEach((key) => {
              if (value[key]) {
                selectorQueue.push({
                  selector: selectorMap.selector, // inherit the selector here in case we are within a pseudo-class
                  styleMap: { [prop]: value[key] },
                  selectorWrapper: `@media (min-width:${config.grid.breakpoint[key]})`,
                });
              }
            });
          }

          return acc;
        }

        // Parse normal props
        const props = parsePropShorthands(prop, value as string | number);
        return `${acc}${props.reduce((acc2, { prop, value }) => {
          // Convert property names from camel-case to dash-case, i.e.:
          //  `backgroundColor` -> `background-color`
          // Vendor-prefixed names need an extra `-` appended to front:
          //  `webkitAppearance` -> `-webkit-appearance`
          // Exception is any property name containing a dash, including
          // custom properties; we assume these are already dash-cased i.e.:
          //  `--my-button-color` --> `--my-button-color`
          prop = prop.replace(/(?:^(webkit|moz|ms|o)|)(?=[A-Z])/g, '-$&').toLowerCase();
          return `${acc2}${prop}:${value};`;
        }, '')}`;
      }, ''),
    });
  }

  return parsedSelectors;
};

const parseSelectors = (styles, selectors?: any) => {
  if (!selectors) {
    return styles;
  } else {
    return `${styles}${selectors.reduce((acc, { selector, selectorWrapper, style }) => {
      if (style === '') {
        return acc;
      }
      const base = `${selector}{${style}}`;
      const wrapped = selectorWrapper ? `${selectorWrapper}{${base}}` : base;
      return `${acc}${wrapped}`;
    }, '')}`;
  }
};

/**
 * Combines all instance styleMap selectors + style info into one cssText
 * used for updating CSSStyleSheets or <style> tags.
 */
export const constructInstanceStyles = (instanceStylesMap) => {
  let styles = '';

  // Sort keys in alphabetical order to help avoid hydration mismatch errors
  [...instanceStylesMap.entries()]
    .filter(([key, _]) => key !== 'se' && key !== 'sp')
    .sort((a, b) => b[0] - a[0])
    .forEach(([_, selectors]) => {
      styles = parseSelectors(styles, selectors);
    });

  // Always parse `se` and `sp` selectors last to help avoid hydration mismatch errors
  // and ensure they override any component level dynamic styles.
  styles = `${styles}${parseSelectors('', instanceStylesMap.get('se'))}${parseSelectors(
    '',
    instanceStylesMap.get('sp')
  )}`;
  return styles;
};
