import { cxPreset, defaultPreset, MortarPreset, pxPreset, rxPreset, hwPreset } from './presets';
import { computed, Signal, signal } from '../utilities';

class _MtePresetService {
  private presets = new Map<string, MortarPreset>([
    ['global', defaultPreset],
    ['default', defaultPreset],
    ['px', defaultPreset],
    ['cx', cxPreset],
    ['rx', rxPreset],
    ['hw', hwPreset],
  ]);

  private presetChangedSignal = signal<{ presetName: string; componentName: string }>({
    presetName: null,
    componentName: null,
  });

  public setPreset(presetName: string, preset: Partial<MortarPreset>) {
    this.presets.set(presetName, {
      ...(this.presets.get(presetName) ?? defaultPreset),
      ...preset,
    });
    this.presetChangedSignal.set({ presetName, componentName: null });
  }

  public setComponentPreset<C extends keyof MortarPreset>(
    presetName: string,
    componentName: C,
    componentPreset: Partial<MortarPreset[C]>
  ) {
    const selectedPreset = this.presets.get(presetName) ?? defaultPreset;
    this.presets.set(presetName, {
      ...selectedPreset,
      [componentName]: {
        ...(selectedPreset[componentName] ?? ({} as any)),
        ...componentPreset,
      },
    });
    this.presetChangedSignal.set({ presetName, componentName });
  }

  public setComponentPresetProperty<C extends keyof MortarPreset, P extends keyof MortarPreset[C]>(
    presetName: string,
    componentName: C,
    propertyName: P,
    value: MortarPreset[C][P] | Partial<MortarPreset[C][P]>
  ) {
    const selectedPreset = this.presets.get(presetName) ?? defaultPreset;
    const selectedComponentPreset = selectedPreset[componentName] as any;

    // If setting an object value be sure to spread over existing value
    if (typeof value === 'object') {
      this.presets.set(presetName, {
        ...selectedPreset,
        [componentName]: {
          ...selectedComponentPreset,
          [propertyName]: {
            ...selectedComponentPreset[propertyName],
            ...value,
          },
        },
      });
    }
    // Otherwise just set the value
    else {
      this.presets.set(presetName, {
        ...selectedPreset,
        [componentName]: {
          ...selectedComponentPreset,
          [propertyName]: value,
        },
      });
    }
    this.presetChangedSignal.set({ presetName, componentName });
  }

  public getPreset(presetName: string): MortarPreset {
    const preset = this.presets.get(presetName);
    if (!preset) {
      console.error(`MtePresetService: A preset with the name "${presetName}" does not exist`);
    }
    return preset;
  }

  public getComponentPreset<C extends keyof MortarPreset>(
    presetName: string,
    componentName: C
  ): MortarPreset[C] {
    const preset = this.presets.get(presetName);
    const componentPreset = preset[componentName];
    if (!preset) {
      console.error(`MtePresetService: A preset with the name "${presetName}" does not exist.`);
    }
    return componentPreset;
  }

  public selectPreset(presetName: string): Signal<MortarPreset> {
    let firstEmit = true;
    return computed(this.presetChangedSignal, (change, set) => {
      if (firstEmit) {
        set(this.getPreset(presetName));
        firstEmit = false;
      } else if (change.presetName === presetName) {
        set(this.getPreset(presetName));
      }
    }) as Signal<MortarPreset>;
  }

  public selectComponentPreset<C extends keyof MortarPreset>(
    presetName: string,
    componentName: C
  ): Signal<MortarPreset[C]> {
    let firstEmit = true;
    return computed(this.presetChangedSignal, (change, set) => {
      if (firstEmit) {
        set(this.getComponentPreset(presetName, componentName));
        firstEmit = false;
      } else if (
        (change.presetName === presetName && !change.componentName) ||
        (change.presetName === presetName && change.componentName === componentName)
      ) {
        set(this.getComponentPreset(presetName, componentName));
      }
    }) as Signal<MortarPreset[C]>;
  }
}

export const MtePresetService = new _MtePresetService();
