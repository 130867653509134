import { buttonPresets, iconButtonPresets } from '../../atomic/button/button.presets';
import { iconPresets } from '../../atomic/icon/icon.presets';
import { progressBarPresets } from '../../atomic/progress-bar/progress-bar.presets';
import { progressSpinnerPresets } from '../../atomic/progress-spinner/progress-spinner.presets';
import { cardPresets, cardButtonPresets } from '../../atomic/card/card.presets';
import { treePresets } from '../../atomic/tree/tree.presets';
import { tagPresets } from '../../atomic/tag/tag.presets';
import { badgePresets } from '../../atomic/badge/badge.presets';
import { linkPresets } from '../../atomic/link/link.presets';
import { alertPresets } from '../../atomic/alert/alert.presets';
import { inputPresets } from '../../atomic/input/input.presets';
import { drawerPresets } from '../../atomic/drawer/drawer.presets';
import { asidePresets } from '../../atomic/aside/aside.presets';
import { breadcrumbItemPresets } from '../../atomic/breadcrumbs/breadcrumb-item.presets';
import { modalPresets } from '../../atomic/modal/modal.presets';
import { checkboxPresets } from '../../atomic/checkbox/checkbox.presets';
import { radioPresets } from '../../atomic/radio/radio.presets';
import {
  navRootPresets,
  navHeaderPresets,
  navSubHeaderPresets,
  navSidebarPresets,
  navContentPresets,
  navFooterPresets,
  navButtonPresets,
  navIconButtonPresets,
  navItemPresets,
} from '../../atomic/nav/nav.presets';
import { buttonGroupPresets } from '../../atomic/button-group/button-group.presets';
import { switchPresets } from '../../atomic/switch/switch.presets';
import { nativeSelectPresets } from '../../atomic/native-select/native-select.presets';
import { textareaPresets } from '../../atomic/textarea/textarea.presets';
import { skeletonPresets } from '../../atomic/skeleton/skeleton.presets';
import { sliderPresets } from '../../atomic/slider/slider.presets';
import { rangeSliderPresets } from '../../atomic/range-slider/range-slider.presets';
import { segmentedControlPresets } from '../../atomic/segmented-control/segmented-control.presets';

// Re-export preset files so types can be imported properly by external libraries
export * from '../../atomic/button/button.presets';
export * from '../../atomic/icon/icon.presets';
export * from '../../atomic/progress-bar/progress-bar.presets';
export * from '../../atomic/progress-spinner/progress-spinner.presets';
export * from '../../atomic/card/card.presets';
export * from '../../atomic/tree/tree.presets';
export * from '../../atomic/tag/tag.presets';
export * from '../../atomic/badge/badge.presets';
export * from '../../atomic/link/link.presets';
export * from '../../atomic/alert/alert.presets';
export * from '../../atomic/input/input.presets';
export * from '../../atomic/drawer/drawer.presets';
export * from '../../atomic/aside/aside.presets';
export * from '../../atomic/breadcrumbs/breadcrumb-item.presets';
export * from '../../atomic/modal/modal.presets';
export * from '../../atomic/checkbox/checkbox.presets';
export * from '../../atomic/radio/radio.presets';
export * from '../../atomic/nav/nav.presets';
export * from '../../atomic/button-group/button-group.presets';
export * from '../../atomic/switch/switch.presets';
export * from '../../atomic/native-select/native-select.presets';
export * from '../../atomic/textarea/textarea.presets';
export * from '../../atomic/skeleton/skeleton.presets';
export * from '../../atomic/slider/slider.presets';
export * from '../../atomic/range-slider/range-slider.presets';
export * from '../../atomic/segmented-control/segmented-control.presets';

export interface MortarPresetTheme {
  name: string;
  colorScheme: 'system' | 'light' | 'dark';
}

export const defaultPreset = {
  preset: 'default',
  theme: {
    name: 'px',
    colorScheme: 'light',
  } as MortarPresetTheme,
  MteButton: buttonPresets.default,
  MteIconButton: iconButtonPresets.default,
  MteIcon: iconPresets.default,
  MteProgressBar: progressBarPresets.default,
  MteProgressSpinner: progressSpinnerPresets.default,
  MteCard: cardPresets.default,
  MteCardButton: cardButtonPresets.default,
  MteTree: treePresets.default,
  MteTag: tagPresets.default,
  MteBadge: badgePresets.default,
  MteLink: linkPresets.default,
  MteAlert: alertPresets.default,
  MteInput: inputPresets.default,
  MteDrawer: drawerPresets.default,
  MteAside: asidePresets.default,
  MteBreadcrumbItem: breadcrumbItemPresets.default,
  MteModal: modalPresets.default,
  MteCheckbox: checkboxPresets.default,
  MteRadio: radioPresets.default,
  MteNavRoot: navRootPresets.default,
  MteNavHeader: navHeaderPresets.default,
  MteNavSubHeader: navSubHeaderPresets.default,
  MteNavSidebar: navSidebarPresets.default,
  MteNavContent: navContentPresets.default,
  MteNavFooter: navFooterPresets.default,
  MteNavButton: navButtonPresets.default,
  MteNavIconButton: navIconButtonPresets.default,
  MteNavItem: navItemPresets.default,
  MteButtonGroup: buttonGroupPresets.default,
  MteSwitch: switchPresets.default,
  MteNativeSelect: nativeSelectPresets.default,
  MteTextarea: textareaPresets.default,
  MteSkeleton: skeletonPresets.default,
  MteSlider: sliderPresets.default,
  MteRangeSlider: rangeSliderPresets.default,
  MteSegmentedControl: segmentedControlPresets.default,
};

export type MortarPreset = typeof defaultPreset;

// Re-export default as PX preset
export const pxPreset: MortarPreset = defaultPreset;

export const cxPreset: MortarPreset = {
  preset: 'cx',
  theme: {
    name: 'cx',
    colorScheme: 'light',
  },
  MteButton: buttonPresets.cx,
  MteIconButton: iconButtonPresets.cx,
  MteIcon: iconPresets.cx,
  MteProgressBar: progressBarPresets.cx,
  MteProgressSpinner: progressSpinnerPresets.cx,
  MteCard: cardPresets.cx,
  MteCardButton: cardButtonPresets.cx,
  MteTree: treePresets.cx,
  MteTag: tagPresets.cx,
  MteBadge: badgePresets.cx,
  MteLink: linkPresets.cx,
  MteAlert: alertPresets.cx,
  MteInput: inputPresets.cx,
  MteDrawer: drawerPresets.cx,
  MteAside: asidePresets.cx,
  MteBreadcrumbItem: breadcrumbItemPresets.cx,
  MteModal: modalPresets.cx,
  MteCheckbox: checkboxPresets.cx,
  MteRadio: radioPresets.cx,
  MteNavRoot: navRootPresets.cx,
  MteNavHeader: navHeaderPresets.cx,
  MteNavSubHeader: navSubHeaderPresets.cx,
  MteNavSidebar: navSidebarPresets.cx,
  MteNavContent: navContentPresets.cx,
  MteNavFooter: navFooterPresets.cx,
  MteNavButton: navButtonPresets.cx,
  MteNavIconButton: navIconButtonPresets.cx,
  MteNavItem: navItemPresets.cx,
  MteButtonGroup: buttonGroupPresets.cx,
  MteSwitch: switchPresets.cx,
  MteNativeSelect: nativeSelectPresets.cx,
  MteTextarea: textareaPresets.cx,
  MteSkeleton: skeletonPresets.cx,
  MteSlider: sliderPresets.cx,
  MteRangeSlider: rangeSliderPresets.cx,
  MteSegmentedControl: segmentedControlPresets.cx,
};

export const hwPreset: MortarPreset = {
  ...cxPreset,
  preset: 'hw',
  theme: {
    name: 'hw',
    colorScheme: 'light',
  },
  MteButton: buttonPresets.hw,
  MteIconButton: iconButtonPresets.hw,
  MteIcon: iconPresets.hw,
  MteProgressBar: progressBarPresets.hw,
  MteProgressSpinner: progressSpinnerPresets.hw,
  MteCard: cardPresets.hw,
  MteCardButton: cardButtonPresets.hw,
  MteTree: treePresets.hw,
  MteTag: tagPresets.hw,
  MteBadge: badgePresets.hw,
  MteLink: linkPresets.hw,
  MteAlert: alertPresets.hw,
  MteInput: inputPresets.hw,
  MteDrawer: drawerPresets.hw,
  MteAside: asidePresets.hw,
  MteBreadcrumbItem: breadcrumbItemPresets.hw,
  MteModal: modalPresets.hw,
  MteCheckbox: checkboxPresets.hw,
  MteRadio: radioPresets.hw,
  MteNavRoot: navRootPresets.hw,
  MteNavHeader: navHeaderPresets.hw,
  MteNavSubHeader: navSubHeaderPresets.hw,
  MteNavSidebar: navSidebarPresets.hw,
  MteNavContent: navContentPresets.hw,
  MteNavFooter: navFooterPresets.hw,
  MteNavButton: navButtonPresets.hw,
  MteNavIconButton: navIconButtonPresets.hw,
  MteNavItem: navItemPresets.hw,
  MteButtonGroup: buttonGroupPresets.hw,
  MteSwitch: switchPresets.hw,
  MteNativeSelect: nativeSelectPresets.hw,
  MteTextarea: textareaPresets.hw,
  MteSkeleton: skeletonPresets.hw,
  MteSlider: sliderPresets.hw,
  MteRangeSlider: rangeSliderPresets.hw,
  MteSegmentedControl: segmentedControlPresets.hw,
};

export const rxPreset: MortarPreset = {
  preset: 'rx',
  theme: {
    name: 'rx',
    colorScheme: 'light',
  },
  MteButton: buttonPresets.rx,
  MteIconButton: iconButtonPresets.rx,
  MteIcon: iconPresets.rx,
  MteProgressBar: progressBarPresets.rx,
  MteProgressSpinner: progressSpinnerPresets.rx,
  MteCard: cardPresets.rx,
  MteCardButton: cardButtonPresets.rx,
  MteTree: treePresets.rx,
  MteTag: tagPresets.rx,
  MteBadge: badgePresets.rx,
  MteLink: linkPresets.rx,
  MteAlert: alertPresets.rx,
  MteInput: inputPresets.rx,
  MteDrawer: drawerPresets.rx,
  MteAside: asidePresets.rx,
  MteBreadcrumbItem: breadcrumbItemPresets.rx,
  MteModal: modalPresets.rx,
  MteCheckbox: checkboxPresets.rx,
  MteRadio: radioPresets.rx,
  MteNavRoot: navRootPresets.rx,
  MteNavHeader: navHeaderPresets.rx,
  MteNavSubHeader: navSubHeaderPresets.rx,
  MteNavSidebar: navSidebarPresets.rx,
  MteNavContent: navContentPresets.rx,
  MteNavFooter: navFooterPresets.rx,
  MteNavButton: navButtonPresets.rx,
  MteNavIconButton: navIconButtonPresets.rx,
  MteNavItem: navItemPresets.rx,
  MteButtonGroup: buttonGroupPresets.rx,
  MteSwitch: switchPresets.rx,
  MteNativeSelect: nativeSelectPresets.rx,
  MteTextarea: textareaPresets.rx,
  MteSkeleton: skeletonPresets.rx,
  MteSlider: sliderPresets.rx,
  MteRangeSlider: rangeSliderPresets.rx,
  MteSegmentedControl: segmentedControlPresets.rx,
};
