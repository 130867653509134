import { property } from 'lit/decorators.js';
import {
  ActiveLinkOptions,
  Constructor,
  MteElement,
  MteHistoryApiService,
  Unsubscriber,
  isLinkActive,
  onUpdate,
} from '../';

export declare class LinkInterface {
  href?: string;
}

export declare class AutoActiveLinkInterface {
  /** Whether or not this link is the active page */
  active: boolean;

  /** Whether or not this link should automatically try to determine if it is the active page */
  withoutAutoActive: boolean;

  /** Options to refine how this link will determine if it is the active page */
  activeLinkOptions?: Partial<ActiveLinkOptions>;

  /** The internal anchor element from which to pull the location for comparison */
  anchorElement?: HTMLAnchorElement;
}

export const AutoActiveLinkMixin = <T extends Constructor<MteElement & LinkInterface>>(
  superClass: T
) => {
  class AutoActiveLinkElement extends superClass {
    @property({ type: Boolean, reflect: true }) active = false;

    @property({ type: Boolean, reflect: true }) withoutAutoActive = false;

    @property({ type: Object, reflect: true }) activeLinkOptions?: Partial<ActiveLinkOptions>;

    anchorElement?: HTMLAnchorElement;

    private unsub: Unsubscriber;

    @onUpdate('active', { waitUntilFirstUpdate: true })
    private handleActiveLinkChange() {
      if (this.active) {
        this.anchorElement?.setAttribute('aria-current', 'page');
      } else {
        this.anchorElement?.removeAttribute('aria-current');
      }
    }

    @onUpdate(['href', 'iref', 'withoutAutoActive'], { waitUntilFirstUpdate: true })
    private handleAutoActiveChange() {
      if (this.href || this['iref']) {
        if (this.withoutAutoActive) {
          this.removeListener();
          this.active = false;
        } else {
          this.addListener();
          this.handleUrlChange();
        }
      }
    }

    connectedCallback(): void {
      super.connectedCallback();
      if (!this.withoutAutoActive) {
        this.updateComplete.then(() => {
          this.handleAutoActiveChange();
        });
      }
    }

    disconnectedCallback(): void {
      super.disconnectedCallback();
      this.removeListener();
    }

    private addListener() {
      if (!this.unsub) {
        this.unsub = MteHistoryApiService.stateChanges().subscribe(() => {
          this.handleUrlChange();
        });
      }
    }

    private removeListener() {
      this.unsub?.();
      this.unsub = null;
    }

    private handleUrlChange = () => {
      this.active = isLinkActive(
        this.anchorElement?.href,
        window.location.href,
        this.activeLinkOptions
      );
    };
  }
  return AutoActiveLinkElement as Constructor<AutoActiveLinkInterface> & T;
};
