import { Presets, StatusPalette } from './../../core';

export interface ProgressBarPreset {
  color?: StatusPalette;
}

export const progressBarPresets: Presets<ProgressBarPreset> = {
  default: {},
  cx: {
    color: 'secondary',
  },
  rx: {},
  hw: {},
};
