import { isSsr } from '../utilities';

export type MteDateFormatOptions = Intl.DateTimeFormatOptions & {
  lang?: string;
  format?:
    | 'short'
    | 'medium'
    | 'long'
    | 'full'
    | 'shortDate'
    | 'mediumDate'
    | 'longDate'
    | 'fullDate'
    | 'shortTime'
    | 'mediumTime'
    | 'longTime'
    | 'fullTime';
};

export type MteNumberFormatOptions = Intl.NumberFormatOptions & { lang?: string };

class _MteLocalizeService {
  // Assume `en` lang for now as a fallback until we rollback wider support for i11n when necessary
  private language = 'en';
  private initialized = false;

  private init() {
    if (!isSsr() && !this.initialized) {
      this.initialized = true;
      this.language = `${document.documentElement.lang || navigator.language}`.toLowerCase();
    }
  }

  public formatNumber(numberToFormat: number | string, options?: MteNumberFormatOptions): string {
    this.init();
    const lang = options?.lang ? options.lang : this.language;
    numberToFormat = Number(numberToFormat);
    // Ensure no null options exist
    const finalOptions = {
      lang: options.lang ?? undefined,
      style: options.style ?? undefined,
      currency: options.currency ?? undefined,
      currencyDisplay: options.currencyDisplay ?? undefined,
      useGrouping: options.useGrouping ?? undefined,
      minimumIntegerDigits: options.minimumIntegerDigits ?? undefined,
      minimumFractionDigits: options.minimumFractionDigits ?? undefined,
      maximumFractionDigits: options.maximumFractionDigits ?? undefined,
      minimumSignificantDigits: options.minimumSignificantDigits ?? undefined,
      maximumSignificantDigits: options.maximumSignificantDigits ?? undefined,
    };
    return isNaN(numberToFormat)
      ? ''
      : new Intl.NumberFormat(lang, finalOptions).format(numberToFormat);
  }

  public formatDate(dateToFormat: Date | string, options?: MteDateFormatOptions) {
    this.init();
    const lang = options?.lang ? options.lang : this.language;
    const formattedOptions = { ...(options ?? {}), ...this.getDefinedDateFormat(options?.format) };
    // Ensure no null options exist
    const finalOptions = {
      lang: formattedOptions.lang ?? undefined,
      weekday: formattedOptions.weekday ?? undefined,
      era: formattedOptions.era ?? undefined,
      year: formattedOptions.year ?? undefined,
      month: formattedOptions.month ?? undefined,
      day: formattedOptions.day ?? undefined,
      hour: formattedOptions.hour ?? undefined,
      minute: formattedOptions.minute ?? undefined,
      second: formattedOptions.second ?? undefined,
      fractionalSecondDigits: formattedOptions.fractionalSecondDigits ?? undefined,
      timeZoneName: formattedOptions.timeZoneName ?? undefined,
      timeZone: formattedOptions.timeZone ?? undefined,
      hour12: formattedOptions.hour12 ?? undefined,
      format: formattedOptions.format ?? undefined,
    };
    dateToFormat = new Date(dateToFormat);
    return new Intl.DateTimeFormat(lang, finalOptions).format(dateToFormat);
  }

  private getDefinedDateFormat(
    format:
      | 'short'
      | 'medium'
      | 'long'
      | 'full'
      | 'shortDate'
      | 'mediumDate'
      | 'longDate'
      | 'fullDate'
      | 'shortTime'
      | 'mediumTime'
      | 'longTime'
      | 'fullTime'
  ): MteDateFormatOptions {
    switch (format) {
      case 'short':
        return {
          month: 'numeric',
          day: 'numeric',
          year: 'numeric',
          hour: 'numeric',
          minute: 'numeric',
        };
      case 'medium':
        return {
          month: 'short',
          day: 'numeric',
          year: 'numeric',
          hour: 'numeric',
          minute: 'numeric',
          second: 'numeric',
        };
      case 'long':
        return {
          month: 'long',
          day: 'numeric',
          year: 'numeric',
          hour: 'numeric',
          minute: 'numeric',
          second: 'numeric',
          timeZoneName: 'short',
        };
      case 'full':
        return {
          weekday: 'long',
          month: 'long',
          day: 'numeric',
          year: 'numeric',
          hour: 'numeric',
          minute: 'numeric',
          second: 'numeric',
          timeZoneName: 'short',
        };
      case 'shortDate':
        return {
          month: 'numeric',
          day: 'numeric',
          year: 'numeric',
        };
      case 'mediumDate':
        return {
          month: 'short',
          day: 'numeric',
          year: 'numeric',
        };
      case 'longDate':
        return {
          month: 'long',
          day: 'numeric',
          year: 'numeric',
        };
      case 'fullDate':
        return {
          weekday: 'long',
          month: 'long',
          day: 'numeric',
          year: 'numeric',
        };
      case 'shortTime':
        return {
          hour: 'numeric',
          minute: 'numeric',
        };
      case 'mediumTime':
        return {
          hour: 'numeric',
          minute: 'numeric',
          second: 'numeric',
        };
      case 'longTime':
        return {
          hour: 'numeric',
          minute: 'numeric',
          second: 'numeric',
          timeZoneName: 'short',
        };
      case 'fullTime':
        return {
          hour: 'numeric',
          minute: 'numeric',
          second: 'numeric',
          fractionalSecondDigits: 2,
          timeZoneName: 'short',
        };
      default:
        return {};
    }
  }
}

export const MteLocalizeService = new _MteLocalizeService();
