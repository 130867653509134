/* eslint-disable wc/no-self-class */
import { config, selectorFactory } from '@mortar/styles';
import { property } from 'lit/decorators.js';
import { borderWidthValShorthands, dotStringToConfigValue } from '../utilities';
import { MteElement } from './mte.element';
import { ResponsiveValueConverter } from '../attribute-converter';

export type SpacingValue =
  | number
  | string
  | 1
  | 2
  | 3
  | 4
  | 5
  | 6
  | 'none'
  | 'xs'
  | 'sm'
  | 'md'
  | 'lg'
  | 'xl'
  | 'xxl'
  | undefined;

export type UnitValue = number | string | undefined;

export type BorderRadiusValue =
  | 'none'
  | 'xs'
  | 'sm'
  | 'md'
  | 'lg'
  | 'xl'
  | 'xxl'
  | 'full'
  | string
  | undefined;

export type BorderWidthValue = 'none' | 'sm' | 'md' | 'lg' | string | undefined;

export type OpacityValue =
  | 'xxxs'
  | 'xxs'
  | 'xs'
  | 'sm'
  | 'md'
  | 'lg'
  | 'xl'
  | 'xxl'
  | string
  | undefined;

export type ResponsiveValue<T> = T | Partial<Record<'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'xxl', T>>;

const defaultBorderFactory = (width?, color?, style?) =>
  `${style ?? 'solid'} ${
    borderWidthValShorthands([
      { prop: 'borderWidth', value: dotStringToConfigValue(width ?? '1px') },
    ])[0]?.value
  } ${dotStringToConfigValue(color) ?? config.color.border1}`;

export class MteContentBase extends MteElement {
  // -- Spacing Props --

  /** Sets the `margin` css property on this element */
  @property({ reflect: true, converter: ResponsiveValueConverter })
  m?: ResponsiveValue<SpacingValue>;

  /** Sets the `margin-right` and `margin-left` css properties on this element */
  @property({ reflect: true, converter: ResponsiveValueConverter })
  mx?: ResponsiveValue<SpacingValue>;

  /** Sets the `margin-top` and `margin-bottom` css properties on this element */
  @property({ reflect: true, converter: ResponsiveValueConverter })
  my?: ResponsiveValue<SpacingValue>;

  /** Sets the `margin-top` css property on this element */
  @property({ reflect: true, converter: ResponsiveValueConverter })
  mt?: ResponsiveValue<SpacingValue>;

  /** Sets the `margin-right` css property on this element */
  @property({ reflect: true, converter: ResponsiveValueConverter })
  mr?: ResponsiveValue<SpacingValue>;

  /** Sets the `margin-bottom` css property on this element */
  @property({ reflect: true, converter: ResponsiveValueConverter })
  mb?: ResponsiveValue<SpacingValue>;

  /** Sets the `margin-left` css property on this element */
  @property({ reflect: true, converter: ResponsiveValueConverter })
  ml?: ResponsiveValue<SpacingValue>;

  /** Sets the `padding` css property on this element */
  @property({ reflect: true, converter: ResponsiveValueConverter })
  p?: ResponsiveValue<SpacingValue>;

  /** Sets the `padding-right` and `padding-left` css properties on this element */
  @property({ reflect: true, converter: ResponsiveValueConverter })
  px?: ResponsiveValue<SpacingValue>;

  /** Sets the `padding-top` and `padding-bottom` css properties on this element */
  @property({ reflect: true, converter: ResponsiveValueConverter })
  py?: ResponsiveValue<SpacingValue>;

  /** Sets the `padding-top` css property on this element */
  @property({ reflect: true, converter: ResponsiveValueConverter })
  pt?: ResponsiveValue<SpacingValue>;

  /** Sets the `padding-right` css property on this element */
  @property({ reflect: true, converter: ResponsiveValueConverter })
  pr?: ResponsiveValue<SpacingValue>;

  /** Sets the `padding-bottom` css property on this element */
  @property({ reflect: true, converter: ResponsiveValueConverter })
  pb?: ResponsiveValue<SpacingValue>;

  /** Sets the `padding-left` css property on this element */
  @property({ reflect: true, converter: ResponsiveValueConverter })
  pl?: ResponsiveValue<SpacingValue>;

  // -- Positioning Props --

  /** Sets the `display` css property on this element */
  @property({ reflect: true, converter: ResponsiveValueConverter }) d?: ResponsiveValue<
    | undefined
    | 'block'
    | 'inline-block'
    | 'flex'
    | 'inline-flex'
    | 'grid'
    | 'inline-grid'
    | 'inline'
    | 'list-item'
    | 'none'
    | 'initial'
    | 'inherit'
  >;

  /** Sets the `align-items` css property on this element */
  @property({ reflect: true, converter: ResponsiveValueConverter }) a?: ResponsiveValue<
    | undefined
    | 'flex-start'
    | 'start'
    | 'center'
    | 'end'
    | 'flex-end'
    | 'stretch'
    | 'baseline'
    | 'initial'
    | 'inherit'
  >;

  /** Sets the `align-items` css property on this element */
  @property({ reflect: true, converter: ResponsiveValueConverter }) alignItems?: ResponsiveValue<
    | undefined
    | 'flex-start'
    | 'start'
    | 'center'
    | 'flex-end'
    | 'end'
    | 'stretch'
    | 'baseline'
    | 'initial'
    | 'inherit'
  >;

  /** Sets the `align-self` css property on this element */
  @property({ reflect: true, converter: ResponsiveValueConverter }) alignSelf?: ResponsiveValue<
    | undefined
    | 'auto'
    | 'flex-start'
    | 'start'
    | 'center'
    | 'flex-end'
    | 'end'
    | 'stretch'
    | 'baseline'
    | 'initial'
    | 'inherit'
  >;

  /** Sets the `align-content` css property on this element */
  @property({ reflect: true, converter: ResponsiveValueConverter }) alignContent?: ResponsiveValue<
    | undefined
    | 'flex-start'
    | 'start'
    | 'center'
    | 'flex-end'
    | 'end'
    | 'stretch'
    | 'space-between'
    | 'space-around'
    | 'space-evenly'
    | 'initial'
    | 'inherit'
  >;

  /** Sets the `justify-content` css property on this element */
  @property({ reflect: true, converter: ResponsiveValueConverter }) j?: ResponsiveValue<
    | undefined
    | 'flex-start'
    | 'start'
    | 'center'
    | 'flex-end'
    | 'end'
    | 'space-between'
    | 'space-around'
    | 'space-evenly'
    | 'initial'
    | 'inherit'
  >;

  /** Sets the `justify-content` css property on this element */
  @property({ reflect: true, converter: ResponsiveValueConverter })
  justifyContent?: ResponsiveValue<
    | undefined
    | 'flex-start'
    | 'start'
    | 'center'
    | 'flex-end'
    | 'end'
    | 'space-between'
    | 'space-around'
    | 'space-evenly'
    | 'initial'
    | 'inherit'
  >;

  /** Sets the `justify-items` css property on this element */
  @property({ reflect: true, converter: ResponsiveValueConverter }) justifyItems?: ResponsiveValue<
    | undefined
    | 'normal'
    | 'stretch'
    | 'flex-start'
    | 'start'
    | 'left'
    | 'center'
    | 'flex-end'
    | 'end'
    | 'right'
    | 'initial'
    | 'inherit'
  >;

  /** Sets the `justify-self` css property on this element */
  @property({ reflect: true, converter: ResponsiveValueConverter }) justifySelf?: ResponsiveValue<
    | undefined
    | 'normal'
    | 'stretch'
    | 'start'
    | 'flex-start'
    | 'left'
    | 'center'
    | 'flex-end'
    | 'end'
    | 'right'
    | 'initial'
    | 'inherit'
  >;

  /** Sets the `flex` css property on this element */
  @property({ reflect: true, converter: ResponsiveValueConverter })
  flex: ResponsiveValue<UnitValue>;

  /** Sets the `flex-direction` css property on this element */
  @property({ reflect: true, converter: ResponsiveValueConverter }) direction?: ResponsiveValue<
    undefined | 'row' | 'rowReverse' | 'column' | 'columnReverse' | 'initial' | 'inherit'
  >;

  /** Sets the `flex-direction` css property on this element */
  @property({ reflect: true, converter: ResponsiveValueConverter }) flexDirection?: ResponsiveValue<
    undefined | 'row' | 'rowReverse' | 'column' | 'columnReverse' | 'initial' | 'inherit'
  >;

  /** Sets the `flex-basis` css property on this element */
  @property({ reflect: true, converter: ResponsiveValueConverter }) basis?: ResponsiveValue<
    number | string | undefined
  >;

  /** Sets the `flex-basis` css property on this element */
  @property({ reflect: true, converter: ResponsiveValueConverter }) flexBasis?: ResponsiveValue<
    number | string | undefined
  >;

  /** Sets the `flex-grow` css property on this element */
  @property({ reflect: true, converter: ResponsiveValueConverter }) grow?: ResponsiveValue<
    number | string | undefined
  >;

  /** Sets the `flex-grow` css property on this element */
  @property({ reflect: true, converter: ResponsiveValueConverter }) flexGrow?: ResponsiveValue<
    number | string | undefined
  >;

  /** Sets the `flex-shrink` css property on this element */
  @property({ reflect: true, converter: ResponsiveValueConverter }) shrink?: ResponsiveValue<
    number | string | undefined
  >;

  /** Sets the `flex-shrink` css property on this element */
  @property({ reflect: true, converter: ResponsiveValueConverter }) flexShrink?: ResponsiveValue<
    number | string | undefined
  >;

  /** Sets the `flex-wrap` css property on this element */
  @property({ reflect: true, converter: ResponsiveValueConverter }) wrap?: ResponsiveValue<
    undefined | 'nowrap' | 'wrap' | 'wrap-reverse' | 'initial' | 'inherit'
  >;

  /** Sets the `flex-wrap` css property on this element */
  @property({ reflect: true, converter: ResponsiveValueConverter }) flexWrap?: ResponsiveValue<
    undefined | 'nowrap' | 'wrap' | 'wrap-reverse' | 'initial' | 'inherit'
  >;

  /** Sets the `order` css property on this element */
  @property({ reflect: true, converter: ResponsiveValueConverter }) order?: ResponsiveValue<
    number | string
  >;

  /** Sets the `gap` css property on this element */
  @property({ reflect: true, converter: ResponsiveValueConverter })
  gap?: ResponsiveValue<UnitValue>;

  /** Sets the `column-gap` css property on this element */
  @property({ reflect: true, converter: ResponsiveValueConverter })
  columnGap?: ResponsiveValue<UnitValue>;

  /** Sets the `row-gap` css property on this element */
  @property({ reflect: true, converter: ResponsiveValueConverter })
  rowGap?: ResponsiveValue<UnitValue>;

  /** Sets the `grid-column` css property on this element */
  @property({ reflect: true, converter: ResponsiveValueConverter }) gridColumn?: ResponsiveValue<
    string | undefined
  >;

  /** Sets the `grid-row` css property on this element */
  @property({ reflect: true, converter: ResponsiveValueConverter }) gridRow?: ResponsiveValue<
    string | undefined
  >;

  /** Sets the `grid-auto-flow` css property on this element */
  @property({ reflect: true, converter: ResponsiveValueConverter }) gridAutoFlow?: ResponsiveValue<
    undefined | 'row' | 'column' | 'dense' | 'row dense' | 'column dense'
  >;

  /** Sets the `grid-auto-columns` css property on this element */
  @property({ reflect: true, converter: ResponsiveValueConverter })
  gridAutoColumns?: ResponsiveValue<'auto' | 'max-content' | 'min-content' | UnitValue>;

  /** Sets the `grid-auto-rows` css property on this element */
  @property({ reflect: true, converter: ResponsiveValueConverter }) gridAutoRows?: ResponsiveValue<
    'auto' | 'max-content' | 'min-content' | UnitValue
  >;

  /** Sets the `grid-template-columns` css property on this element */
  @property({ reflect: true, converter: ResponsiveValueConverter })
  gridTemplateColumns?: ResponsiveValue<
    'none' | 'auto' | 'max-content' | 'min-content' | UnitValue
  >;

  /** Sets the `grid-template-rows` css property on this element */
  @property({ reflect: true, converter: ResponsiveValueConverter })
  gridTemplateRows?: ResponsiveValue<'none' | 'auto' | 'max-content' | 'min-content' | UnitValue>;

  /** Sets the `grid-template-areas` css property on this element */
  @property({ reflect: true, converter: ResponsiveValueConverter })
  gridTemplateAreas?: ResponsiveValue<'none' | string | undefined>;

  /** Sets the `grid-area` css property on this element */
  @property({ reflect: true, converter: ResponsiveValueConverter }) gridArea?: ResponsiveValue<
    string | undefined
  >;

  // -- Sizing Props --

  /** Sets the `width` css property on this element */
  @property({ reflect: true, converter: ResponsiveValueConverter }) w?: ResponsiveValue<UnitValue>;

  /** Sets the `max-width` css property on this element */
  @property({ reflect: true, converter: ResponsiveValueConverter })
  maxw?: ResponsiveValue<UnitValue>;

  /** Sets the `min-width` css property on this element */
  @property({ reflect: true, converter: ResponsiveValueConverter })
  minw?: ResponsiveValue<UnitValue>;

  /** Sets the `height` css property on this element */
  @property({ reflect: true, converter: ResponsiveValueConverter }) h?: ResponsiveValue<UnitValue>;

  /** Sets the `max-height` css property on this element */
  @property({ reflect: true, converter: ResponsiveValueConverter })
  maxh?: ResponsiveValue<UnitValue>;

  /** Sets the `min-height` css property on this element */
  @property({ reflect: true, converter: ResponsiveValueConverter })
  minh?: ResponsiveValue<UnitValue>;

  /** Sets the `background` css property on this element. Can be passed dot notated theme config strings to select color token values. */
  @property({ reflect: true, converter: ResponsiveValueConverter }) bg?: ResponsiveValue<
    string | undefined
  >;

  /** Sets the `color` css property on this element. Can be passed dot notated theme config strings to select color token values. */
  @property({ reflect: true, converter: ResponsiveValueConverter }) c?: ResponsiveValue<
    string | undefined
  >;

  /** Sets the `border-color` css property on this element. Can be passed dot notated theme config strings to select color token values. */
  @property({ reflect: true, converter: ResponsiveValueConverter }) borderColor?: ResponsiveValue<
    string | undefined
  >;

  /** Sets the `border-width` css property on this element. */
  @property({ reflect: true, converter: ResponsiveValueConverter })
  borderWidth?: ResponsiveValue<BorderWidthValue>;

  /** Sets the `border-style` css property on this element. */
  @property({ reflect: true, converter: ResponsiveValueConverter }) borderStyle?: ResponsiveValue<
    undefined | 'solid' | 'dotted' | 'dashed'
  >;

  /** Sets the `border` css property on this element. */
  @property({ type: Boolean, reflect: true, converter: ResponsiveValueConverter })
  border?: ResponsiveValue<boolean | undefined>;

  /** Sets the `border-top` css property on this element. */
  @property({ type: Boolean, reflect: true, converter: ResponsiveValueConverter })
  borderTop?: ResponsiveValue<boolean | undefined>;

  /** Sets the `border-right` css property on this element. */
  @property({ type: Boolean, reflect: true, converter: ResponsiveValueConverter })
  borderRight?: ResponsiveValue<boolean | undefined>;

  /** Sets the `border-bottom` css property on this element. */
  @property({ type: Boolean, reflect: true, converter: ResponsiveValueConverter })
  borderBottom?: ResponsiveValue<boolean | undefined>;

  /** Sets the `border-left` css property on this element. */
  @property({ type: Boolean, reflect: true, converter: ResponsiveValueConverter })
  borderLeft?: ResponsiveValue<boolean | undefined>;

  /** Sets the `border-radius` css property on this element. */
  @property({ reflect: true, converter: ResponsiveValueConverter })
  borderRadius?: ResponsiveValue<BorderRadiusValue>;

  /** Sets the `border-top-right-radius` css property on this element. */
  @property({ reflect: true, converter: ResponsiveValueConverter })
  borderTopRightRadius?: ResponsiveValue<BorderRadiusValue>;

  /** Sets the `border-top-left-radius` css property on this element. */
  @property({ reflect: true, converter: ResponsiveValueConverter })
  borderTopLeftRadius?: ResponsiveValue<BorderRadiusValue>;

  /** Sets the `border-bottom-right-radius` css property on this element. */
  @property({ reflect: true, converter: ResponsiveValueConverter })
  borderBottomRightRadius?: ResponsiveValue<BorderRadiusValue>;

  /** Sets the `border-bottom-left-radius` css property on this element. */
  @property({ reflect: true, converter: ResponsiveValueConverter })
  borderBottomLeftRadius?: ResponsiveValue<BorderRadiusValue>;

  /** Sets the `border-top-right-radius` and `border-top-left-radius` css properties on this element. */
  @property({ reflect: true, converter: ResponsiveValueConverter })
  borderTopRadius?: ResponsiveValue<BorderRadiusValue>;

  /** Sets the `border-top-right-radius` and `border-bottom-right-radius` css properties on this element. */
  @property({ reflect: true, converter: ResponsiveValueConverter })
  borderRightRadius?: ResponsiveValue<BorderRadiusValue>;

  /** Sets the `border-bottom-right-radius` and `border-bottom-left-radius` css properties on this element. */
  @property({ reflect: true, converter: ResponsiveValueConverter })
  borderBottomRadius?: ResponsiveValue<BorderRadiusValue>;

  /** Sets the `border-top-left-radius` and `border-bottom-left-radius` css properties on this element. */
  @property({ reflect: true, converter: ResponsiveValueConverter })
  borderLeftRadius?: ResponsiveValue<BorderRadiusValue>;

  /** Sets the `line-height` css property  */
  @property({ reflect: true, converter: ResponsiveValueConverter }) lineHeight?: ResponsiveValue<
    string | number
  >;

  /** Sets the `font-weight` css property */
  @property({ reflect: true, converter: ResponsiveValueConverter }) fontWeight?: ResponsiveValue<
    | undefined
    | 'light'
    | 'regular'
    | 'medium'
    | 'lighter'
    | 'normal'
    | 'bold'
    | 'bolder'
    | 'initial'
    | 'inherit'
    | '100'
    | '200'
    | '300'
    | '400'
    | '500'
    | '600'
    | '700'
    | '800'
    | '900'
  >;

  /** Shorthand for `fontWeight`. Sets the `font-weight` css property */
  @property({ reflect: true, converter: ResponsiveValueConverter }) fw?: ResponsiveValue<
    | undefined
    | 'light'
    | 'regular'
    | 'medium'
    | 'lighter'
    | 'normal'
    | 'bold'
    | 'bolder'
    | 'initial'
    | 'inherit'
    | '100'
    | '200'
    | '300'
    | '400'
    | '500'
    | '600'
    | '700'
    | '800'
    | '900'
  >;

  /** Sets the `font-size` css property */
  @property({ reflect: true, converter: ResponsiveValueConverter }) fontSize?: ResponsiveValue<
    undefined | UnitValue | 'xxs' | 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'xxl'
  >;

  /** Shorthand for `fontSize`. Sets the `font-size` css property */
  @property({ reflect: true, converter: ResponsiveValueConverter }) fs?: ResponsiveValue<
    undefined | UnitValue | 'xxs' | 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'xxl'
  >;

  /** Sets the `font-family` css property */
  @property({ reflect: true, converter: ResponsiveValueConverter }) fontFamily?: ResponsiveValue<
    undefined | 'sans' | 'serif' | 'mono' | string
  >;

  /** Shorthand for `opacity`. Sets the `opacity` css property */
  @property({ reflect: true, converter: ResponsiveValueConverter })
  opacity?: ResponsiveValue<OpacityValue>;

  /** Applies Mortar standard box-shadow values */
  @property({ reflect: true, converter: ResponsiveValueConverter }) elevation?: ResponsiveValue<
    'z0' | 'z1' | 'z2' | 'z3' | 'z4'
  >;

  /** Adjust this elements bleed if it is a direct descendant of an mte-layout-container */
  @property({ reflect: true }) layoutBleed?: 'full' | 'popout' | 'content' | undefined;

  /**
   * Renders this element as a subgrid when set. `display` is set to `grid` and `grid-template-columns` is set to `subgrid`.
   * @experimental
   */
  @property({ type: Boolean, reflect: true }) subgrid = false;

  /**
   * Override this selector to have instance styles target a specific inner element.
   * Useful in specific scenarios where the primary content/slot wrapped within the template
   * like with the modal component.
   */
  protected contentStyleSelectorRoot = ':host:host:host:host:host:host';

  // List of props for optimal style update checking
  private static contentProps = new Set([
    'm',
    'mx',
    'my',
    'mt',
    'mr',
    'mb',
    'ml',
    'p',
    'px',
    'py',
    'pt',
    'pr',
    'pb',
    'pl',
    'd',
    'a',
    'j',
    'basis',
    'shrink',
    'grow',
    'direction',
    'wrap',
    'alignItems',
    'alignSelf',
    'alignContent',
    'justifyContent',
    'justifyItems',
    'justifySelf',
    'flex',
    'flexDirection',
    'flexBasis',
    'flexShrink',
    'flexGrow',
    'flexWrap',
    'order',
    'gap',
    'columnGap',
    'rowGap',
    'gridColumn',
    'gridRow',
    'gridAutoFlow',
    'gridAutoColumns',
    'gridAutoRows',
    'gridTemplateColumns',
    'gridTemplateRows',
    'gridTemplateAreas',
    'gridArea',
    'w',
    'maxw',
    'minw',
    'h',
    'maxh',
    'minh',
    'bg',
    'c',
    'borderColor',
    'borderWidth',
    'borderStyle',
    'border',
    'borderTop',
    'borderRight',
    'borderBottom',
    'borderLeft',
    'borderRadius',
    'borderTopRightRadius',
    'borderTopLeftRadius',
    'borderBottomRightRadius',
    'borderBottomLeftRadius',
    'borderTopRadius',
    'borderRightRadius',
    'borderBottomRadius',
    'borderLeftRadius',
    'lineHeight',
    'fontWeight',
    'fw',
    'fontSize',
    'fs',
    'fontFamily',
    'opacity',
    'elevation',
    'layoutBleed',
    'subgrid',
  ]);

  protected updateInstanceStyles(changedProps: Map<string, any>) {
    super.updateInstanceStyles(changedProps);

    // Check if any content prop has changed to determine if we need to update the instance styles
    let shouldUpdateStyles = false;
    for (const key of changedProps.keys()) {
      if (MteContentBase.contentProps.has(key)) {
        shouldUpdateStyles = true;
        break;
      }
    }

    if (shouldUpdateStyles) {
      // Double the `:host` selector to increase specificity beyond most component level styles by default
      this.setInstanceStyle('content-base', selectorFactory(this.contentStyleSelectorRoot), {
        // Spacing
        m: this.m,
        mx: this.mx,
        my: this.my,
        mt: this.mt,
        mr: this.mr,
        mb: this.mb,
        ml: this.ml,
        p: this.p,
        px: this.px,
        py: this.py,
        pt: this.pt,
        pr: this.pr,
        pb: this.pb,
        pl: this.pl,
        // Positioning
        d: this.subgrid ? 'grid' : this.d,
        alignItems: this.a ?? this.alignItems,
        alignSelf: this.alignSelf,
        alignContent: this.alignContent,
        justifyContent: this.j ?? this.justifyContent,
        justifyItems: this.justifyItems,
        justifySelf: this.justifySelf,
        flex: this.flex,
        flexDirection: this.direction ?? this.flexDirection,
        flexBasis: this.basis ?? this.flexBasis,
        flexShrink: this.shrink ?? this.flexShrink,
        flexGrow: this.grow ?? this.flexGrow,
        flexWrap: this.wrap ?? this.flexWrap,
        order: this.order,
        gap: this.gap,
        columnGap: this.columnGap,
        rowGap: this.rowGap,
        gridColumn: this.layoutBleed ? this.layoutBleed : this.gridColumn,
        gridRow: this.gridRow,
        gridAutoFlow: this.gridAutoFlow,
        gridAutoColumns: this.gridAutoColumns,
        gridAutoRows: this.gridAutoRows,
        gridTemplateColumns: this.subgrid ? 'subgrid' : this.gridTemplateColumns,
        gridTemplateRows: this.gridTemplateRows,
        gridTemplateAreas: this.gridTemplateAreas,
        gridArea: this.gridArea,
        // Sizing
        w: this.w,
        maxw: this.maxw,
        minw: this.minw,
        h: this.h,
        maxh: this.maxh,
        minh: this.minh,
        // Colors
        bg: this.bg,
        c: this.c,
        // Borders
        borderColor: this.borderColor,
        borderWidth: this.borderWidth,
        borderStyle: this.borderStyle,
        border: this.border
          ? defaultBorderFactory(this.borderWidth, this.borderColor, this.borderStyle)
          : null,
        borderTop: this.borderTop
          ? defaultBorderFactory(this.borderWidth, this.borderColor, this.borderStyle)
          : null,
        borderRight: this.borderRight
          ? defaultBorderFactory(this.borderWidth, this.borderColor, this.borderStyle)
          : null,
        borderLeft: this.borderLeft
          ? defaultBorderFactory(this.borderWidth, this.borderColor, this.borderStyle)
          : null,
        borderBottom: this.borderBottom
          ? defaultBorderFactory(this.borderWidth, this.borderColor, this.borderStyle)
          : null,
        // Border Radius
        borderRadius: this.borderRadius,
        borderTopRightRadius:
          this.borderTopRightRadius ?? this.borderTopRadius ?? this.borderRightRadius,
        borderTopLeftRadius:
          this.borderTopLeftRadius ?? this.borderTopRadius ?? this.borderLeftRadius,
        borderBottomRightRadius:
          this.borderBottomRightRadius ?? this.borderBottomRadius ?? this.borderRightRadius,
        borderBottomLeftRadius:
          this.borderBottomLeftRadius ?? this.borderBottomRadius ?? this.borderLeftRadius,
        // Fonts
        lineHeight: this.lineHeight,
        fontWeight: this.fw ?? this.fontWeight,
        fontSize: this.fs ?? this.fontSize,
        fontFamily: this.fontFamily,
        // Opacity
        opacity: this.opacity,
        // Elevation
        elevation: this.elevation,
      });
    }
  }
}
