import { ResponsiveValue } from './../../core/base-classes/content.base';
import { html } from 'lit';
import { property } from 'lit/decorators.js';
import {
  defineElement,
  baseStyles,
  MteContentBase,
  mapNumberToPx,
  ResponsiveValueConverter,
  mapResponsiveNumberToPx,
  WaitForFrameworkMixin,
} from '../../core';
import { styles } from './layout-container.styles';
import { selectorFactory } from '@mortar/styles';

/**
 * @slot - Default slotted content
 */
@defineElement('mte-layout-container')
export class MteLayoutContainer extends WaitForFrameworkMixin(MteContentBase) {
  static styles = [baseStyles, styles];

  /** When set, the container will stretch across the entire available width and not constrain content other than with gutters. */
  @property({ type: Boolean, reflect: true }) fluid = false;

  /** Can be set to customize the width of the popout bleed. Defaults to just the size of the gutter. */
  @property({ reflect: true, converter: ResponsiveValueConverter })
  popoutBleedWidth?: ResponsiveValue<string | number>;

  protected updateInstanceStyles(changedProps) {
    super.updateInstanceStyles(changedProps);

    if (changedProps.has('popoutBleedWidth')) {
      this.setInstanceStyle('mte-grid', selectorFactory(this.instanceStyleSelectorRoot), {
        '--popout-bleed-width': mapResponsiveNumberToPx(this.popoutBleedWidth),
      });
    }
  }

  render() {
    return html`${this.instanceStyles}<slot></slot>`;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'mte-layout-container': MteLayoutContainer;
  }
}
