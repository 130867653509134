import { Presets } from './../../core';

export interface TreePreset {}

export const treePresets: Presets<TreePreset> = {
  default: {},
  cx: {},
  rx: {},
  hw: {},
};
