import { html } from 'lit';
import { baseStyles, defineElement, MteContentBase } from '../../core';
import { styles } from './content.styles';

/**
 *
 */
@defineElement('mte-content')
export class MteContent extends MteContentBase {
  static styles = [baseStyles, styles];

  render() {
    return html`${this.instanceStyles}<slot></slot>`;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'mte-content': MteContent;
  }
}
