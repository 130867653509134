import { html } from 'lit';
import { MteElement, defineElement, MteElementParts, baseStyles } from '../../core';
import { styles } from './visually-hidden.styles';

export interface MteVisuallyHiddenParts extends MteElementParts {}

/**
 * @slot - The content to be visually hidden.
 */
@defineElement('mte-visually-hidden')
export class MteVisuallyHidden extends MteElement {
  static styles = [baseStyles, styles];

  render() {
    return html`<slot></slot>`;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'mte-visually-hidden': MteVisuallyHidden;
  }
}
