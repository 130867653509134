import { ReactiveControllerHost } from 'lit';
import {
  getFocusableElements,
  isElementFocused,
  normalizeTabIndex,
} from '../utilities/focus.utilities';

const instances = [];

/** Returns true if any focus trap is active */
export const isFocusTrapActive = () => {
  return instances.length > 0;
};

/** A controller for trapping focus within a DOM node.*/
export class FocusTrapController {
  private trapNode?: Element | HTMLElement = null;
  private restoreTarget?: Element | HTMLElement;

  /** An array of tab-ordered focusable elements inside the trap node. */
  private get focusableElements() {
    return getFocusableElements(this.trapNode);
  }

  /** The index of the element inside the trap node that currently has focus. */
  private get focusedElementIndex() {
    const focusableElements = this.focusableElements;
    return focusableElements?.indexOf(focusableElements?.filter(isElementFocused).pop());
  }

  constructor(private host: ReactiveControllerHost & Element) {
    this.host.addController(this);
    this.onKeyDown = this.onKeyDown.bind(this);
  }

  hostConnected() {
    document.addEventListener('keydown', this.onKeyDown);
  }

  hostDisconnected() {
    document.removeEventListener('keydown', this.onKeyDown);
  }

  /**
   * Activates a focus trap for a DOM node that will prevent focus from escaping the node.
   * The trap can be deactivated with the `.releaseFocus()` method.
   *
   * If focus is initially outside the trap, the method will move focus inside,
   * on the first focusable element of the trap in the tab order.
   * The first focusable element can be the trap node itself if it is focusable
   * and comes first in the tab order.
   *
   * If there are no focusable elements, the method will throw an exception
   * and the trap will not be set.
   *
   * If an element is passed to `restoreFocusOnDone` that element will be focused upon
   * release of the focus trap.
   */
  trapFocus(trapNode: Element | HTMLElement, restoreFocusOnRelease?: Element | HTMLElement) {
    this.trapNode = trapNode;
    this.restoreTarget = restoreFocusOnRelease ?? null;

    if (this.focusableElements.length === 0) {
      // Allows us to focus things like popovers if they contain not focusable children
      if (this.trapNode.hasAttribute('data-focus-fallback')) {
        (this.trapNode as HTMLElement).focus();
      } else {
        this.trapNode = null;
      }
      // TODO(Reece): Decide if we want to throw error or warn at all here
      // throw new Error(
      //   'The trap node should have at least one focusable descendant or be focusable itself.'
      // );
    }

    instances.push(this);

    const elem = this.trapNode?.querySelector('[data-autofocus]') as any;
    if (elem && normalizeTabIndex(elem) !== -1) {
      elem.focus();
      if (elem.showFocusRing !== undefined) {
        elem.showFocusRing = true;
      }
    } else if (this.focusedElementIndex === -1 && this.focusableElements?.length > 0) {
      this.focusableElements[0].focus();
      if (this.focusableElements[0].showFocusRing !== undefined) {
        this.focusableElements[0].showFocusRing = true;
      }
    }
  }

  public isTrappingFocus() {
    return this.trapNode !== null;
  }

  /**
   * Deactivates the focus trap set with the `.trapFocus()` method
   * so that it becomes possible to tab outside the trap node.
   */
  releaseFocus(preventScroll = false) {
    this.trapNode = null;
    instances.pop();
    (this.restoreTarget as any)?.focus?.({ preventScroll: preventScroll });
  }

  /**
   * A `keydown` event handler that manages tabbing navigation when the trap is enabled.
   *
   * - Moves focus to the next focusable element of the trap on `Tab` press.
   * When no next element to focus, the method moves focus to the first focusable element.
   * - Moves focus to the prev focusable element of the trap on `Shift+Tab` press.
   * When no prev element to focus, the method moves focus to the last focusable element.
   */
  private onKeyDown(event) {
    if (!this.trapNode) {
      return;
    }

    // Only handle events for the last instance
    if (this !== Array.from(instances).pop()) {
      return;
    }

    if (event.key === 'Tab') {
      event.preventDefault();

      const backward = event.shiftKey;
      this.focusNextElement(backward);
    }
  }

  /**
   * - Moves focus to the next focusable element if `backward === false`.
   * When no next element to focus, the method moves focus to the first focusable element.
   * - Moves focus to the prev focusable element if `backward === true`.
   * When no prev element to focus the method moves focus to the last focusable element.
   *
   * If no focusable elements, the method returns immediately.
   */
  private focusNextElement(backward = false) {
    const focusableElements = this.focusableElements;
    if (focusableElements?.length > 0) {
      const step = backward ? -1 : 1;
      const currentIndex = this.focusedElementIndex;
      const nextIndex = (focusableElements.length + currentIndex + step) % focusableElements.length;
      focusableElements[nextIndex].focus();
    }
  }
}
