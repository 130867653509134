/* eslint-disable wc/no-self-class */
import { property, state } from 'lit/decorators.js';
import { LitElement } from 'lit';
import { selectorFactory, SelectorFactory } from '@mortar/styles';
import {
  constructInstanceStyles,
  generateSelectorsFromStyleMap,
  SelectorStyles,
  StyleMap,
  Unsubscriber,
} from '../utilities';
import { unsafeHTML } from 'lit-html/directives/unsafe-html.js';
import { MortarPresetTheme } from '../presets';

// Disable this warning for now by pre-marking it as issued so users don't have to see it
// TODO: Ask lit team why this is thrown everywhere and what alternatives are for preset solution
globalThis.litIssuedWarnings ??= new Set();
globalThis.litIssuedWarnings.add(
  'Overriding ReactiveElement.getPropertyDescriptor() is deprecated. The override will not be called with standard decorators See https://lit.dev/msg/no-override-get-property-descriptor for more information.'
);

export class MteElement extends LitElement {
  private __instanceStyleSheetMap = new Map<string, SelectorStyles[]>();
  private __instanceStyleSheetNeedsUpdate = false;

  /** Set to true when firstUpdated is first called */
  protected __firstUpdated = false;

  protected instanceStyles;

  /**
   * All subs pushed to this array will be cleared in the disconnectedCallback
   * @ignore
   */
  subs: Unsubscriber[] = [];

  /** Style mappings that are applied to the host element. */
  @property({ type: Object, reflect: true }) se?: StyleMap;

  /**
   * The theme to use for styling this component. Overrides the global preset for this instance of this component.
   * @ignore
   */
  @property({ reflect: true, attribute: 'data-mte-theme' })
  set theme(theme: string) {
    const oldValue = this._theme;
    this._theme = theme;
    this.requestUpdate('theme', oldValue);
    // TODO: figure out a better way to solve this for all presets
    if (this.__localPresetTheme) {
      // setTimeout(() => this.requestUpdate('theme', null));
      this.updateComplete.then(() => this.requestUpdate('theme', null));
    }
  }
  get theme(): string {
    return this._theme ?? this.__localPresetTheme?.name;
  }
  private _theme: string;

  /** Used to declare a local theme value determined by the local preset if it is set. */
  @state() protected __localPresetTheme: MortarPresetTheme;

  /**
   * Override this selector to have instance styles target a specific inner element.
   * Useful in specific scenarios where focus is delegated like buttons.
   *
   * By default this repeats `:host` six times to provide a specificity of 6
   */
  protected instanceStyleSelectorRoot = ':host:host:host:host:host:host';

  /**
   * Can be called by derived classes to register new instance level dynamic styles.
   * When called the instance stylesheet will be replaced with the latest styles this update.
   */
  protected setInstanceStyle(id: string, selector: SelectorFactory, styleMap: StyleMap) {
    // We parse all style information here and scope it to the given id. This ensures we
    // only ever need to re-parse this portion of style information when its associated
    // id is updated again.
    this.__instanceStyleSheetMap.set(id, generateSelectorsFromStyleMap(selector, styleMap));
    this.__instanceStyleSheetNeedsUpdate = true;
  }

  /** A custom hook that can be overridden in derived classes to set instance styles as needed */
  protected updateInstanceStyles(changedProps) {
    if (changedProps.has('se')) {
      this.setInstanceStyle('se', selectorFactory(this.instanceStyleSelectorRoot), this.se ?? {});
    }
  }

  protected willUpdate(changedProps) {
    super.willUpdate(changedProps);

    // Call the updateInstanceStyles hook
    this.updateInstanceStyles(changedProps);

    // Check if instance styles need to be rerendered
    if (this.__instanceStyleSheetNeedsUpdate) {
      this.instanceStyles = unsafeHTML(
        `<style id="__instance-styles">${constructInstanceStyles(
          this.__instanceStyleSheetMap
        )}</style>`
      );
      this.__instanceStyleSheetNeedsUpdate = false;
    }
  }

  protected firstUpdated(changedProps) {
    this.__firstUpdated = true;
    super.firstUpdated(changedProps);
  }

  disconnectedCallback(): void {
    super.disconnectedCallback();

    this.subs.forEach((unsub) => unsub());
    this.subs = [];
  }
}
