import { Presets, RadiusOptions } from './../../core';

export interface CardPreset {
  radius?: RadiusOptions;
  withShadow?: boolean;
  withoutShadow?: boolean;
}

export interface CardButtonPreset extends CardPreset {}

export const cardPresets: Presets<CardPreset> = {
  default: {},
  cx: {
    withoutShadow: true,
  },
  rx: {},
  hw: {
    withoutShadow: true,
  },
};

export const cardButtonPresets: Presets<CardButtonPreset> = {
  default: {},
  cx: {
    withoutShadow: true,
  },
  rx: {},
  hw: {
    withoutShadow: true,
  },
};
