import { CorePalette, DensityOptions, Presets, SizeOptions, RadiusOptions } from '../../core';

export interface ButtonPreset {
  size?: SizeOptions;
  color?: CorePalette;
  density?: DensityOptions;
  radius?: RadiusOptions;
}

export const buttonPresets: Presets<ButtonPreset> = {
  default: {},
  cx: {},
  rx: {},
  hw: {},
};

export interface IconButtonPreset {
  size?: SizeOptions;
  color?: CorePalette;
  density?: DensityOptions;
  radius?: RadiusOptions;
}

export const iconButtonPresets: Presets<IconButtonPreset> = {
  default: {},
  cx: {},
  rx: {},
  hw: {},
};
