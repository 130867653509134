import { MteElement, ResponsiveValue } from '../base-classes';
import { capitalCase } from './case.utilities';
import { mapNumberToPx } from './css-in-js';

export const getResponsivePropVal = (c: MteElement, prop: string, bp: string) => {
  if (c[prop] && typeof c[prop] !== 'object') {
    return bp === '' ? c[prop] : c[`${prop}${capitalCase(bp)}`];
  } else if (c[prop] && typeof c[prop] === 'object' && bp !== '') {
    return c[prop][bp.toLowerCase()] ?? c[`${prop}${capitalCase(bp)}`];
  } else if (bp !== '') {
    return c[`${prop}${capitalCase(bp)}`];
  } else {
    return null;
  }
};

export const mapResponsiveNumberToPx = (value: ResponsiveValue<string | number>) => {
  if (typeof value === 'object') {
    Object.keys(value).forEach((key) => {
      value[key] = mapNumberToPx(value[key]);
    });
    return value;
  } else {
    return mapNumberToPx(value);
  }
};
