import { ReactiveControllerHost } from 'lit';
import { isSsr } from '../utilities';

export class RestoreFocusController {
  private restoreFocusTarget: Element;

  constructor(private host: ReactiveControllerHost & Element) {
    this.host.addController(this);
  }

  hostConnected() {}

  /**
   * Sets the target element that will be focused when restoreFocus is requests.
   * If not defined, the `document.activeElement` will be used instead.
   */
  setRestoreFocusTarget(restoreFocusTarget: Element | void) {
    if (restoreFocusTarget) {
      this.restoreFocusTarget = restoreFocusTarget;
    } else if (!isSsr()) {
      this.restoreFocusTarget = document.activeElement as Element;
    }
  }

  /** Attempts to restor focus to the restoreFocus target */
  restoreFocus(preventScroll = false) {
    (this.restoreFocusTarget as any)?.focus?.({ preventScroll: preventScroll });
  }
}
