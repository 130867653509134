import { ReactiveControllerHost } from 'lit';

export class ClickOutsideController {
  private targets = new Set<HTMLElement>();
  private listening = false;

  constructor(private host: ReactiveControllerHost & Element, private callback: () => any) {
    this.host.addController(this);

    this.handleClickOutside = this.handleClickOutside.bind(this);
  }

  hostConnected() {}

  hostDisconnected() {
    this.removeListeners();
  }

  listenForClicksOutsideOf(element: HTMLElement) {
    this.targets.add(element);
    this.addListeners();
  }

  addTarget(element: HTMLElement) {
    this.targets.add(element);
  }

  removeTarget(element: HTMLElement) {
    this.targets.delete(element);
  }

  stopListening() {
    this.removeListeners();
    this.targets.clear();
  }

  isListening() {
    return this.targets.size > 0;
  }

  private handleClickOutside(event: any) {
    const shouldIgnore = event?.target?.hasAttribute('data-ignore-outside-clicks');
    let shouldTrigger = true;
    const targetsIt = this.targets.entries();
    for (const target of targetsIt) {
      if (event.composedPath().includes(target[0])) {
        shouldTrigger = false;
        break;
      }
    }
    if (shouldTrigger && !shouldIgnore) {
      this.callback();
    }
  }

  private addListeners() {
    if (!this.listening) {
      this.listening = true;
      document.addEventListener('mousedown', this.handleClickOutside);
      document.addEventListener('touchstart', this.handleClickOutside);
    }
  }

  private removeListeners() {
    this.listening = false;
    document.removeEventListener('mousedown', this.handleClickOutside);
    document.removeEventListener('touchstart', this.handleClickOutside);
  }
}
