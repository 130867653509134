import { Presets, StatusPalette } from './../../core';

export interface IconPreset {
  color?: StatusPalette;
}

export const iconPresets: Presets<IconPreset> = {
  default: {},
  cx: {},
  rx: {},
  hw: {},
};
