import { Presets } from '../../core';

export interface NavRootPreset {
  mobileBreakpoint?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'xxl';
  inverse?: 'header' | 'sidebar';
  preferSidebar?: 'opened' | 'closed';
}

export const navRootPresets: Presets<NavRootPreset> = {
  default: {
    inverse: 'sidebar',
  },
  cx: {
    preferSidebar: 'closed',
  },
  rx: {
    preferSidebar: 'closed',
  },
  hw: {
    preferSidebar: 'closed',
  },
};

export interface NavHeaderPreset {
  withContainer?: boolean;
}

export const navHeaderPresets: Presets<NavHeaderPreset> = {
  default: {},
  cx: {},
  rx: {},
  hw: {},
};

export interface NavSubHeaderPreset {
  withContainer?: boolean;
}

export const navSubHeaderPresets: Presets<NavSubHeaderPreset> = {
  default: {},
  cx: {},
  rx: {},
  hw: {},
};

export interface NavSidebarPreset {
  hideUntilTransition?: boolean;
}

export const navSidebarPresets: Presets<NavSidebarPreset> = {
  default: {},
  cx: {
    hideUntilTransition: true,
  },
  rx: {
    hideUntilTransition: true,
  },
  hw: {
    hideUntilTransition: true,
  },
};

export interface NavContentPreset {}

export const navContentPresets: Presets<NavContentPreset> = {
  default: {},
  cx: {},
  rx: {},
  hw: {},
};

export interface NavFooterPreset {
  withContainer?: boolean;
}

export const navFooterPresets: Presets<NavFooterPreset> = {
  default: {},
  cx: {},
  rx: {},
  hw: {},
};

export interface NavButtonPreset {}

export const navButtonPresets: Presets<NavButtonPreset> = {
  default: {},
  cx: {},
  rx: {},
  hw: {},
};

export interface NavIconButtonPreset {}

export const navIconButtonPresets: Presets<NavIconButtonPreset> = {
  default: {},
  cx: {},
  rx: {},
  hw: {},
};

export interface NavItemPreset {}

export const navItemPresets: Presets<NavItemPreset> = {
  default: {},
  cx: {},
  rx: {},
  hw: {},
};
