/**
 * NOTE:
 *
 * This file contains implementation for an extremely simple and lightweight
 * reactive solution loosely based on solid-js style signals. It enables basic
 * reactive subscriptions and computed signals of one or more other dependency signals.
 *
 * In its current state, it is not perfect and does not handle all edge cases. For
 * example, if you have multiple layers of computed state with a single dependency
 * shared more than once across those layers, the same change will potentially be
 * emitted twice. Our expectation atm, is that we won't encounter those scenarios
 * in this library.
 */

/** Unsubscribes from value updates. */
export type Unsubscriber = () => void;

export interface Signal<T> {
  /** Get the current value */
  get(): T;

  /** Set reactive value */
  set(value: T);

  /** Update reactive value using callback */
  update(fn: (value: T) => T);

  /** Subscribe to reactive value changes */
  subscribe(fn: (value: T, unsubscribe?: Unsubscriber) => void): Unsubscriber;
}

const context = [];

function _subscribe(running, subscriptions) {
  subscriptions.add(running);
  running.dependencies.add(subscriptions);
}

function cleanup(running) {
  for (const dep of running.dependencies) {
    dep.delete(running);
  }
  running.dependencies.clear();
}

/** Create a signal that allows both updating and reading by getter or subscription. */
export function signal<T>(value?: T) {
  const subscriptions = new Set<any>();

  const get = (): T => {
    const running = context[context.length - 1];
    if (running) _subscribe(running, subscriptions);
    return value;
  };

  const set = (nextValue: T) => {
    value = nextValue;
    for (const sub of [...subscriptions]) {
      sub.execute();
    }
  };

  const update = (fn: (value: T) => T) => {
    value = fn(value);
    for (const sub of [...subscriptions]) {
      sub.execute();
    }
  };

  // This is essentially a useEffect masquerading as a subscribe function
  const subscribe = (fn: (value: T, unsubscribe?: Unsubscriber) => void): Unsubscriber => {
    const execute = () => {
      cleanup(running);
      context.push(running);
      try {
        fn(running.get(), () => cleanup(running));
      } finally {
        context.pop();
      }
      return () => cleanup(running);
    };

    const running = {
      execute,
      dependencies: new Set(),
      get,
    };

    return execute();
  };

  return {
    get,
    set,
    update,
    subscribe,
  } as Signal<T>;
}

/** One or more `Signal`s */
type Signals = Signal<any> | [Signal<any>, ...Array<Signal<any>>] | Signal<any>[];

/** One or more values from signals */
export type SignalValues<T> = T extends Signal<infer U>
  ? U
  : { [K in keyof T]: T[K] extends Signal<infer U> ? U : never };

/** Create a new computed signal from one or more signal dependencies */
export function computed<D extends Signals, T>(
  dependencies: D,
  fn: (values: SignalValues<D>) => T
): Signal<T>;

/** Create a new computed signal from one or more signal dependencies */
export function computed<D extends Signals, T>(
  dependencies: D,
  fn?: (values: SignalValues<D>, set?: (value: any) => void) => T
): Signal<unknown>;

/** Create a new computed signal from one or more signal dependencies */
export function computed<D extends Signals>(
  dependencies: D,
  fn?: (...params: any[]) => any
): Signal<any> {
  const auto = fn.length < 2;
  const s = signal<any>();
  const single = !Array.isArray(dependencies);
  const deps = single
    ? ([dependencies] as Signal<SignalValues<D>>[])
    : (dependencies as Signal<SignalValues<D>>[]);

  // We only need to subscribe to the first item because
  // the act of retrieving all dependency values will add them
  // to the context stack appropriately
  const dep = deps[0];
  dep.subscribe(() => {
    const values = deps.map((dep) => dep.get());
    const ret = single ? values[0] : (values as SignalValues<D>);
    if (auto) {
      s.set(fn(ret));
    } else {
      fn(ret, s.set);
    }
  });
  return s;
}
