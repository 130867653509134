import { html } from 'lit';
import { property } from 'lit/decorators.js';
import { baseStyles, defineElement, MteContentBase } from '../../core';
import { styles } from './footer.styles';

/**
 *
 */
@defineElement('mte-footer')
export class MteFooter extends MteContentBase {
  static styles = [baseStyles, styles];

  /** Renders contents in a end aligned flex row with a gap */
  @property({ type: Boolean, reflect: true }) actions = false;

  /** Adds a bottom border to this header */
  @property({ type: Boolean, reflect: true }) withBorder = false;

  render() {
    return html`${this.instanceStyles}<slot></slot>`;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'mte-footer': MteFooter;
  }
}
