import type { LitElement, PropertyValueMap, ReactiveElement } from 'lit';
import { isSsr } from '../utilities';

type UpdateHandler = (changedProps: PropertyValueMap<any>) => void;

interface OnUpdateOptions {
  /**
   * If true, will only start watching after the initial update/render
   */
  waitUntilFirstUpdate?: boolean;

  /** Determines where this method will be called. Defaults to `client`. */
  on?: 'client' | 'ssr' | 'both';
}

export function onUpdate(propNames: string | string[], options?: OnUpdateOptions) {
  const resolvedOptions: Required<OnUpdateOptions> = {
    waitUntilFirstUpdate: false,
    on: 'client',
    ...options,
  };
  return <ElemClass extends LitElement>(proto: ElemClass, decoratedFnName: PropertyKey) => {
    // @ts-expect-error -- update is a protected property
    const { willUpdate } = proto;
    const _propNames = Array.isArray(propNames) ? propNames : [propNames];
    const propNameKeys = _propNames as (keyof ElemClass)[];
    // @ts-expect-error -- update is a protected property
    proto.willUpdate = function (this: ElemClass, changedProps: PropertyValueMap<any>) {
      if (propNameKeys.some((key) => changedProps.has(key))) {
        if (!resolvedOptions.waitUntilFirstUpdate || (this as unknown as LitElement).hasUpdated) {
          const ssr = isSsr();
          if (resolvedOptions.on === 'client' && !ssr) {
            (this[decoratedFnName] as unknown as UpdateHandler)(changedProps);
          } else if (resolvedOptions.on === 'ssr' && ssr) {
            (this[decoratedFnName] as unknown as UpdateHandler)(changedProps);
          } else if (resolvedOptions.on === 'both') {
            (this[decoratedFnName] as unknown as UpdateHandler)(changedProps);
          }
        }
      }
      willUpdate.call(this, changedProps);
    };
  };
}
