import { isSsr, isPlatform } from '../utilities';
import { MteScrollLockService } from './scroll-lock.service';

export type PortalStack = 'overlay' | 'affix';

export interface PortaledElementMetadata {
  element: Element;
  stack: PortalStack;
  childPortaledElements: Set<Element>;
  parentPortaledElement?: Element;
  backdrop?: Element;
}

export interface ElementPortalController {
  id: number;
  parentPortaledElement: Element;
  options: { stack: PortalStack };
}

export type PortalAdapter<T = any> = (
  portalElement: T,
  outletElement: Element
) => Promise<any> | any;

export interface PortalOptions {
  /** Renders the a backdrop */
  withBackdrop?: boolean;

  /** Renders the backdrop dimmed */
  withDimBackdrop?: boolean;

  /** Makes the backdrop ignore pointer events. Useful for triggerOn "hover". */
  withInertBackdrop?: boolean;

  /** Disables the ability to scroll the page behind the overlay when true */
  withScrollLock?: boolean;

  /** Callback for handling closeOnClickOutside events when the portal's backdrop is clicked */
  closeOnClickOutsideHandler?: () => any;

  /** The animation duration for fading a shadow backdrop in/out */
  backdropTransitionDuration?: string;

  /** Adapter for "portaling" an element from one location in the DOM to another. Leveraged by framework wrappers for improved interop. */
  portalAdapter?: PortalAdapter;
}

/** Default native DOM adapter for portaling an element to another target element */
export const defaultPortalAdapter: PortalAdapter = (portalElem, outletElem) => {
  outletElem?.appendChild(portalElem);
};

class _MtePortalService {
  /** Shared global const for stack element refs */
  private stackElements: { overlay: Element; affix: Element } = {
    overlay: undefined,
    affix: undefined,
  };

  /** Shared global const for portaled elements metadata */
  private portaledElements = new Map<number, Map<Element, PortaledElementMetadata>>();

  private activeScrollLocks = new Set<Element>();

  private stackMutationObserver?: MutationObserver;

  /**
   * When called, this service will attempt to locate the `@angular/cdk` overlay stack.
   * If found this service will be configured to use that element as the `overlay` stack instead of initializing its own.
   */
  public configureCdkInterop() {
    if (!isSsr()) {
      const cdkStack = document.querySelector('.cdk-overlay-container');
      if (cdkStack) {
        this.stackElements.overlay = cdkStack;
        this.stackElements.overlay.classList.add(this.idFactory('overlay'));
      }
    }
  }

  /**
   * Registers a portal controller. All items it requested to be portaled will be track against it.
   * When the portal is removed we can then remove any elements it currently has portaled and
   * any of their portaled children.
   */
  public addController(portalController: ElementPortalController) {
    if (!this.portaledElements.has(portalController?.id)) {
      // Track in set by ID so we don't keep a live reference to the controller itself
      this.portaledElements.set(portalController.id, new Map<Element, PortaledElementMetadata>());
    }
  }

  /** Removes all of a portal controllers portaled elements and their references */
  public removeController(portalController: ElementPortalController) {
    // Attempt to remove all items attached by this controller from the configured stack
    const attachedElements = this.portaledElements.get(portalController.id);
    if (attachedElements) {
      attachedElements.forEach((item) => this.removeFromStack(portalController, item.element));
    }
    this.portaledElements.delete(portalController.id);
  }

  /**
   * Ensures a ref to the configured stack exists. If it does not, the configured stack will
   * be created and appended to the document. Does nothing during SSR.
   */
  public initializeStack(stack: PortalStack) {
    if (stack !== 'overlay' && stack !== 'affix') {
      throw new Error(
        `Invalid Overlay Stack: "${stack}" must be 'overlay' or 'affix' at this time.`
      );
    }
    if (!isSsr()) {
      if (!this.stackElements[stack]) {
        const elem = document.getElementById(this.idFactory(stack));
        this.stackElements[stack] =
          elem ??
          Object.assign(document.createElement('div'), {
            className: this.idFactory(stack),
          });
      }
      if (this.stackElements[stack] && !this.stackElements[stack].isConnected) {
        this.appendStackToDom(this.stackElements[stack]);

        // Init mutation observer
        // This will watch for anytime the stack is removed from the DOM for some reason
        // If it is, it will re-attach it to the DOM after the next tick
        if (!this.stackMutationObserver) {
          this.stackMutationObserver = new MutationObserver((ml, observer) => {
            for (const mutation of ml) {
              for (const removedNode of mutation.removedNodes) {
                if (removedNode === this.stackElements.overlay) {
                  this.removeAllItemsFromStacks();
                  setTimeout(() => {
                    this.appendStackToDom(this.stackElements.overlay);
                  });
                }
              }
            }
          });
        }
        if (this.stackElements[stack]?.parentElement) {
          this.stackMutationObserver.observe(this.stackElements[stack].parentNode, {
            attributes: false,
            childList: true,
            subtree: false,
          });
        }
      }
    }
  }

  private appendStackToDom(stackElement: Element) {
    if (stackElement && !stackElement.isConnected) {
      const toastContainer = document.body.querySelector('mte-toast-container');
      if (toastContainer) {
        document.body.insertBefore(stackElement, toastContainer);
      } else {
        document.body.append(stackElement);
      }
    }
  }

  /** Appends an item to the configured portal stack */
  public appendToStack(
    portalController: ElementPortalController,
    element: Element,
    {
      withBackdrop = true,
      withDimBackdrop = false,
      withInertBackdrop = false,
      withScrollLock = false,
      backdropTransitionDuration,
      closeOnClickOutsideHandler,
      portalAdapter = defaultPortalAdapter,
    }: PortalOptions = {}
  ) {
    this.addController(portalController);
    return new Promise<void>((resolve) => {
      const parentPortaledElement = this.getParentPortaledElement(portalController, element);
      const stackName = portalController.options.stack;
      const stack = this.stackElements[stackName];
      const attachedElements = this.portaledElements.get(portalController.id);
      let backdrop: HTMLDivElement | undefined;
      if (withBackdrop) {
        backdrop = Object.assign(document.createElement('div'), {
          className: 'mte-portal__backdrop',
          ...(backdropTransitionDuration
            ? { style: `transition-duration: ${backdropTransitionDuration};` }
            : {}),
        });

        // Handle backdrop styling
        if (withInertBackdrop) {
          backdrop.classList.add('backdrop--inert');
        }
        if (withDimBackdrop) {
          backdrop.classList.add('backdrop--shadow');
        } else {
          backdrop.classList.add('backdrop--transparent');
        }

        // Append backdrop
        stack?.appendChild(backdrop);
      }

      if (!isSsr()) {
        // Add listener if click handler is present
        if (closeOnClickOutsideHandler) {
          // Intercepts the click/touch events here to prevent issues with clicks passing through the backdrop on mobile Safari
          const handleClose = (e: Event) => {
            e.stopPropagation();
            e.preventDefault();
            closeOnClickOutsideHandler();
          };
          backdrop.addEventListener('mousedown', handleClose);
          backdrop.addEventListener('touchstart', handleClose);
        }
        // If backdrop should be dim, animate it after it has been appended to the dom
        if (backdrop && withDimBackdrop) {
          globalThis.requestAnimationFrame(() => {
            backdrop.classList.add('backdrop--show');
          });
        }
        // Apply scroll lock
        if (withScrollLock) {
          this.applyScrollLock(element);
        }
      }

      // Wait for React (or other future framework adapter) to append element to new
      // location in the DOM before adding to stack. Doing so triggers `hostDisconnected`
      // which would instantly remove the element if not for this extra timeout to wait a tick
      Promise.resolve(portalAdapter(element, stack)).then(() => {
        attachedElements.set(element, {
          element,
          stack: stackName,
          backdrop: withBackdrop ? backdrop : null,
          childPortaledElements: new Set<Element>(),
          parentPortaledElement,
        });
      });
      resolve();
    });
  }

  /** Removes an element from the stack */
  public removeFromStack(portalController: ElementPortalController, element: Element) {
    if (this.portaledElements.has(portalController.id)) {
      const attachedElements = this.portaledElements.get(portalController.id);
      if (attachedElements) {
        const elementMetadata = attachedElements.get(element);
        if (elementMetadata) {
          attachedElements.delete(element);
          this.finalizeStackRemove(elementMetadata);
        }
      }
    }
  }

  // Safely removes all items from all stacks
  private removeAllItemsFromStacks() {
    for (let [id, controllerMap] of this.portaledElements.entries()) {
      for (let [element, elementMetadata] of controllerMap.entries()) {
        if (elementMetadata) {
          this.finalizeStackRemove(elementMetadata);
        }
      }
    }
  }

  /** Makes a backdrop associated with a portaled element visible if it exists */
  public showBackdrop(portalController: ElementPortalController, element: Element) {
    this.addController(portalController);
    const { backdrop } = this.portaledElements.get(portalController.id).get(element) ?? {};
    backdrop?.classList.add('backdrop--show');
  }

  /** Hides a backdrop associated with a portaled element if it exists */
  public hideBackdrop(portalController: ElementPortalController, element: Element) {
    this.addController(portalController);
    const { backdrop } = this.portaledElements.get(portalController.id).get(element) ?? {};
    backdrop?.classList.remove('backdrop--show');
  }

  /** Updates the intertness a backdrop if it exists (whether or not the user can click through it) */
  public setBackdropInertness(
    portalController: ElementPortalController,
    element: Element,
    inert: boolean = true
  ) {
    this.addController(portalController);
    const { backdrop } = this.portaledElements.get(portalController.id).get(element) ?? {};
    if (inert) {
      backdrop?.classList.add('backdrop--inert');
    } else {
      backdrop?.classList.remove('backdrop--inert');
    }
  }

  /** Returns the id for the configured stack element */
  private idFactory = (stack: PortalStack) => `mte-portal__${stack}-stack`;

  /**
   * Checks if an element is being portaled from within another portaled
   * element and returns that parent if it is
   */
  private getParentPortaledElement(portalController: ElementPortalController, element: Element) {
    if (portalController.parentPortaledElement) {
      return portalController.parentPortaledElement;
    }
    let parentPortaledElement: Element;
    this.portaledElements.forEach((portalController) =>
      portalController.forEach((portaledElement) => {
        if (portaledElement.element.contains(element)) {
          portaledElement.childPortaledElements.add(element);
          parentPortaledElement = portaledElement.element;
        }
      })
    );
    // Cache the parentPortaledElement if found on the controller so we can reference
    // it when opened after the element has been removed from the DOM
    if (parentPortaledElement) {
      portalController.parentPortaledElement = parentPortaledElement;
    }
    return parentPortaledElement;
  }

  /** Ensures an element is removed from the stack no matter which controller has portaled it */
  private removeFromStackGlobally(element: Element) {
    this.portaledElements.forEach((portalController) => {
      if (portalController.has(element)) {
        const elementMetadata = portalController.get(element);
        portalController.delete(element);
        this.finalizeStackRemove(elementMetadata);
      }
    });
  }

  /** Finalizes the remove of an element from a portal stack */
  private finalizeStackRemove(elementMetadata: PortaledElementMetadata) {
    if (elementMetadata) {
      const {
        element,
        stack: stackName,
        backdrop,
        childPortaledElements,
        parentPortaledElement,
      } = elementMetadata;
      const stack = this.stackElements[stackName];

      // Remove any scrollLocks
      this.removeScrollLock(element);

      // Ensures an element has been removed from any parent portaled elements children lists
      if (parentPortaledElement) {
        this.portaledElements.forEach((portalController) => {
          if (portalController.has(parentPortaledElement)) {
            portalController.get(parentPortaledElement).childPortaledElements.delete(element);
          }
        });
      }

      // Ensures any children of this element are removed before this element is
      if (childPortaledElements.size > 0) {
        childPortaledElements.forEach((element) => this.removeFromStackGlobally(element));
      }
      // Removes this elements backdrop from the portal stack
      if (backdrop && backdrop.parentElement === stack) {
        stack?.removeChild(backdrop);
      }
      // Check if this is a react wrapped portaled element
      if (element.parentElement?.hasAttribute('data-overlay-wrapper')) {
        if (element.parentElement.parentElement === stack) {
          stack?.removeChild(element.parentElement);
        }
      } else if (element.parentElement === stack) {
        stack?.removeChild(element);
      }
    }
  }

  /**
   * Applies a scroll lock to the body element to prevent scrolling of page content.
   * Calculates any body offsets due to visible scrollbars and dynamically adjusts for them.
   */
  private applyScrollLock(element: Element) {
    this.activeScrollLocks.add(element);

    MteScrollLockService.apply();
  }

  /** Removes an active scrollLock if there are no remaining elements portaled that requested it */
  private removeScrollLock(element: Element) {
    this.activeScrollLocks.delete(element);

    if (this.activeScrollLocks.size === 0) {
      MteScrollLockService.remove();
    }
  }
}

export const MtePortalService = new _MtePortalService();
