import { html } from 'lit';
import { property } from 'lit/decorators.js';
import {
  MteContentBase,
  PresetMixinFactory,
  InverseMixin,
  RadiusMixin,
  baseStyles,
  defineElement,
  presetProperty,
  inverseStyles,
} from '../../core';
import { styles } from './card.styles';
import { CardPreset } from './card.presets';
import '../composition';

/**
 * @dependency mte-composition
 *
 * @slot - Default slotted content
 */
@defineElement('mte-card')
export class MteCard extends InverseMixin(
  RadiusMixin(PresetMixinFactory<CardPreset>('MteCard')(MteContentBase))
) {
  static styles = [baseStyles, inverseStyles, styles];

  /** Renders a drop shadow for presets with shadows disabled by default. Cards within cards cannot display shadows even if this attribute is present. */
  @presetProperty({ type: Boolean, reflect: true }) withShadow = false;

  /** Prevents drop shadow rendering for presets with shadows enabled by default. Cards within cards cannot display shadows even if this attribute is present. */
  @presetProperty({ type: Boolean, reflect: true }) withoutShadow = false;

  /** Whether or not this card should hide overflow content */
  @property({ type: Boolean, reflect: true }) hideOverflow = false;

  /** Whether or not this card should be marked as selected */
  @property({ type: Boolean, reflect: true }) selected = false;

  /** Adds hover/active styling. Prefer using an mte-card-button when user interaction is need. This can be useful internal to other controls */
  @property({ type: Boolean, reflect: true }) selectable = false;

  /** Removes the card border */
  @property({ type: Boolean, reflect: true }) withoutBorder = false;

  render() {
    return html`${this.instanceStyles}<slot></slot>`;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'mte-card': MteCard;
  }
}
