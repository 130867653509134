import { Presets } from './../../core';

export interface DrawerPreset {}

export const drawerPresets: Presets<DrawerPreset> = {
  default: {},
  cx: {},
  rx: {},
  hw: {},
};
