import { Presets, RadiusOptions } from '../../core';

export interface ButtonGroupPreset {
  radius?: RadiusOptions;
}

export const buttonGroupPresets: Presets<ButtonGroupPreset> = {
  default: {},
  cx: {},
  rx: {},
  hw: {},
};
