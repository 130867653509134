import { html } from 'lit';
import { property } from 'lit/decorators.js';
import {
  defineElement,
  baseStyles,
  ResponsiveValue,
  MteContentBase,
  getResponsivePropVal,
  ResponsiveValueConverter,
} from '../../core';
import { styles } from './grid-item.styles';
import { selectorFactory } from '@mortar/styles';

/**
 * @slot - Default slotted content
 */
@defineElement('mte-grid-item')
export class MteGridItem extends MteContentBase {
  static styles = [baseStyles, styles];

  /** The amount of columns this item should span. */
  @property({ reflect: true, converter: ResponsiveValueConverter }) span?: ResponsiveValue<
    string | number | 'all'
  >;

  /** The amount of columns this item should span above the `sm` breakpoint. */
  @property({ reflect: true }) spanSm?: string | number;

  /** The amount of columns this item should span above the `md` breakpoint. */
  @property({ reflect: true }) spanMd?: string | number;

  /** The amount of columns this item should span above the `lg` breakpoint. */
  @property({ reflect: true }) spanLg?: string | number;

  /** The amount of columns this item should span above the `xl` breakpoint. */
  @property({ reflect: true }) spanXl?: string | number;

  /** The amount of columns this item should span above the `xxl` breakpoint. */
  @property({ reflect: true }) spanXxl?: string | number;

  /** The column where this item should start horizontally spanning. */
  @property({ reflect: true, converter: ResponsiveValueConverter }) start?: ResponsiveValue<
    string | number
  >;

  /** The column where this item should start horizontally spanning above the `sm` breakpoint. */
  @property({ reflect: true }) startSm?: string | number;

  /** The column where this item should start horizontally spanning above the `md` breakpoint. */
  @property({ reflect: true }) startMd?: string | number;

  /** The column where this item should start horizontally spanning above the `lg` breakpoint. */
  @property({ reflect: true }) startLg?: string | number;

  /** The column where this item should start horizontally spanning above the `xl` breakpoint. */
  @property({ reflect: true }) startXl?: string | number;

  /** The column where this item should start horizontally spanning above the `xxl` breakpoint. */
  @property({ reflect: true }) startXxl?: string | number;

  /** The amount of rows this item should span. */
  @property({ reflect: true, converter: ResponsiveValueConverter }) rowSpan?: ResponsiveValue<
    string | number
  >;

  /** The amount of rows this item should span above the `sm` breakpoint. */
  @property({ reflect: true }) rowSpanSm?: string | number;

  /** The amount of rows this item should span above the `md` breakpoint. */
  @property({ reflect: true }) rowSpanMd?: string | number;

  /** The amount of rows this item should span above the `lg` breakpoint. */
  @property({ reflect: true }) rowSpanLg?: string | number;

  /** The amount of rows this item should span above the `xl` breakpoint. */
  @property({ reflect: true }) rowSpanXl?: string | number;

  /** The amount of rows this item should span above the `xxl` breakpoint. */
  @property({ reflect: true }) rowSpanXxl?: string | number;

  /** The row where this item should start vertically spanning. */
  @property({ reflect: true, converter: ResponsiveValueConverter }) rowStart?: ResponsiveValue<
    string | number
  >;

  /** The row where this item should start vertically spanning above the `sm` breakpoint. */
  @property({ reflect: true }) rowStartSm?: string | number;

  /** The row where this item should start vertically spanning above the `md` breakpoint. */
  @property({ reflect: true }) rowStartMd?: string | number;

  /** The row where this item should start vertically spanning above the `lg` breakpoint. */
  @property({ reflect: true }) rowStartLg?: string | number;

  /** The row where this item should start vertically spanning above the `xl` breakpoint. */
  @property({ reflect: true }) rowStartXl?: string | number;

  /** The row where this item should start vertically spanning above the `xxl` breakpoint. */
  @property({ reflect: true }) rowStartXxl?: string | number;

  private getGridArea(bp: string) {
    const colSpan = getResponsivePropVal(this, 'span', bp) ?? null;
    const colStart = getResponsivePropVal(this, 'start', bp) ?? null;
    const rowSpan = getResponsivePropVal(this, 'rowSpan', bp) ?? null;
    const rowStart = getResponsivePropVal(this, 'rowStart', bp) ?? null;

    // Calculate start/ends
    let gridColumnStart = colStart;
    let gridColumnEnd = colSpan ? `span ${colSpan}` : null;
    const gridRowStart = rowStart;
    const gridRowEnd = rowSpan ? `span ${rowSpan}` : null;

    // Calculate custom values
    if (colSpan === 'all') {
      gridColumnStart = gridColumnStart ?? '1';
      gridColumnEnd = '-1';
    }

    // If no props are set return nothing
    if (!gridColumnStart && !gridColumnEnd && !gridRowStart && !gridRowEnd) {
      return null;
    } else {
      // Properties are set individually and not with `grid-area` so they extend to all
      // responsive breakpoints instead of requiring a value set for each one.
      return {
        gridColumnStart,
        gridColumnEnd,
        gridRowStart,
        gridRowEnd,
      };
    }
  }

  private static _styleProps = new Set([
    'span',
    'spanSm',
    'spanMd',
    'spanLg',
    'spanXl',
    'spanXxl',
    'start',
    'startSm',
    'startMd',
    'startLg',
    'startXl',
    'startXxl',
    'rowStart',
    'rowStartSm',
    'rowStartMd',
    'rowStartLg',
    'rowStartXl',
    'rowStartXxl',
    'rowSpan',
    'rowSpanSm',
    'rowSpanMd',
    'rowSpanLg',
    'rowSpanXl',
    'rowSpanXxl',
  ]);

  protected updateInstanceStyles(changedProps) {
    super.updateInstanceStyles(changedProps);

    // Check if any style prop has changed to determine if we need to update the instance styles
    let shouldUpdateStyles = false;
    for (const key of changedProps.keys()) {
      if (MteGridItem._styleProps.has(key)) {
        shouldUpdateStyles = true;
        break;
      }
    }

    if (shouldUpdateStyles) {
      this.setInstanceStyle('mte-grid-item', selectorFactory(this.instanceStyleSelectorRoot), {
        ...this.getGridArea(''),
        xs: this.getGridArea('xs'),
        sm: this.getGridArea('sm'),
        md: this.getGridArea('md'),
        lg: this.getGridArea('lg'),
        xl: this.getGridArea('xl'),
        xxl: this.getGridArea('xxl'),
      });
    }
  }

  render() {
    return html`${this.instanceStyles}<slot></slot>`;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'mte-grid-item': MteGridItem;
  }
}
