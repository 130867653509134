import { Presets } from '../../core';

export interface SwitchPreset {}

export const switchPresets: Presets<SwitchPreset> = {
  default: {},
  cx: {},
  rx: {},
  hw: {},
};
