import { Presets } from './../../core';

export interface BadgePreset {}

export const badgePresets: Presets<BadgePreset> = {
  default: {},
  cx: {},
  rx: {},
  hw: {},
};
