import { LitElement, PropertyValues } from 'lit';
import { property } from 'lit/decorators.js';
import { Constructor } from '../';

export declare class DisabledInterface {
  /**
   * Disables this component
   * @attr disabled
   */
  disabled: boolean;
}

export const DisabledMixin = <T extends Constructor<LitElement>>(superClass: T) => {
  class DisabledElement extends superClass {
    /**
     * Disables this component
     * @attr disabled
     */
    @property({ type: Boolean, reflect: true }) disabled = false;

    // Because `ariaDisabled` exists as a string on LitElement we cannot declare this a property here
    protected willUpdate(_changedProperties: PropertyValues<any>): void {
      super.willUpdate(_changedProperties);

      if (_changedProperties.has('disabled')) {
        if (this.disabled) {
          this.setAttribute('aria-disabled', `${this.disabled}`);
        } else {
          this.removeAttribute('aria-disabled');
        }
      }
    }
  }
  return DisabledElement as Constructor<DisabledInterface> & T;
};
