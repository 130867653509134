import { defaultConverter } from 'lit';

export const ResponsiveValueConverter = {
  fromAttribute: (value, type) => {
    try {
      if (value?.[0] === '{') {
        return defaultConverter.fromAttribute(value, Object);
      }
    } catch (e) {
      // eslint-disable-next-line no-empty
    }
    return defaultConverter.fromAttribute(value, type);
  },
  toAttribute: (value, type) => {
    if (typeof value === 'object') {
      return defaultConverter.toAttribute(value, Object);
    }
    return defaultConverter.toAttribute(value, type);
  },
};
