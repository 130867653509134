/* eslint-disable @typescript-eslint/ban-types */
import { ContextConsumer } from '@lit-labs/context';
import { PropertyDeclaration, ReactiveElement } from 'lit';
import { property, state } from 'lit/decorators.js';
import {
  Constructor,
  isSsr,
  MteElement,
  MtePresetService,
  presetContext,
  signal,
  computed,
  Unsubscriber,
} from '../';
import { MortarPreset } from './presets';

export type PresetOptions = 'md' | 'sm' | 'lg' | string | undefined;

export declare class PresetElementInterface<P> {
  /** @ignore */
  readonly __componentName: keyof MortarPreset;

  /** Selects the preset for this component. Overrides the global preset for this instance of this component. */
  preset: string;

  /** @ignore */
  appliedPreset: string;
}

export interface PresetMixinFactoryOptions {
  reflectAppliedPreset: boolean;
}

const {
  is,
  defineProperty,
  getOwnPropertyDescriptor,
  getOwnPropertyNames,
  getOwnPropertySymbols,
  getPrototypeOf,
} = Object;

export const PresetMixinFactory = <P>(
  componentName: keyof MortarPreset,
  options: PresetMixinFactoryOptions = { reflectAppliedPreset: false }
) => {
  return <T extends Constructor<MteElement>>(superClass: T) => {
    class PresetElement extends superClass {
      readonly __componentName = componentName;

      /** Map that stores private values that take precedence over preset values */
      protected _valueMap = new Map<PropertyKey, P[keyof P]>();

      /** Map that stores fallback preset values emitted by the presetController */
      protected _presetMap = new Map<PropertyKey, P[keyof P]>();

      /** Selects a specific preset for this component instance. Overrides the current global preset. */
      @property({ reflect: true })
      set preset(preset: string) {
        this._preset = preset;
        this._activePresetSignal.set(preset);
        if (isSsr()) {
          // Stop SSR constructor listener if still open.
          this.ssrConstructorSub?.();
          this.ssrConstructorSub = null;
          const preset = MtePresetService.getPreset(this.preset ?? 'global');
          this.handleSsrPresetChanges(preset);
        }
      }
      get preset() {
        return this._preset;
      }
      private _preset = 'global';

      @property({ reflect: options?.reflectAppliedPreset ?? false }) appliedPreset = 'global';

      @state() private _appliedPreset?: string;

      /** Keeps constructor preset subscription open until willUpdate */
      private ssrConstructorSub: Unsubscriber;
      private previousComponentUnsub: Unsubscriber;

      /** Emits whenever the local preset value changes. */
      private _activePresetSignal = signal<string>(null);
      private _parentPresetSignal = signal<string>(null);

      private _presetContext = new ContextConsumer(this, {
        context: presetContext,
        subscribe: true,
        callback: (value) => {
          this._parentPresetSignal.set((value === '' ? null : value) ?? 'global');
        },
      });

      /**
       * Overrides the default getPropertyDescriptor method to check for the custom
       * `isPreset` option. Descriptors of properties that are declared with the `isPreset`
       * option are defined with special getter/setter logic that sets value to valueMap
       * and gets values from the valueMap but then also falls back to any value in the presetMap
       *
       * The presetMap is populated above from values from the presetController whenever the
       * preset for this component is changed globally.
       */
      static getPropertyDescriptor(
        name: PropertyKey,
        key: string | symbol,
        options: PropertyDeclaration
      ) {
        const { get, set } = getOwnPropertyDescriptor(this.prototype, name) ?? {
          get(this: ReactiveElement) {
            return this[key as keyof typeof this];
          },
          set(this: ReactiveElement, v: unknown) {
            (this as unknown as Record<string | symbol, unknown>)[key] = v;
          },
        };
        return {
          get(this: ReactiveElement): any {
            // Special getter logic that gets from the valueMap or presetMap
            if (options['isPreset']) {
              return (
                (
                  (this as {} as { [key: string]: unknown })._valueMap as Map<
                    PropertyKey,
                    P[keyof P]
                  >
                ).get(name) ||
                (
                  (this as {} as { [key: string]: unknown })._presetMap as Map<
                    PropertyKey,
                    P[keyof P]
                  >
                ).get(name)
              );
            }
            // Default property getter logic
            else {
              return get?.call(this);
            }
          },
          set(this: ReactiveElement, value: unknown) {
            // Special setter logic that sets to the valueMap
            if (options['isPreset']) {
              const oldValue = (
                (this as {} as { [key: string]: unknown })._valueMap as Map<PropertyKey, P[keyof P]>
              ).get(name);
              (
                (this as {} as { [key: string]: unknown })._valueMap as Map<PropertyKey, P[keyof P]>
              ).set(name, value as any);
              // During SSR we need to update immediately
              if (isSsr()) {
                (this as unknown as ReactiveElement).requestUpdate(name, oldValue, options);
              }
              // On the client we need to wait for the current update to finish
              else {
                this.updateComplete.then(() => {
                  (this as unknown as ReactiveElement).requestUpdate(name, oldValue, options);
                });
              }
            }
            // Default property setter logic
            else {
              const oldValue = get?.call(this);
              set!.call(this, value);
              (this as unknown as ReactiveElement).requestUpdate(name, oldValue, options);
            }
          },
          configurable: true,
          enumerable: true,
        };
      }

      connectedCallback() {
        super.connectedCallback();

        // Subscribes for changes to this components active preset to update all relevant props preset values
        this.subs.push(
          computed(
            [this._activePresetSignal, this._parentPresetSignal],
            ([activePreset, parentPreset], set) => {
              if (activePreset) {
                this.__localPresetTheme =
                  activePreset === 'global'
                    ? null
                    : MtePresetService.getComponentPreset(activePreset, 'theme');
              } else {
                this.__localPresetTheme = null;
              }
              this.requestUpdate('theme', null);

              this._appliedPreset = activePreset ?? parentPreset ?? 'global';
              this.appliedPreset = MtePresetService.getComponentPreset(
                this._appliedPreset,
                'preset'
              );

              // Unsubscribe to previous component preset changes
              this.previousComponentUnsub?.();
              // Update props if new component preset emits
              this.previousComponentUnsub = MtePresetService.selectComponentPreset(
                this._appliedPreset,
                this.__componentName
              ).subscribe((newPreset) => {
                if (newPreset) {
                  set(newPreset);
                }
              });
            }
          ).subscribe((newPresets) => {
            const oldValuesMap = new Map(this._presetMap);
            const oldProps = Array.from(this._presetMap.keys());
            const newProps = Object.keys(newPresets ?? {});

            // If there are no keys in the new presets clear all preset values
            if (newProps.length === 0) {
              this._presetMap.clear();
            }
            // Loop through old set props and new ones
            // Remove old ones & set new ones
            // Finally force an update for each prop
            [...oldProps, ...newProps].forEach((key) => {
              if (newPresets[key]) {
                this._presetMap.set(key, newPresets[key]);
              } else {
                this._presetMap.delete(key);
              }
              this.requestUpdate(key, oldValuesMap.get(key) ?? null);
            });
          })
        );
      }

      disconnectedCallback() {
        super.disconnectedCallback();

        this.previousComponentUnsub?.();
        this.ssrConstructorSub?.();
      }

      constructor(...args: any[]) {
        super(args);

        // TODO(reece): Ensure we update this to support context when that is SSR Friendly: https://github.com/lit/lit/issues/3365
        // Handle setting preset defaults in SSR
        if (isSsr()) {
          this.ssrConstructorSub = MtePresetService.selectPreset(this.preset ?? 'global').subscribe(
            (preset) => this.handleSsrPresetChanges(preset)
          );
        }
      }

      private handleSsrPresetChanges(preset: MortarPreset) {
        if (preset) {
          const componentPresets = preset[this.__componentName];
          const oldProps = Array.from(this._presetMap.keys());
          const newProps = Object.keys(componentPresets ?? {});

          // If there are no keys in the new presets clear all preset values
          if (newProps.length === 0) {
            this._presetMap.clear();
          }
          // Loop through old set props and new ones
          // Remove old ones & set new ones
          // Finally force an update for each prop
          [...oldProps, ...newProps].forEach((key) => {
            if (componentPresets[key]) {
              this._presetMap.set(key, componentPresets[key]);
            } else {
              this._presetMap.delete(key);
            }
            this.requestUpdate(key, null);
          });
          this.appliedPreset = preset['preset'];
          this.requestUpdate('appliedPreset', null);
        }
      }
    }
    return PresetElement as Constructor<PresetElementInterface<P>> & T;
  };
};
