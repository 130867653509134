import { Presets } from '../../core';

export interface RadioPreset {}

export const radioPresets: Presets<RadioPreset> = {
  default: {},
  cx: {
    color: 'secondary',
  },
  rx: {},
  hw: {},
};
