import { html } from 'lit';
import { property, state } from 'lit/decorators.js';
import { styles } from './icon.styles';
import {
  defineElement,
  StatusColorMixin,
  MteElement,
  PresetMixinFactory,
  onUpdate,
  Unsubscriber,
  isSsr,
} from '../../core';
import { mtrIcon } from '@mortar/icons';
import { MteIconRegistryService } from './icon-registry.service';
import { IconPreset } from './icon.presets';
import { unsafeSVG } from 'lit/directives/unsafe-svg.js';
import { selectorFactory } from '@mortar/styles';

/**
 */
@defineElement('mte-icon')
export class MteIcon extends StatusColorMixin(
  PresetMixinFactory<IconPreset>('MteIcon')(MteElement)
) {
  static styles = [styles];

  /** The name of the icon to render */
  @property({ reflect: true }) name: mtrIcon | string;

  /** The library to load the icon from. The default library is `_default`. */
  @property({ reflect: true }) library = '_default';

  /** The size of this icon. Matches a given font-size in px. */
  @property({ type: Number, reflect: true }) size: string | number = 24;

  /** Scale this icon relative to the computed font-size of this element. */
  @property({ type: Boolean, reflect: true }) autosize = false;

  /** An icon's role is defaults to `img` to ensure they do not interrupt text.  */
  @property({ reflect: true }) role = 'img';

  /** Label for icon to be read to screenreader technologies */
  @property({ attribute: 'aria-label', reflect: true }) ariaLabel: string;

  /** Label for icon to be read to screenreader technologies */
  @property({ attribute: 'aria-hidden', reflect: true }) ariaHidden = 'true';

  /** Renders the icon in the default muted ink color. */
  @property({ type: Boolean, reflect: true }) muted = false;

  /** Renders this icon with colors defined in the SVG instead of inheriting from CSS parents */
  @property({ type: Boolean, reflect: true }) withColor = false;

  /**
   * @hidden
   * Tracks the loaded icon so we don't need to refetch it during hydration
   */
  @property({ reflect: true }) _loaded?: string;

  @state() private iconSvg: string;

  private fetchAbortController?: AbortController;

  private previousIconSubscription: Unsubscriber;

  @onUpdate(['name', 'library'], { on: 'both' })
  private handleNameUpdate() {
    if (this.name) {
      if (this.needsIconUpdate()) {
        this.fetchAbortController?.abort();
        this.fetchAbortController = new AbortController();
        this.previousIconSubscription?.();
        this.previousIconSubscription = MteIconRegistryService.getIcon(
          this.name,
          this.library,
          this.fetchAbortController.signal
        ).subscribe((icon, unsub) => {
          if (icon) {
            const oldSvg = this.iconSvg;
            const oldLoaded = this._loaded;

            if (isSsr()) {
              this.iconSvg = icon;
              this._loaded = `${this.library}~${this.name}`;
              this.requestUpdate('iconSvg', oldSvg);
              this.requestUpdate('_loaded', oldLoaded);
            } else {
              this.updateComplete.then(() => {
                this.iconSvg = icon;
                this._loaded = `${this.library}~${this.name}`;
                this.requestUpdate('iconSvg', oldSvg);
                this.requestUpdate('_loaded', oldLoaded);
              });
            }

            // If the result was not null or the fallback icon then we leave this subscription
            // open in case it loads later or lazy loading is re-enabled.
            if (this.iconSvg !== null && this.iconSvg !== MteIconRegistryService['fallbackIcon']) {
              unsub();
              this.previousIconSubscription?.();
              this.previousIconSubscription = null;
            }
          }
          // During SSR always unsub
          if (isSsr()) {
            unsub();
          }
        });
      } else if (!this.iconSvg) {
        // If the icon doesn't need an update but we don't know what the iconSvg is (hydrating from SSR), get it from the DOM
        const svg = this.renderRoot?.querySelector('svg')?.outerHTML ?? null;
        // Wait for update to complete to prevent hydration mismatch
        this.updateComplete.then(() => {
          this.iconSvg = svg;
          this.requestUpdate('iconSvg', null);
        });
      }
    } else {
      this._loaded = null;
      this.iconSvg = undefined;
    }
  }

  @onUpdate(['ariaLabel'], { on: 'both' })
  private handleAriaLabelChange() {
    if (!this.ariaLabel) {
      this.ariaHidden = 'true';
    }

    if (this.ariaLabel && this.ariaHidden === 'true') {
      this.ariaHidden = null;
    }
  }

  protected updateInstanceStyles(changedProps) {
    super.updateInstanceStyles(changedProps);

    if (changedProps.has('size') || changedProps.has('iconSvg') || changedProps.has('autosize')) {
      if (this.autosize || this.size) {
        const size = this.autosize ? 'calc(1em + 2px)' : `${this.size}px`;
        // Specificity is one less that se/sp props so those still override default size calculations
        this.setInstanceStyle('mte-icon-sizing', selectorFactory(':host:host'), {
          width: size,
          minWidth: size,
          height: size,
          minHeight: size,
        });
      }
    }
  }

  disconnectedCallback(): void {
    super.disconnectedCallback();

    this.previousIconSubscription?.();
  }

  private needsIconUpdate() {
    return !this._loaded || this._loaded !== `${this.library}~${this.name}`;
  }

  render() {
    return html`${this.instanceStyles}${unsafeSVG(this.iconSvg)}`;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'mte-icon': MteIcon;
  }
}
