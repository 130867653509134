import { Presets } from './../../core';

export interface InputPreset {}

export const inputPresets: Presets<InputPreset> = {
  default: {},
  cx: {},
  rx: {},
  hw: {},
};
