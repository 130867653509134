/* eslint-disable @typescript-eslint/no-inferrable-types */
import { Signal, signal, isSsr, computed } from '../../core';
import { MortarIcon, mtrIconHelpRhombus } from '@mortar/icons';

export type IconData = { data?: string; fetched?: boolean };

/**
 * Service used to register svg strings for a given icon name and then look them
 * up by that same name or fetch them from a CDN. The `MteIconRegistryService` is
 * a singleton instance of this class. You should always use that export instead
 * of initializing a new instance.
 */
export class _MteIconRegistryService {
  /** Map where loaded icon svgs are cached */
  private iconRegistry = new Map<string, Signal<IconData>>();

  /** Map of registered lazy loading resolver functions per icon library */
  private iconUrlResolvers = new Map<string, (iconName: string) => string>();

  /** Defines the svg used when an icon cannot be loaded */
  private fallbackIcon = mtrIconHelpRhombus?.data;

  /** Tracks if icons should be lazy loaded internally */
  private lazyLoadEnabled = true;

  /** Prevents logs and fetches when true */
  private testModeEnabled = false;

  /** Delimiter used when creating library + icon name keys */
  private delimiter = '~';

  /** If executing server-side all mortar icons will be loaded here */
  private serverIconCache?: Record<string, MortarIcon>;

  constructor() {
    this.iconUrlResolvers.set('_default', (iconName: string) => {
      return `https://mortar-cdn.heb.com/icons/mdi/7.2/${iconName}.svg`;
    });
    this.iconUrlResolvers.set('cx', (iconName: string) => {
      return `https://mortar-cdn.heb.com/icons/cx/1.1/${iconName}.svg`;
    });
    this.ssrInit().then(() => {});
  }

  /** @hidden */
  async ssrInit() {
    /**
     * This monstrosity enables local icon rendering during SSR _WITHOUT_ also resulting in
     * bundlers pulling in the entire icon library elsewhere. This can also be achieved with just
     * the magic comments below...but not all bundlers support that kind of feature so this is an
     * extra precaution.
     *
     * For this to work:
     * - The environment variable `MORTAR_DYNAMIC_SSR_ICON_IMPORTS` must be true
     * - The `@mortar/icons` package must be available
     */
    try {
      if (isSsr()) {
        const iconImport =
          (process as any)?.env.MORTAR_DYNAMIC_SSR_ICON_IMPORTS === 'true' ? '@mortar/icons' : null;
        if (iconImport) {
          // Attempt to load all icons locally if run server side. The ignore
          // comments here prevent webpack & vite from throwing a warning
          // since their inability to optimize this is intentional here.
          const icons = await import(/* webpackIgnore: true */ /* @vite-ignore */ iconImport);
          this.serverIconCache = icons?.default ?? icons;
        }
      }
    } catch (e) {
      /* empty */
    }
  }

  /**
   * Configures the root url from which this service will attempt to fetch icons from for the given "library".
   */
  public registerIconLibrary(
    iconLibrary = '_default',
    iconUrlResolver?: (iconName: string) => string
  ) {
    this.lazyLoadEnabled = true;
    if (iconUrlResolver) {
      this.iconUrlResolvers.set(iconLibrary ?? '_default', iconUrlResolver);
    }

    // Fetch any icons requested before this was called that have not yet been fetched
    for (const [name, iconSignal] of this.iconRegistry) {
      if (!iconSignal || !iconSignal.get()?.fetched) {
        const [iconLibrary, pathName] = name.split(this.delimiter);
        this.fetchIcon(pathName, iconLibrary).then((icon) => {
          iconSignal.set({ fetched: true, data: icon });
        });
      }
    }
  }

  /** Manually registers new icons */
  public registerIcons(icons: MortarIcon[], iconLibrary = '_default') {
    icons.forEach((icon) => {
      const iconSignal = this.iconRegistry.get(`${iconLibrary}${this.delimiter}${icon.name}`);
      if (!iconSignal) {
        this.iconRegistry.set(
          `${iconLibrary}${this.delimiter}${icon.name}`,
          signal({ fetched: true, data: icon.data })
        );
      } else if (iconSignal && !iconSignal.get()?.fetched) {
        iconSignal.set({ fetched: true, data: icon.data });
      }
    });
  }

  /**
   * Allows you to override the lazy loading. Defaults to `true`.
   * When `false` icons will not be fetched from remote locations.
   */
  public setLazyLoading(value: boolean = true) {
    this.lazyLoadEnabled = value;
  }

  /**
   * Allows you to override the test mode. Defaults to `false`.
   * When true warnings will not be thrown and no fetches will be made.
   */
  public setTestMode(value: boolean = false) {
    this.testModeEnabled = value;
  }

  /** Sets a new custom fallback icon */
  public setFallbackIcon(fallbackIcon: string | null) {
    this.fallbackIcon = fallbackIcon;
  }

  /**
   * Attempts to retrieve an icon from the registry.
   *
   * If a rootSvgIconUrl has been set, this method will
   * attempt to load the icon from there. If successful,
   * that svg will be registered for reuse.
   */
  public getIcon(
    pathName: string,
    iconLibrary = '_default',
    abortSignal?: AbortSignal
  ): Signal<string | null> {
    // If icon is undefined instantly return the fallback icon
    if (pathName === undefined) {
      return signal(this.fallbackIcon);
    }
    const registeredSignal = this.iconRegistry.get(`${iconLibrary}${this.delimiter}${pathName}`);
    const iconRegistered = !!registeredSignal;
    // If the icon is registered just return it
    if (iconRegistered) {
      return computed(registeredSignal, (entry) => {
        return entry?.data;
      });
    }
    // Otherwise, if lazyLoadEnabled then attempt to fetch and register the icon
    else if (!iconRegistered && this.lazyLoadEnabled) {
      let iconSignal: Signal<IconData>;

      // Load from local server icon cache during ssr if it exists
      if (isSsr()) {
        if (this.serverIconCache) {
          const camelName = pathName.replace(/-([a-z])/g, (g) => g[1].toUpperCase());
          const iconName = `${iconLibrary === 'cx' ? 'cx' : 'mtr'}Icon${camelName
            .charAt(0)
            .toUpperCase()}${camelName.slice(1)}`;

          if (this.serverIconCache[iconName] as any) {
            iconSignal = signal<IconData>({
              data: (this.serverIconCache[iconName] as any)?.data,
              fetched: true,
            });
          } else {
            return this.handledFailedLoad(iconLibrary, pathName, iconSignal) as Signal<
              string | null
            >;
          }
        } else {
          // If the icon cache doesn't exist just do nothing because the icon will be loaded on the client
          iconSignal = signal<IconData>({
            data: null,
            fetched: false,
          });
        }
      }
      // If not on the server then fetch the icon
      else {
        // Set the icon as the result of the await right now, before the fetch
        // is initiated, to prevent redundant initial queries on page load
        iconSignal = signal<IconData>({
          data: null,
          fetched: false,
        });
        this.iconRegistry.set(`${iconLibrary}${this.delimiter}${pathName}`, iconSignal);

        this.fetchIcon(pathName, iconLibrary, abortSignal).then((icon) => {
          iconSignal.set({ data: icon, fetched: true });
        });
      }

      return computed(iconSignal, (entry) => entry?.data);
    }
    // Otherwise, just alert that the icon isn't registered and return the fallback
    else if (!iconRegistered && !this.lazyLoadEnabled) {
      const temp = this.handledFailedLoad(iconLibrary, pathName);
      return temp;
    }
  }

  /** Logs a warning and returns fallback icon. Call when attempt to load icon has failed. */
  private handledFailedLoad(iconLibrary, pathName, iconSignal?: Signal<IconData>) {
    // Create return subj if one isn't passed
    if (!iconSignal) {
      iconSignal = signal<IconData>({
        data: this.fallbackIcon,
        fetched: false,
      });
    }
    this.iconRegistry.set(`${iconLibrary}${this.delimiter}${pathName}`, iconSignal);
    if (!this.testModeEnabled) {
      console.warn(
        `Mortar: No icon is registered for the path name "${pathName}". Did you add it to the mortar icon registry?`
      );
    }
    return computed(iconSignal, (entry) => entry?.data);
  }

  /** Fetches an icon for the given library by name */
  private async fetchIcon(pathName: string, iconLibrary = '_default', abortSignal?: AbortSignal) {
    if (!this.testModeEnabled) {
      try {
        const resolver = this.iconUrlResolvers.get(iconLibrary);
        // If no resolver is registered for the requested library
        if (!resolver) {
          console.warn(
            `MteIconRegistryService: No iconUrlResolver registered for library: "${iconLibrary}"`
          );
          return this.fallbackIcon;
        }
        // Create the fetch request but to not await yet
        const req = fetch(resolver(pathName), { method: 'get', signal: abortSignal });
        const res = this.parseIconData(pathName, req);
        return await res;
      } catch (e) {
        // If the fetch failed log and return fallback
        if (pathName) {
          console.error(`MteIconRegistryService: failed to load icon: ${pathName}`);
        }
        return this.fallbackIcon;
      }
    }
    return this.fallbackIcon;
  }

  /** Asynchronously parse icon fetch response data */
  private parseIconData(pathName: string, req: Promise<Response>): Promise<string> {
    return new Promise((resolve, reject) => {
      req
        .then((res) => {
          if (res.status >= 200 && res.status < 300) {
            resolve(res.text());
          } else {
            if (pathName) {
              console.error(`MteIconRegistryService: failed to load icon: ${pathName}`);
            }
            // Gracefully resolve with fallback icon on failed load
            resolve(this.fallbackIcon);
          }
        })
        .catch((e) => {
          // Gracefully resolve with fallback icon on error
          resolve(this.fallbackIcon);
        });
    });
  }
}

const MteIconRegistryService = new _MteIconRegistryService();

/**
 * Instance of _MteIconRegistryService exported as a singleton.
 *
 * Service used to register svg strings for a given icon name and then look them up by that same name.
 */
export { MteIconRegistryService };
