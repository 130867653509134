import { FormControlMixin, FormControlInterface } from '@open-wc/form-control';
import { property, query } from 'lit/decorators.js';
import {
  Constructor,
  DisabledMixin,
  TabIndexMixin,
  MteElement,
  innerInputValidators,
  TabIndexInterface,
  DisabledInterface,
  onUpdate,
  eventEmitter,
  EventEmitter,
} from '..';

// Required polyfill for now
import '../../core/polyfills/element-internals/index';

export interface MteCheckboxChangeDetail {
  /** The value of the control changed */
  value: string;

  /** The name of the control changed */
  name: string;

  /** Whether or not the element is checked */
  checked: boolean;
}

export interface CheckboxInterface {
  /** The label for the element */
  label?: string;

  /** The error text for the element */
  error?: string;

  /** The hint text for the element */
  hint?: string;

  /** An aria label that will be provided to the input element */
  ariaLabel?: string;

  /** The name for the element. Used when submitting a form that contains the element */
  name: string;

  /** The value for the element. Used as the value for a element when submitting a form that contains the element  */
  value: string;

  /**
   * Alters the behavior of this control. When "controlled", a controls value is driven explicitly by external state
   * via the `value` prop. Change events will fire but the control value will not change until it is altered directly.
   * @experimental
   */
  controlled: boolean;

  /** Whether the element should be checked by default. Useful for when resetting form controls or using a stateless component */
  defaultChecked: boolean;

  /** Whether the element is checked or not */
  checked: boolean;

  /** Whether the element should be readonly */
  readonly: boolean;

  /** Whether the selecting the element is required or not */
  required: boolean;

  /** Renders the required indicator for this inputs label without enabling native `required` validation */
  showRequired: boolean;

  /** Function to call when the input blurs  */
  handleBlur(): void;

  /** Function to call when the input is focused on  */
  handleFocus(): void;

  /** Function to call when the state of the input changes  */
  handleChange(): void;

  /** A set of attributes to be forwarded to an element within render and removed from the element */
  inheritedAttributes: { [key: string]: any };
}

export const CheckboxMixin = <T extends Constructor<MteElement>>(superClass: T) => {
  class CheckboxElement extends TabIndexMixin(FormControlMixin(DisabledMixin(superClass)), {
    initialTabIndex: 0,
  }) {
    inheritedAttributes: { [key: string]: any } = {};

    /** @ignore */
    static formControlValidators = innerInputValidators;

    /** The label for the element */
    @property() label?: string;

    /** The error text for the element */
    @property() error?: string;

    /** The hint text for the element */
    @property() hint?: string;

    /**
     * Alters the behavior of this control. When "controlled", a controls value is driven explicitly by external state
     * via the `value` prop. Change events will fire but the control value will not change until it is altered directly.
     * @experimental
     */
    @property({ type: Boolean }) controlled: boolean;

    /** An aria label that will be provided to the input element */
    @property({ attribute: 'aria-label', reflect: true }) ariaLabel: string;

    /** The name for the element. Used when submitting a form that contains the element */
    @property({ reflect: true }) name: string;

    /** The value for the element. Used as the value for a element when submitting a form that contains the element  */
    @property({ reflect: true }) value? = 'on';

    /** Whether the element should be checked by default. Useful for when resetting form controls or using a stateless component */
    @property({ type: Boolean, reflect: true }) defaultChecked = false;

    /** Whether the element is checked or not */
    @property({ type: Boolean, reflect: true }) checked = false;

    /** Whether the element should be readonly */
    @property({ type: Boolean, reflect: true }) readonly = false;

    /** Whether the selecting the element is required or not */
    @property({ type: Boolean, reflect: true }) required = false;

    /** Renders the required indicator for this inputs label without enabling native `required` validation */
    @property({ type: Boolean }) showRequired?;

    /** The input element used within the form control mixin */
    @query('input[type="checkbox"]') validationTarget: HTMLInputElement;

    /** Emitted when the element's state is about to change. If default is prevented, it will revert the checkbox to it's original checked state prior to selecting. */
    @eventEmitter() _willChange: EventEmitter<MteCheckboxChangeDetail>;

    /** Emitted when the element's state has changed. */
    @eventEmitter() _onChange: EventEmitter<MteCheckboxChangeDetail>;

    /** Emits when this element is focused */
    @eventEmitter() _onFocus: EventEmitter<void>;

    /** Emits when this element loses focus */
    @eventEmitter() _onBlur: EventEmitter<void>;

    @onUpdate('defaultChecked')
    protected onDefaultCheckedChange() {
      if (this.controlled) {
        return;
      }

      if (!this.hasUpdated && this.defaultChecked && !this.checked) {
        this.checked = true;
      }
    }

    @onUpdate(['value', 'checked'], { on: 'both' })
    protected updateValue() {
      this.setValue(this.value);
    }

    @onUpdate('ariaLabel', { on: 'both' })
    protected updateLabel() {
      this.inheritedAttributes = {
        ...this.inheritedAttributes,
        ariaLabel: this.ariaLabel,
      };
      this.removeAttribute('aria-label');
    }

    firstUpdated(changedProps) {
      super.firstUpdated(changedProps);
      if (document.readyState === 'complete') {
        this.setValue(this.value);
      } else {
        document.addEventListener('DOMContentLoaded', () => {
          this.setValue(this.value);
        });
      }
    }

    /** -- Form control validation overrides -- */
    shouldFormValueUpdate(): boolean {
      return this.checked;
    }

    public resetFormControl(): void {
      super.resetFormControl?.();
      this.setValue(this.value);

      if (this.controlled) {
        return;
      }

      this.checked = this.defaultChecked;
    }

    validityCallback(): string | void {
      return this.error || this.validationTarget?.validationMessage;
    }

    /** -- TabIndex overrides -- */
    getTabIndexAdapter() {
      return this.validationTarget?.getAttribute('tabindex');
    }

    setTabIndexAdapter(value: string) {
      this.removeAttribute('tabindex');
      this.validationTarget?.setAttribute('tabindex', value);
    }

    removeTabIndexAdapter() {
      this.removeAttribute('tabindex');
      this.validationTarget?.removeAttribute('tabindex');
    }

    handleChange() {
      if (this.disabled || this.readonly) {
        return;
      }

      const notPrevented = this._willChange.emit(
        {
          value: this.value,
          name: this.name,
          checked: !this.checked,
        },
        { cancelable: true }
      );

      if (this.controlled) {
        this._onChange.emit({
          value: this.value,
          name: this.name,
          checked: !this.checked,
        });
        return;
      }
      if (notPrevented) {
        this.setValue(this.value);
        this.checked = !this.checked;
        this._onChange.emit({
          value: this.value,
          name: this.name,
          checked: this.checked,
        });
      }
    }

    handleBlur = () => {
      this._onBlur.emit();
    };

    handleFocus = () => {
      this._onFocus.emit();
    };

    /** Clicks the element */
    public click() {
      this.validationTarget.click();
    }

    /** Focuses the element */
    public focus() {
      this.validationTarget.focus();
    }

    /** Blurs the element */
    public blur() {
      this.validationTarget.blur();
    }
  }

  return CheckboxElement as Constructor<
    CheckboxInterface & TabIndexInterface & FormControlInterface & DisabledInterface
  > &
    T;
};
