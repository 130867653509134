import { Presets } from '../../core';

export interface NativeSelectPreset {}

export const nativeSelectPresets: Presets<NativeSelectPreset> = {
  default: {},
  cx: {},
  rx: {},
  hw: {},
};
