import { html } from 'lit';
import { baseStyles, defineElement, MteContentBase } from '../../core';
import { styles } from './section.styles';

/**
 *
 */
@defineElement('mte-section')
export class MteSection extends MteContentBase {
  static styles = [baseStyles, styles];

  render() {
    return html`${this.instanceStyles}<slot></slot>`;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'mte-section': MteSection;
  }
}
