import { html } from 'lit';
import { property } from 'lit/decorators.js';
import {
  PresetMixinFactory,
  RadiusMixin,
  InverseMixin,
  ButtonMixin,
  presetProperty,
  MteContentBase,
  defineElement,
  baseStyles,
  buttonStyles,
  inverseStyles,
} from '../../core';
import { styles as cardStyles } from './card.styles';
import { styles } from './card-button.styles';
import { CardButtonPreset } from './card.presets';

/**
 * @slot - Default slotted content
 */
@defineElement('mte-card-button')
export class MteCardButton extends InverseMixin(
  RadiusMixin(PresetMixinFactory<CardButtonPreset>('MteCardButton')(ButtonMixin(MteContentBase)))
) {
  static styles = [baseStyles, inverseStyles, buttonStyles, cardStyles, styles];

  /** Renders a drop shadow for presets with shadows disabled by default. Cards within cards cannot display shadows even if this attribute is present. */
  @presetProperty({ type: Boolean, reflect: true }) withShadow = false;

  /** Prevents drop shadow rendering for presets with shadows enabled by default. Cards within cards cannot display shadows even if this attribute is present. */
  @presetProperty({ type: Boolean, reflect: true }) withoutShadow = false;

  /** Whether or not this card should hide overflow content */
  @property({ type: Boolean, reflect: true }) hideOverflow = false;

  /** Prevents hover styling effect */
  @presetProperty({ type: Boolean, reflect: true }) withoutHover = false;

  /** Whether or not this card should be marked as selected */
  @property({ type: Boolean, reflect: true }) selected = false;

  render() {
    return this.renderButtonTemplate(html`<slot></slot>`);
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'mte-card-button': MteCardButton;
  }
}
