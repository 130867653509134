import { html } from 'lit';
import { property } from 'lit/decorators.js';
import { defineElement, MteElementParts, baseStyles, MteContentBase } from '../../core';
import { styles } from './list.styles';

export interface MteListParts extends MteElementParts {}

/**
 * @slot - Default slotted content
 */
@defineElement('mte-list')
export class MteList extends MteContentBase {
  static styles = [baseStyles, styles];

  @property({ reflect: true }) role = 'list';

  /** How to render dividers for this list */
  @property({ reflect: true }) divider: 'default' | 'inset' | 'none' | null = 'default';

  render() {
    return html`${this.instanceStyles}<slot></slot>`;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'mte-list': MteList;
  }
}
