import { MteOverlayPlacement, MteOverlayPosition } from '../constants';
import { Placement } from '@floating-ui/dom';

/** Calculates optimal floating-ui overlays for the current position */
export const calculateOverlayPlacements = (
  position: MteOverlayPosition,
  placement: MteOverlayPlacement = 'center'
): [Placement, Placement[]] => {
  const getFallbackGroup = (
    position: MteOverlayPosition,
    preferredPlacement: MteOverlayPlacement,
    primaryPosition: string
  ): Placement[] => {
    if (primaryPosition === 'left') {
      if (position === 'top' || position === 'bottom') {
        preferredPlacement = 'start';
      }
    }
    if (primaryPosition === 'right') {
      if (position === 'top' || position === 'bottom') {
        preferredPlacement = 'end';
      }
    }
    const alignOrder =
      preferredPlacement === 'center'
        ? ['', '-end', '-start']
        : preferredPlacement === 'start'
        ? ['-start', '', '-end']
        : ['-end', '', '-start'];
    return alignOrder
      .map((align) => `${position}${align}` as Placement)
      .filter((placement) => placement !== primaryPosition);
  };

  const preferred = `${position}${
    placement === 'end' ? '-end' : placement === 'start' ? '-start' : ''
  }` as Placement;

  switch (position) {
    case 'bottom':
      return [
        preferred,
        [
          ...getFallbackGroup('bottom', placement, preferred),
          ...getFallbackGroup('top', placement, preferred),
          ...getFallbackGroup('right', placement, preferred),
          ...getFallbackGroup('left', placement, preferred),
        ],
      ];
    case 'left':
      return [
        preferred,
        [
          ...getFallbackGroup('left', placement, preferred),
          ...getFallbackGroup('right', placement, preferred),
          ...getFallbackGroup('top', placement, preferred),
          ...getFallbackGroup('bottom', placement, preferred),
        ],
      ];
    case 'right':
      return [
        preferred,
        [
          ...getFallbackGroup('right', placement, preferred),
          ...getFallbackGroup('left', placement, preferred),
          ...getFallbackGroup('top', placement, preferred),
          ...getFallbackGroup('bottom', placement, preferred),
        ],
      ];
    case 'top':
    default:
      return [
        preferred,
        [
          ...getFallbackGroup('top', placement, preferred),
          ...getFallbackGroup('bottom', placement, preferred),
          ...getFallbackGroup('right', placement, preferred),
          ...getFallbackGroup('left', placement, preferred),
        ],
      ];
  }
};

export const calculateDefaultPanelAnimation = (
  renderedPlacement: Placement,
  options: {
    fallbackPosition: string;
    fallbackPlacement: string;
    action: 'open' | 'close';
    scaleX?: number;
    scaleY?: number;
  }
) => {
  const scaleX = options.scaleX ?? 0.9;
  const scaleY = options.scaleY ?? null;
  let position = options.fallbackPosition;
  let placement = options.fallbackPlacement;
  if (renderedPlacement) {
    const [pa, pb] = renderedPlacement.split('-');
    position = pa;
    placement = pb;
  }

  let translateY = null;
  if (position === 'top') {
    translateY = '10%';
  } else if (position === 'bottom') {
    translateY = '-10%';
  } else if (position === 'right' || position === 'left') {
    if (placement === 'start') {
      translateY = '-5%';
    } else if (placement === 'end') {
      translateY = '5%';
    }
  }

  let translateX = '';
  if (position === 'top' || position === 'bottom') {
    if (placement === 'start') {
      translateX = '-5%';
    } else if (placement === 'end') {
      translateX = '5%';
    }
  } else {
    if (position === 'right') {
      translateX = '-10%';
    } else if (position === 'left') {
      translateX = '10%';
    }
  }
  if (options.action === 'open') {
    return {
      scaleX: [scaleX, 1],
      scaleY: scaleY ? [scaleY, 1] : undefined,
      translateX: [translateX, '0%'],
      translateY: translateY ? [translateY, '0%'] : undefined,
    };
  } else {
    return {
      scaleX: [1, scaleX],
      scaleY: scaleY ? [1, scaleY] : undefined,
      translateX: ['0%', translateX],
      translateY: translateY ? ['0%', translateY] : undefined,
    };
  }
};
