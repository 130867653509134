import { LitElement } from 'lit';
import { Constructor } from '../';
import { presetProperty } from '../presets/preset.decorator';
import { PresetElementInterface } from '../presets/preset.mixin';

export type DensityOptions = 'compact' | 'comfy' | 'roomy' | undefined;

export declare class DensityInterface {
  /**
   * Adjusts the data density of this component
   * @attr density
   */
  density: DensityOptions;
}

export const DensityMixin = <T extends Constructor<LitElement & PresetElementInterface<any>>>(
  superClass: T
) => {
  class DensityElement extends superClass {
    /**
     * Adjusts the data density of this component
     * @attr density
     */
    @presetProperty({ reflect: true }) density: DensityOptions;
  }
  return DensityElement as Constructor<DensityInterface> & T;
};
