import { property, state } from 'lit/decorators.js';
import {
  ClickOutsideController,
  Constructor,
  EventEmitter,
  FocusTrapController,
  MteElement,
  PortalAdapter,
  PortalController,
  defaultPortalAdapter,
  eventEmitter,
  isSsr,
} from '..';
import { PropertyValueMap } from 'lit';

export declare class OverlayInterface {
  /**
   * Updates the overlay state on the overlay mixin. By default all options are set to true initially.
   * @param options
   */
  setOverlayOptions(options: {
    withCloseOnClickOutside?: boolean;
    withCloseOnEscape?: boolean;
    withFocusTrap?: boolean;
    withPortal?: boolean;
    withDimBackdrop?: boolean;
    withInertBackdrop?: boolean;
    withScrollLock?: boolean;
    animationDuration?: number;
    backdropTransitionDuration?: string;
    returnToOriginOnClose?: boolean;
    focusTrapTarget?: Element;
  }): unknown;

  /**
   * A function to call when the overlay should open. Can add a listener for outside clicks, portal an element,
   * and prevent the body from scrolling upon open.
   * @param options
   */
  handleOverlayOpen(options?: {
    clickOutsideElement?: HTMLElement;
    willOpen?: () => void;
  }): Promise<void>;

  /**
   * A function to call after the overlay has been optionally portaled and/or all animations have finished.
   */
  handleOverlayOpenEnd(): Promise<void>;

  /**
   * A function to call when the overlay element should close. Can stop listening for outside clicks, release a,
   * focus trap, and allow the body element to scroll again.
   */
  handleOverlayClose(): Promise<void>;

  /**
   * A function to call when the overlay element has finished closing and/or all animations have completed. Can
   * remove an element from the portal.
   * @param options
   */
  handleOverlayCloseEnd(options?: { removeFromElement?: HTMLElement }): Promise<void>;

  open(): Promise<void>;

  close(): Promise<void>;

  /**
   * The tab index for this element
   */
  tabindex: number;

  /** Emitted when this overlay is initially opened */
  _onOpen: EventEmitter<void>;

  /** Emitted after this overlay has finished opening */
  _onOpenEnd: EventEmitter<void>;

  /** Emitted when this overlay is initially closing */
  _onClose: EventEmitter<void>;

  /** Emitted when this overlay has finished closing */
  _onCloseEnd: EventEmitter<void>;

  /** @ignore */
  portalAdapter: PortalAdapter;

  /**
   * @ignore
   * The opened state of the overlay regardless of animations
   */
  opened: boolean;

  /**
   * @ignore
   * Indicates the overlay has been portaled and ready to animate
   */
  readyForAnimation: boolean;

  /**
   * @ignore
   * Indicates the overlay has been fully animated open
   */
  openedFull: boolean;
}

export const OverlayMixin = <T extends Constructor<MteElement>>(superClass: T) => {
  class OverlayElement extends superClass {
    /** The tab index of this element */
    @property({ attribute: 'tabindex', type: Number }) tabindex = -1;

    /**
     * Adapter for portal DOM operation that can be supplemented for external-framework integrations.
     * Notably-leveraged by overlays in our generated react wrappers.
     *
     * @ignore
     */
    @property() portalAdapter: PortalAdapter = defaultPortalAdapter;

    /** Emitted when this overlay is initially opened */
    @eventEmitter() _onOpen: EventEmitter<void>;

    /** Emitted after this overlay has finished opening */
    @eventEmitter() _onOpenEnd: EventEmitter<void>;

    /** Emitted when this overlay is initially closed */
    @eventEmitter() _onClose: EventEmitter<void>;

    /** Emitted when this overlay has finished closing */
    @eventEmitter() _onCloseEnd: EventEmitter<void>;

    /** Whether the overlay element should use a focus trap or not */
    private _withFocusTrap = true;

    /** Whether the overlay element should close when a user clicks outside of the element */
    private _closeOnClickOutside = true;

    /** Whether the element should close when the escape button is pressed */
    private _closeOnEscape = true;

    /** Whether the overlay should use a portal or not */
    private _withPortal = true;

    /** Wether the user should be able to click behind the backdrop or not */
    private _withInertBackdrop = false;

    /** Wether the overlay's backdrop should render dimmed */
    private _withDimBackdrop = false;

    /** Wether or not the element should be re-appended to it's origin parent element on close _if_ portaled. */
    private _returnToOriginOnClose = false;

    /** Optional backdrop timing. Important particularly for smooth closes with a visible backdrop */
    private _backdropTransitionDuration?: string;

    /** Whether the overlay should prevent scrolling on the body */
    private _withScrollLock = true;

    protected focusTrapController = new FocusTrapController(this);

    protected clickOutsideController = new ClickOutsideController(this, () => {
      if (!this._withPortal) {
        this.handleCloseOnClickOutside();
      }
    });

    protected portalController = new PortalController(this);

    /**
     * The initial parent element
     */
    protected initialParent: Element | HTMLElement = this.getActualParentElement();

    /**
     * The element to detect clicks outside of to close the overlay element.
     */
    private clickOutsideOverlayElement: HTMLElement | null;

    private _originParent?: Element | HTMLElement;

    private _focusTrapTarget?: Element | HTMLElement = this;

    /**
     * @ignore
     * The opened state of the overlay regardless of animations
     */
    @property({ type: Boolean, reflect: true }) opened = false;

    /**
     * @ignore
     * Indicates the overlay has been portaled and ready to animate
     */
    @state()
    set readyForAnimation(readyForAnimation: boolean) {
      this._readyForAnimation = readyForAnimation;
    }
    get readyForAnimation() {
      // During SSR return true in case something needs to be opened initially.
      return isSsr() ? true : this._readyForAnimation;
    }
    private _readyForAnimation = false;

    /**
     * @ignore
     * The opened state of the overlay regardless of animations. This is reflected and used by
     * some overlays to restrict their size while hidden. (since display: none breaks animations)
     */
    @property({ type: Boolean, reflect: true }) openedFull = false;

    setOverlayOptions(options: {
      withCloseOnClickOutside?: boolean;
      withCloseOnEscape?: boolean;
      withFocusTrap?: boolean;
      withPortal?: boolean;
      withInertBackdrop?: boolean;
      withDimBackdrop?: boolean;
      withScrollLock?: boolean;
      backdropTransitionDuration?: string;
      returnToOriginOnClose?: boolean;
      focusTrapTarget?: Element;
    }) {
      const {
        withFocusTrap,
        withCloseOnClickOutside,
        withCloseOnEscape,
        withPortal,
        withScrollLock,
        withInertBackdrop,
        withDimBackdrop,
        backdropTransitionDuration,
        returnToOriginOnClose,
        focusTrapTarget,
      } = options ?? {};

      this._withFocusTrap = withFocusTrap ?? this._withFocusTrap;
      this._closeOnClickOutside = withCloseOnClickOutside ?? this._closeOnClickOutside;
      this._withScrollLock = withScrollLock ?? this._withScrollLock;
      this._withPortal = withPortal ?? this._withPortal;
      this._withInertBackdrop =
        withInertBackdrop ?? !this._withFocusTrap ?? this._withInertBackdrop;
      this._withDimBackdrop = withDimBackdrop ?? this._withDimBackdrop;
      this._backdropTransitionDuration =
        backdropTransitionDuration ?? this._backdropTransitionDuration;
      this._returnToOriginOnClose = returnToOriginOnClose ?? this._returnToOriginOnClose;
      this._focusTrapTarget = focusTrapTarget ?? this._focusTrapTarget;

      if (withCloseOnEscape !== undefined && this._closeOnEscape !== withCloseOnEscape) {
        if (withCloseOnEscape === true) {
          document.addEventListener('keydown', this.handleOverlayKeyDown);
        } else {
          document.removeEventListener('keydown', this.handleOverlayKeyDown);
        }
        this._closeOnEscape = withCloseOnEscape ?? this._closeOnEscape;
      }

      if (withFocusTrap === false) {
        this.tabindex = 0;
      }

      if (withFocusTrap === true && this.tabindex !== -1) {
        this.tabindex = -1;
      }

      this.updateOverlay();
    }

    handleOverlayOpen(options?: {
      clickOutsideElement?: HTMLElement;
      willOpen?: () => void;
    }): Promise<void> {
      // Ignore if already open
      if (this.opened || this.openedFull) {
        return Promise.resolve();
      }
      this.opened = true;
      const { clickOutsideElement, willOpen } = options ?? {};
      willOpen?.();
      return new Promise<void>((resolve) => {
        this.clickOutsideOverlayElement = clickOutsideElement || this;
        this.clickOutsideController.listenForClicksOutsideOf(this.clickOutsideOverlayElement);
        if (this._withPortal) {
          this.portalController
            .appendToStack(this, {
              withDimBackdrop: this._withDimBackdrop,
              withInertBackdrop: this._withInertBackdrop,
              withScrollLock: this._withScrollLock,
              closeOnClickOutsideHandler: this.handleCloseOnClickOutside,
              backdropTransitionDuration: this._backdropTransitionDuration,
              portalAdapter: this.portalAdapter,
            })
            .then(() => {
              // Wait for append to complete before animating
              setTimeout(() => {
                this.readyForAnimation = true;
                // After animation begins resolve
                this.updateComplete.then(() => {
                  this._onOpen.emit(null, { bubbles: false });
                  resolve();
                });
              }, 20);
            });
        } else {
          if (
            this._originParent &&
            this.getActualParentElement() &&
            this.getActualParentElement() !== this._originParent
          ) {
            this.portalAdapter(this, this._originParent);
          }
          // Wait for append to complete before animating
          setTimeout(() => {
            this.readyForAnimation = true;
            // After animation begins resolve
            this.updateComplete.then(() => {
              this._onOpen.emit(null, { bubbles: false });
              resolve();
            });
          }, 20);
        }
      });
    }

    async handleOverlayOpenEnd() {
      // Cancel if closed before open ended
      if (!this.opened) {
        return;
      }
      this.openedFull = true;
      if (this._withFocusTrap) {
        this.focusTrapController.trapFocus(this._focusTrapTarget, document.activeElement);
      }
      this._onOpenEnd.emit(null, { bubbles: false });
    }

    async handleOverlayClose() {
      // Ignore if already closed
      if (!this.opened) {
        return;
      }
      this.opened = false;
      this._onClose.emit(null, { bubbles: false });
      this.clickOutsideOverlayElement = null;
      this.clickOutsideController.stopListening();
      this.focusTrapController.releaseFocus(true);
      this.portalController.hideBackdrop();
    }

    async handleOverlayCloseEnd(options?: { removeFromElement?: HTMLElement }) {
      // Cancel if opened before close ended
      if (this.opened) {
        return;
      }
      this.openedFull = false;
      this.readyForAnimation = false;
      this.portalController.removeFromStack(options?.removeFromElement || this);
      if (this._returnToOriginOnClose) {
        if (this._originParent && this._originParent !== this.getActualParentElement()) {
          this.portalAdapter(this, this._originParent);
        }
      }
      this._onCloseEnd.emit(null, { bubbles: false });
    }

    private handleOverlayKeyDown(e: KeyboardEvent) {
      if (e.key === 'Escape') {
        this.close();
      }
    }

    getActualParentElement() {
      return this.parentElement?.hasAttribute('data-overlay-wrapper')
        ? this.parentElement?.parentElement
        : this.parentElement;
    }

    /**
     * Updates the parent element this overlay will be reattached to either:
     * - When configured to do so on close
     * - When "portaling" is disabled
     */
    setOriginParent(newOrigin: Element) {
      const currentParentElement = this.getActualParentElement();
      if (currentParentElement === this._originParent) {
        this.portalAdapter(this, newOrigin);
      }
      this._originParent = newOrigin;
    }

    /** Updates various behaviors to apply the latest overlay options */
    updateOverlay() {
      // Update backdrop inertness
      if (this._withInertBackdrop) {
        this.portalController.setBackdropInertness(true);
      } else {
        this.portalController.setBackdropInertness(false);
      }
      // Update click outside controller
      if (this._closeOnClickOutside && !this.clickOutsideController.isListening()) {
        this.clickOutsideController.listenForClicksOutsideOf(this);
      } else if (!this._closeOnClickOutside && this.clickOutsideController.isListening()) {
        this.clickOutsideController.stopListening();
      }
      // Update focus trap controller
      if (this._withFocusTrap && this.opened && !this.focusTrapController.isTrappingFocus()) {
        this.focusTrapController.trapFocus(this._focusTrapTarget, document.activeElement);
      } else if (!this._withFocusTrap && this.focusTrapController.isTrappingFocus()) {
        this.focusTrapController.releaseFocus(true);
      }
      // Update backdrop controller
      if (this._withDimBackdrop) {
        this.portalController.showBackdrop();
      } else {
        this.portalController.hideBackdrop();
      }
      if (this._returnToOriginOnClose) {
        if (this._originParent && this._originParent !== this.getActualParentElement()) {
          this.portalAdapter(this, this._originParent);
        }
      }
    }

    async open() {
      throw new Error('Open method not implemented');
    }

    async close() {
      throw new Error('Close method not implemented');
    }

    constructor(...args: any) {
      super(args);
      this.handleOverlayKeyDown = this.handleOverlayKeyDown.bind(this);
    }

    protected firstUpdated(
      changedProperties: PropertyValueMap<any> | Map<PropertyKey, unknown>
    ): void {
      super.firstUpdated(changedProperties);
      this._originParent = this.getActualParentElement();
    }

    private handleCloseOnClickOutside = () => {
      if (this._closeOnClickOutside) {
        this.close();
      }
    };

    connectedCallback(): void {
      super.connectedCallback();

      if (this._closeOnEscape) {
        document.addEventListener('keydown', this.handleOverlayKeyDown);
      }

      // Add the event listener in the event the overlay element was portaled.
      if (this.clickOutsideOverlayElement) {
        this.clickOutsideController.listenForClicksOutsideOf(this.clickOutsideOverlayElement);
      }
    }

    disconnectedCallback(): void {
      super.disconnectedCallback();
      this.focusTrapController.releaseFocus(true);
      document.removeEventListener('keydown', this.handleOverlayKeyDown);
    }
  }

  return OverlayElement as Constructor<OverlayInterface> & T;
};
