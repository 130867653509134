import { selectorFactory } from '@mortar/styles';
import { html } from 'lit';
import { property } from 'lit/decorators.js';
import { baseStyles, defineElement, MteContentBase } from '../../core';
import { styles } from './text.styles';
import { text } from 'stream/consumers';

/**
 * @slot - Default slotted content
 */
@defineElement('mte-text')
export class MteText extends MteContentBase {
  static styles = [baseStyles, styles];

  /** Sets the `text-alignment` property */
  @property({ reflect: true }) align?:
    | 'start'
    | 'center'
    | 'end'
    | 'right'
    | 'left'
    | 'justify'
    | 'justify-all'
    | 'match-parent'
    | 'initial'
    | 'inherit'
    | 'revert'
    | 'revert-layer'
    | 'unset';

  /** Adjusts the font size of this text */
  @property({ reflect: true }) size?: 'xxs' | 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'xxl';

  /** Sets the `text-transform` property */
  @property({ reflect: true }) transform?:
    | 'uppercase'
    | 'lowercase'
    | 'capitalize'
    | 'full-width'
    | 'initial'
    | 'inherit'
    | 'revert'
    | 'revert-layer'
    | 'unset';

  /** Sets the `word-break` property */
  @property({ reflect: true }) wordBreak?:
    | 'normal'
    | 'break-all'
    | 'keep-all'
    | 'initial'
    | 'inherit'
    | 'revert'
    | 'revert-layer'
    | 'unset';

  /** Sets the `word-spacing` property */
  @property({ reflect: true }) wordSpacing?: 'sm' | 'md' | 'lg';

  /** Sets the `letter-spacing` property */
  @property({ reflect: true }) letterSpacing?: 'sm' | 'md' | 'lg';

  /** Sets the `white-space` property */
  @property({ reflect: true }) whiteSpace?:
    | 'normal'
    | 'nowrap'
    | 'pre'
    | 'pre-wrap'
    | 'pre-line'
    | 'break-spaces'
    | 'initial'
    | 'inherit'
    | 'revert'
    | 'revert-layer'
    | 'unset';

  /** Whether or not the text should be underlined */
  @property({ type: Boolean, reflect: true }) underline?: boolean;

  /** Whether or not the text should be italic */
  @property({ type: Boolean, reflect: true }) italic?: boolean;

  /** Whether or not the text should be striked through */
  @property({ type: Boolean, reflect: true }) strikethrough?: boolean;

  /** Whether or not this text should render muted */
  @property({ type: Boolean, reflect: true }) muted?: boolean;

  /** Limits the amount of visible lines. Sets the `-webkit-line-clamp` property. */
  @property({ reflect: true }) lineClamp?: string;

  /** Adjusts if text is wrapped and if so, what algorithm determines the wrapping behavior. */
  @property({ reflect: true }) textWrap?:
    | 'wrap'
    | 'nowrap'
    | 'balance'
    | 'pretty'
    | 'inherit'
    | 'initial'
    | 'revert'
    | 'revert-layer'
    | 'unset'
    | undefined;

  /** Weather or not the text should wrap to the next line */
  @property({ type: Boolean, reflect: true }) noWrap?: boolean;

  /** Limits the width to a specified number of 0 glyph characters and is typically used in combination with noWrap or lineClamp */
  @property({ type: Number, reflect: true }) maxCharWidth?: number;

  private static _styleProps = new Set([
    'align',
    'italic',
    'lineClamp',
    'maxCharWidth',
    'strikethrough',
    'transform',
    'underline',
    'whiteSpace',
    'wordBreak',
    'wordSpacing',
    'letterSpacing',
    'textWrap',
  ]);

  protected updateInstanceStyles(changedProps) {
    super.updateInstanceStyles(changedProps);

    // Check if any content prop has changed to determine if we need to update the instance styles
    let shouldUpdateStyles = false;
    for (const key of changedProps.keys()) {
      if (MteText._styleProps.has(key)) {
        shouldUpdateStyles = true;
        break;
      }
    }

    if (shouldUpdateStyles) {
      // Calc text-decoration
      let textDecoration;
      if (this.underline) {
        textDecoration = `underline${this.strikethrough ? ' line-through' : ''}`;
      } else if (this.strikethrough) {
        textDecoration = 'line-through';
      }
      // Calc word-spacing
      let wordSpacing;
      if (this.wordSpacing === 'sm') {
        wordSpacing = '-0.05em';
      } else if (this.wordSpacing === 'lg') {
        wordSpacing = '0.05em';
      }
      // Calc letter-spacing
      let letterSpacing;
      if (this.letterSpacing === 'sm') {
        letterSpacing = '-0.02em';
      } else if (this.letterSpacing === 'lg') {
        letterSpacing = '0.02em';
      }

      // Double the `:host` selector to increase specificity beyond most component level styles by default
      this.setInstanceStyle('text', selectorFactory(':host:host:host:host:host'), {
        '-webkit-line-clamp': this.lineClamp,
        'maxWidth': Number.isFinite(this.maxCharWidth)
          ? `${Math.round(this.maxCharWidth < 0 ? 0 : this.maxCharWidth)}ch`
          : null,
        'word-break': this.wordBreak,
        'word-spacing': wordSpacing,
        'letter-spacing': letterSpacing,
        'white-space': this.whiteSpace,
        'font-style': this.italic ? 'italic' : undefined,
        'text-align': this.align,
        'text-decoration': textDecoration,
        'text-transform': this.transform,
        'text-wrap': this.textWrap,
      });
    }
  }

  render() {
    return html`${this.instanceStyles}<slot></slot>`;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'mte-text': MteText;
  }
}
