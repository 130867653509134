import { property } from 'lit/decorators.js';
import { Constructor, MteElement, StyleMap } from '..';
import { selectorFactory } from '@mortar/styles';

export interface MteElementParts {
  /** The host element "part". Overrides the `se` property of this element */
  host?: StyleMap;
}

export type StylePartsMap<T = string | number> = {
  [name: string]: StyleMap<T>;
};

export declare class StylePartsInterface<StyleParts> {
  /**
   * Style mappings that are applied to each css part by name. Set the
   * property `host` to apply a `StyleInfo` object to the element host itself.
   */
  sp?: StyleParts;
}

export const StylePartsMixin = <StyleParts, T extends Constructor<MteElement>>(superClass: T) => {
  class StylePartsElement extends superClass {
    /**
     * Style mappings that are applied to each css part by name. Set the
     * property `host` to apply a `StyleInfo` object to the element host itself.
     */
    @property({ type: Object, reflect: true }) sp: StyleParts;

    protected updateInstanceStyles(changedProps) {
      super.updateInstanceStyles(changedProps);

      if (changedProps.has('sp')) {
        Object.entries(this.sp ?? ({} as StyleParts)).forEach(([part, styleMap]) => {
          // Double the `:host` selector to increase specificity beyond most component level styles by default
          const selector = selectorFactory(
            part === 'host'
              ? ':host:host:host:host:host:host'
              : `:host:host:host:host:host:host::part(${part})`
          );
          this.setInstanceStyle(`sp-${part}`, selector, styleMap);
        });
      }
    }
  }

  return StylePartsElement as Constructor<StylePartsInterface<StyleParts>> & T;
};
