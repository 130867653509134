import { Presets } from '../../core';

export interface SliderPreset {}

export const sliderPresets: Presets<SliderPreset> = {
  default: {},
  cx: {},
  rx: {},
  hw: {},
};
