import { ReactiveController, ReactiveControllerHost } from 'lit';
import { ElementPortalController, MtePortalService, PortalOptions, PortalStack } from '../services';

export interface PortalControllerOptions {
  stack: PortalStack;
}

let nextUniqueId = 0;

/**
 * Controller used to attach any element to a portal stack.
 *
 * Use this controller when you need to render an overlay at the root of the DOM.
 * To avoid positioning conflicts or overflow clipping.
 */
export class PortalController implements ReactiveController, ElementPortalController {
  /** @ignore */
  id = nextUniqueId++;

  /**
   * Used by the MtePortalService to cache this elements portaled parent reference
   * @ignore
   */
  parentPortaledElement: Element;

  /**
   * Flag to help check if a disconnected callback has been fired
   * because of a portaling operation.
   */
  private ignoreNextDisconnect = true;

  constructor(
    public host: ReactiveControllerHost & Element,
    public options: PortalControllerOptions = { stack: 'overlay' }
  ) {
    this.host.addController(this);
  }

  /** Requests the portal service append this item to the configured stack */
  public appendToStack(element: Element = this.host, options: PortalOptions = {}) {
    // Only ignore the next disconnect if the element being portaled is currently connected to the DOM
    if (element?.isConnected) {
      this.ignoreNextDisconnect = true;
    }
    return MtePortalService.appendToStack(this, element, options);
  }

  /** Requests the portal service remove this element from the configured stack */
  public removeFromStack(element: Element = this.host) {
    this.ignoreNextDisconnect = false;
    return MtePortalService.removeFromStack(this, element);
  }

  /** Makes a backdrop associated with a portaled element visible if it exists */
  public showBackdrop(element: Element = this.host) {
    return MtePortalService.showBackdrop(this, element);
  }

  /** Hides a backdrop associated with a portaled element if it exists */
  public hideBackdrop(element: Element = this.host) {
    return MtePortalService.hideBackdrop(this, element);
  }

  /** Updates the intertness a backdrop if it exists (whether or not the user can click through it) */
  public setBackdropInertness(inert: boolean, element: Element = this.host) {
    return MtePortalService.setBackdropInertness(this, element, inert);
  }

  hostConnected() {
    if (this.options.stack) {
      MtePortalService.initializeStack(this.options.stack);
    }
  }

  hostDisconnected() {
    // Check if the next disconnect should be ignored because it is trigged
    // from a portaling operation
    if (this.ignoreNextDisconnect) {
      this.ignoreNextDisconnect = false;
    } else {
      MtePortalService.removeController(this);
    }
  }
}
