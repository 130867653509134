import { isSsr } from '../utilities';

export interface EventOptions {
  /** indicate if event bubbles up through the DOM or not */
  bubbles?: boolean;
  /** indicate if event is cancelable */
  cancelable?: boolean;
  /** indicate if event can bubble across the boundary between the shadow DOM and the light DOM */
  composed?: boolean;
}

export class EventEmitter<T> {
  private eventName: string;

  constructor(private target: HTMLElement, eventName: string) {
    // Sanity check to call out improperly prefixed event emitters
    try {
      if (eventName?.[0] !== '_') {
        console.error(
          `${target.tagName}: EventEmitter property for "${eventName}" must be prefixed with "_"`
        );
      }
    } catch (e) {
      // eslint-disable-next-line no-empty
    }
    this.eventName = eventName.replace(/^_/, '');
  }

  /** Dispatches the custom event. If the event is `cancelable` and is prevented this will return false. */
  emit(value: T, options?: EventOptions): boolean {
    // TODO(reece): remove when events are supported during SSR
    if (!isSsr()) {
      return this.target.dispatchEvent(
        new CustomEvent<T>(this.eventName, {
          detail: value,
          bubbles: true,
          cancelable: false,
          composed: true,
          ...options,
        })
      );
    } else {
      return true;
    }
  }
}

// Legacy TS Decorator
function legacyEvent(
  descriptor: PropertyDescriptor,
  protoOrDescriptor: Record<string, unknown>,
  name: PropertyKey
) {
  Object.defineProperty(protoOrDescriptor, name, descriptor);
}

// TC39 Decorators proposal
function standardEvent(descriptor: PropertyDescriptor, element: { key: string }) {
  return {
    kind: 'method',
    placement: 'prototype',
    key: element.key,
    descriptor,
  };
}

export function eventEmitter() {
  return (protoOrDescriptor: any, name: string): any => {
    const descriptor = {
      get(this: HTMLElement) {
        return new EventEmitter(this, name !== undefined ? name : protoOrDescriptor.key);
      },
      enumerable: true,
      configurable: true,
    };

    return name !== undefined
      ? legacyEvent(descriptor, protoOrDescriptor, name)
      : standardEvent(descriptor, protoOrDescriptor);
  };
}
