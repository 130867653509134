import { Presets } from './../../core';

export interface ModalPreset {}

export const modalPresets: Presets<ModalPreset> = {
  default: {},
  cx: {},
  rx: {},
  hw: {},
};
