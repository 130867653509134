const nativeValidityStates: Array<keyof ValidityState> = [
  'badInput',
  'customError',
  'patternMismatch',
  'rangeOverflow',
  'rangeUnderflow',
  'stepMismatch',
  'tooLong',
  'tooShort',
  'typeMismatch',
  'valueMissing',
];

/**
 * Custom validator for the `@open-wc/form-control` that leans on an internal
 * input to forward native validation
 */
export const innerInputValidators = nativeValidityStates.map((key) => ({
  key,
  isValid(instance: HTMLElement & { validationTarget: HTMLInputElement }) {
    if (instance.validationTarget) {
      return !instance.validationTarget.validity[key];
    }
    return true;
  },
}));

export const radioInputValidators = [
  {
    attribute: 'required',
    key: 'valueMissing',
    message: 'Please select an item',
    async isValid(instance) {
      // Wait for an update to occur to allow the radio elements to be rendered.
      await instance.updateComplete;

      const isChecked = instance.radioElements.some((instance) => instance.checked);
      if (instance.required && !isChecked) {
        return false;
      }

      return true;
    },
  },
];
