import { property } from 'lit/decorators.js';
import { Constructor, MteElement, SlotController } from '..';

export declare class SlotObserverInterface {
  /**
   * Prop used to declare which slots are being used during SSR to make them visible in certain edge-cases.
   */
  ssrSlots: string;

  slotController: SlotController;

  hasSlot: (slot: string, orValue?: any) => boolean;

  ssrSlotCheck: (slots: string) => boolean;
}

export const SlotObserverMixin = <T extends Constructor<MteElement>>(superClass: T) => {
  class SlotObserverElement extends superClass {
    /**
     * Prop used to declare which slots are being used during SSR to make them visible in certain edge-cases.
     */
    @property({ reflect: true })
    set ssrSlots(val: string) {
      let oldVal = this._ssrSlots;
      this._ssrSlots = val;
      // Parse and cache ssr slot set
      const slots = (this._ssrSlots ?? '')
        .trim()
        .split(' ')
        .map((slot) => slot.trim());
      this._ssrSlotSet = new Set<string>(slots);
      // Normal update
      this.requestUpdate('ssrSlots', oldVal);
    }
    get ssrSlots() {
      return this._ssrSlots;
    }
    private _ssrSlots?: string;

    /** @ignore */
    _ssrSlotSet = new Set<string>();

    slotController = new SlotController(this);

    /**
     * Returns true if the given slot has content, or if it was specified as existing during SSR.
     * Once called, the component will now automatically update if the contents of this slot change.
     */
    hasSlot(slot: string, orValue?: any): boolean {
      this.slotController.updateOnChange(slot);
      // TODO(reece): reconsider when a solution exists for https://github.com/lit/lit/issues/1434
      // We have to assume alignment with ssrSlotCheck until the first update here otherwise part mismatches can occur
      if (!this.__firstUpdated) {
        return orValue || this.ssrSlotCheck(slot);
      } else {
        return orValue || this.slotController.check(slot);
      }
    }

    /** Will return true if the requested slot was specified in the list of `ssr-slots`. */
    ssrSlotCheck(slot: string) {
      if (this.__firstUpdated) {
        return false;
      } else {
        return this._ssrSlotSet.has(slot);
      }
    }
  }
  return SlotObserverElement as Constructor<SlotObserverInterface> & T;
};
