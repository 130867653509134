import { MteElement } from '../base-classes';

/**
 * Utility method that ensures an element is not tree-shaken
 *
 * This element loops through the provided classes and constructs them. This forces
 * any and all build tooling to _not_ tree-shake away the component. Tree-shaking is
 * an issue because elements are only imported but not directly referenced.
 */
export function registerElements(...elements: typeof MteElement[]) {
  elements.forEach((elem) => {
    /**
     * We wrap each element in a proxy here so we can intercept the constructor call
     * If called this way, some browser environments will complained that an element
     * constructor is being called this way instead of being initialized as a DOM element.
     */
    const proxy = new Proxy(elem, {
      construct(target, args) {
        return {} as any;
      },
    });
    new proxy();
  });
}
