import { html } from 'lit';
import { property } from 'lit/decorators.js';
import { defineElement, MteElementParts, baseStyles, SizeMixin, RadiusMixin } from '../../core';
import { MteContentGrid } from '../grid';
import { styles as contentGridStyles } from '../grid/content-grid.styles';
import { styles } from './metadata.styles';
import { selectorFactory } from '@mortar/styles';

export interface MteMetadataParts extends MteElementParts {}

/**
 * @slot - Default slotted content
 */
@defineElement('mte-metadata')
export class MteMetadata extends RadiusMixin(SizeMixin(MteContentGrid)) {
  static styles = [baseStyles, contentGridStyles, styles];

  /** Adjusts the visual representation of all child metadata items */
  @property({ reflect: true }) format: 'grid' | 'stack' = 'grid';

  /** Adjusts the justification of item labels when `format="grid"` */
  @property({ reflect: true }) justifyLabel: 'flex-start' | 'flex-end';

  /** The a11y role applied to this item */
  @property({ reflect: true }) role = 'list';

  protected updateInstanceStyles(changedProps) {
    super.updateInstanceStyles(changedProps);

    if (changedProps.has('justifyLabel') || changedProps.has('format')) {
      this.setInstanceStyle('mte-metadata', selectorFactory(this.instanceStyleSelectorRoot), {
        '--key-justify': this.format === 'grid' ? this.justifyLabel : null,
      });
    }
  }

  render() {
    return html`${this.instanceStyles}<slot></slot>`;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'mte-metadata': MteMetadata;
  }
}
