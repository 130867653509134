import { ReactiveControllerHost } from 'lit';

interface ClosableElement {
  close: () => any | Promise<any>;
}

/**
 * This controller watches for a `doClose` event to be thrown by any child elements.
 * When a `doClose` event is emitted it will attempt to close the host.
 */
export class DetectChildCloseController {
  constructor(private host: ReactiveControllerHost & Element & ClosableElement) {
    this.host.addController(this);
  }

  /**
   * Only trigger a close if the target is not this element
   * as this implies the close was triggered from a child element
   */
  private childOnlyClose = (e: CustomEvent<any>) => {
    if (e.target !== this.host) {
      e.stopPropagation();
      this.host.close();
    }
  };

  hostConnected() {
    this.host.addEventListener('doClose', this.childOnlyClose, true);
  }

  hostDisconnected() {
    this.host.removeEventListener('doClose', this.childOnlyClose, true);
  }
}
