import { signal } from '../utilities';

class _MteHistoryApiService {
  private initialized = false;
  private changeSignal = signal();
  private prevHref: string;

  public stateChanges() {
    this.init();
    return this.changeSignal;
  }

  private forwardApply = (target, thisArg, argArray) => {
    const res = target.apply(thisArg, argArray);
    // Only emit a state change if the href differs from the last emission
    if (this.prevHref !== window.location.href) {
      this.prevHref = window.location.href;
      this.changeSignal.set(this.prevHref);
    }
    return res;
  };

  private init() {
    if (!this.initialized) {
      this.initialized = true;

      // Listen for back events with popstate event
      window.addEventListener('popstate', () => {
        this.changeSignal.set(window.location.href);
      });

      // Patch History API methods to ensure we emit anytime their state is changed
      // This is neccesary becaise the `popstate` event is not reliably called across browsers whenever the history API is used
      window.history.back = new Proxy(window.history.back, { apply: this.forwardApply });
      window.history.forward = new Proxy(window.history.forward, { apply: this.forwardApply });
      window.history.go = new Proxy(window.history.go, { apply: this.forwardApply });
      window.history.pushState = new Proxy(window.history.pushState, { apply: this.forwardApply });
      window.history.replaceState = new Proxy(window.history.pushState, {
        apply: this.forwardApply,
      });
    }
  }
}

export const MteHistoryApiService = new _MteHistoryApiService();
