export interface ActiveLinkOptions {
  /**
   * - `exact`: all path segments must match.
   * - `subset`: the leading portion of the path must match.
   */
  path: 'exact' | 'subset';

  /**
   * - `exact`: the query parameters must match exactly.
   * - `subset`: all preset parameters must exist, but others can as well.
   * - `ignored`: query params will be ignored.
   */
  queryParams: 'exact' | 'subset' | 'ignored';

  /**
   * - `exact`: indicates that the fragments must be equal.
   * - `ignored`: the fragments will not be compared when determining if active.
   */
  fragment: 'exact' | 'ignored';
}

const defaultActiveLinkOptions = {
  path: 'exact',
  queryParams: 'subset',
  fragment: 'ignored',
};

export const isLinkActive = (
  targetHref: string,
  activeHref: string,
  options?: Partial<ActiveLinkOptions>
) => {
  if (!targetHref) {
    return false;
  } else {
    const mergedOptions = Object.assign(defaultActiveLinkOptions, options ?? {});
    const targetUrl = new URL(targetHref);
    const activeUrl = new URL(activeHref);
    let active = true;

    // Check path
    if (mergedOptions.path === 'exact') {
      if (targetUrl.pathname !== activeUrl.pathname) {
        active = false;
      }
    } else if (mergedOptions.path === 'subset') {
      if (!activeUrl.pathname.startsWith(targetUrl.pathname)) {
        active = false;
      }
    }
    // Check queryParams
    if (mergedOptions.queryParams === 'exact') {
      activeUrl.searchParams.forEach((val, key) => {
        if (val !== targetUrl.searchParams.get(key)) {
          active = false;
        }
      });
    } else if (mergedOptions.queryParams === 'subset') {
      targetUrl.searchParams.forEach((val, key) => {
        if (val !== activeUrl.searchParams.get(key)) {
          active = false;
        }
      });
    }
    // Check fragment
    if (mergedOptions.fragment === 'exact') {
      if (activeUrl.hash !== targetUrl.hash) {
        active = false;
      }
    }
    return active;
  }
};
