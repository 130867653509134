import { html } from 'lit';
import { property } from 'lit/decorators.js';
import { baseStyles, MteContentBase, defineElement } from '../../core';
import { styles } from './tab-panel.styles';

let nextUniqueId = 0;

/**
 * @slot - Default slotted content
 */
@defineElement('mte-tab-panel')
export class MteTabPanel extends MteContentBase {
  static styles = [baseStyles, styles];

  /** Define associated tab, should match MteTab's `panel` property */
  @property({ reflect: true }) name: string;

  /** Specify role of MteTab */
  @property({ reflect: true }) role = 'tabpanel';

  @property({ type: Boolean, reflect: true }) active = false;

  @property({ type: Boolean, reflect: true }) selected = false;

  @property({ reflect: true, attribute: 'aria-labelledby' }) ariaLabelledBy: string;

  /** Specify an id to be associated with the tab panel. Required */
  @property({ reflect: true }) id = `mte-tab-panel--${nextUniqueId++}`;

  render() {
    return html`${this.instanceStyles}<slot></slot>`;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'mte-tab-panel': MteTabPanel;
  }
}
