import { Presets } from '../../core';

export interface SkeletonPreset {}

export const skeletonPresets: Presets<SkeletonPreset> = {
  default: {},
  cx: {},
  rx: {},
  hw: {},
};
