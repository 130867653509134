import { html } from 'lit';
import { property } from 'lit/decorators.js';
import { baseStyles, defineElement, MteElement } from '../../core';
import { styles } from './description.styles';

let nextUniqueId = 0;

/**
 * Element used to represent description messaging text
 */
@defineElement('mte-description')
export class MteDescription extends MteElement {
  static styles = [baseStyles, styles];

  /** Default unique ID for this hint */
  @property({ reflect: true }) id = `mte-description--${nextUniqueId++}`;

  render() {
    return html`${this.instanceStyles}<slot></slot>`;
  }
}

/**
 * Element used to represent description messaging text
 */
@defineElement('mte-description-text')
export class MteDescriptionText extends MteDescription {}

declare global {
  interface HTMLElementTagNameMap {
    'mte-description': MteDescription;
    'mte-description-text': MteDescriptionText;
  }
}
