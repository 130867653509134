import { Presets } from '../../core';

export interface TextareaPreset {}

export const textareaPresets: Presets<TextareaPreset> = {
  default: {},
  cx: {},
  rx: {},
  hw: {},
};
