import { isServer } from 'lit-html/is-server.js';

export const isSsr = () => isServer;

export const isBrowserLike = () => globalThis !== undefined;

export const isValidResizeController = (resizeController) =>
  resizeController?.['_observer']?.['unobserve'];

interface NavigatorUAData {
  brands: Array<{ brand: string; version: string }>;
  mobile: boolean;
  platform: string;
}

export function getPlatform(): string {
  const uaData = (navigator as any).userAgentData as NavigatorUAData | undefined;

  if (uaData?.platform) {
    return uaData.platform;
  }

  return navigator.platform;
}

export const isPlatform = (platform: 'IOS') => {
  if (!isSsr()) {
    if (platform === 'IOS') {
      return /iP(hone|ad|od)|iOS/.test(getPlatform());
    } else {
      return false;
    }
  } else {
    return false;
  }
};

export const prefersReducedMotion = () =>
  isSsr() ? false : globalThis.window.matchMedia('(prefers-reduced-motion: reduce)').matches;
