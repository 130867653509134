import { html } from 'lit';
import { property } from 'lit/decorators.js';
import {
  defineElement,
  baseStyles,
  MteContentBase,
  ResponsiveValue,
  mapResponsiveNumberToPx,
  ResponsiveValueConverter,
  WaitForFrameworkMixin,
} from '../../core';
import { styles } from './content-grid.styles';
import { selectorFactory } from '@mortar/styles';

/**
 * @slot - Default slotted content
 */
@defineElement('mte-content-grid')
export class MteContentGrid extends WaitForFrameworkMixin(MteContentBase) {
  static styles = [baseStyles, styles];

  /** Determines the number of cols in the grid. By default `auto-fit` will scale to fit all items. */
  @property({ reflect: true, converter: ResponsiveValueConverter }) cols?: ResponsiveValue<
    string | number
  >;

  /** Determines the strict width of grid items (when they will wrap). */
  @property({ reflect: true, converter: ResponsiveValueConverter }) colWidth?: ResponsiveValue<
    string | number
  >;

  /** Determines the minimum width of grid items (when they will wrap). */
  @property({ reflect: true, converter: ResponsiveValueConverter }) colMinWidth?: ResponsiveValue<
    string | number
  >;

  /** When set, all rows will stretch to the same size. */
  @property({ type: Boolean, reflect: true }) withAutoRows = false;

  /** Adjusts `grid-auto-flow`. Defaults to `row`. */
  @property({ reflect: true }) autoFlow: 'row' | 'dense' | 'column' | 'column dense' | undefined;

  protected updateInstanceStyles(changedProps) {
    super.updateInstanceStyles(changedProps);

    if (
      ['cols', 'colWidth', 'colMinWidth', 'withAutoRows', 'autoFlow'].some((prop) =>
        changedProps.has(prop)
      )
    ) {
      this.setInstanceStyle('mte-grid', selectorFactory(this.instanceStyleSelectorRoot), {
        '--grid-cols': this.cols,
        '--grid-item-width': mapResponsiveNumberToPx(this.colWidth),
        '--grid-item-min-width': mapResponsiveNumberToPx(this.colMinWidth),
        'grid-auto-rows': this.withAutoRows ? '1fr' : null,
        'grid-auto-flow': this.autoFlow,
      });
    }
  }

  render() {
    return html`${this.instanceStyles}<slot></slot>`;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'mte-content-grid': MteContentGrid;
  }
}
