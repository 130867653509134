import { Presets } from '../../core';

export interface SegmentedControlPreset {}

export const segmentedControlPresets: Presets<SegmentedControlPreset> = {
  default: {},
  cx: {},
  rx: {},
  hw: {},
};
