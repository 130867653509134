import { Constructor, MteElement } from '../';
import { property } from 'lit/decorators.js';

export declare class LangInterface {
  /**
   * Adjusts the lang used to format content rendered by this element
   * @attr lang
   */
  lang: string;
}

export const LangMixin = <T extends Constructor<MteElement>>(superClass: T) => {
  class LangElement extends superClass {
    /**
     * Adjusts the lang used to format content rendered by this element
     * @attr lang
     */
    @property({ reflect: true }) lang: string;
  }
  return LangElement as Constructor<LangInterface> & T;
};
