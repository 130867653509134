import { Presets } from '../../core';

export interface RangeSliderPreset {}

export const rangeSliderPresets: Presets<RangeSliderPreset> = {
  default: {},
  cx: {},
  rx: {},
  hw: {},
};
