import { ReactiveControllerHost } from 'lit';
import { MtePresetService } from '../presets';
import { computed } from '../utilities';
import { MteElement } from '../base-classes';

const globalPreset$ = MtePresetService.selectPreset('global');
const globalPresetName$ = computed(globalPreset$, (preset) => preset.preset);

export interface AppliedPresetContext {
  presetName: string;
}

/**
 * Preset is determined from either the parent preset context or a local preset property.
 * This controller keeps track of the actual active preset applied to the current component.
 * If a callback is given it will be called any time that context changes. Otherwise controller
 * methods can be called when needed.
 *
 * This can be used even within elements that do not have their own preset
 * options (aka extend the presetMixin).
 */
export class AppliedPresetContextController {
  private appliedPresetName = 'global';
  private activeGlobalPresetName = 'default';

  constructor(
    private host: ReactiveControllerHost & MteElement,
    private callback?: (context: AppliedPresetContext) => void
  ) {
    this.host.addController(this);
    this.callback = callback;
  }

  hostConnected() {
    this.host.subs.push(
      globalPresetName$.subscribe((globalPresetName) => {
        this.activeGlobalPresetName = globalPresetName;
        this.updateAppliedPresetContext();
      })
    );
    this.execCallback();
  }

  hostUpdate() {
    this.updateAppliedPresetContext();
  }

  private execCallback() {
    this.callback?.({
      presetName: this.appliedPresetName,
    } as AppliedPresetContext);
  }

  private updateAppliedPresetContext() {
    let updated = false;
    const newPresetName = (this.host as any).appliedPreset ?? this.activeGlobalPresetName;
    if (newPresetName !== this.appliedPresetName) {
      this.appliedPresetName = newPresetName;
      updated = true;
    }
    if (updated) {
      this.execCallback();
    }
  }

  public getActivePresetName() {
    return this.appliedPresetName;
  }
}
