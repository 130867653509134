import { Constructor, MteElement } from '../';
import { presetProperty } from '../presets/preset.decorator';
import { PresetElementInterface } from '../presets/preset.mixin';

export type SizeOptions = 'md' | 'xs' | 'sm' | 'lg' | 'xl' | undefined;

export declare class SizeInterface {
  /**
   * Adjusts the scale of this component
   * @attr size
   */
  size: SizeOptions;
}

export const SizeMixin = <T extends Constructor<MteElement & Partial<PresetElementInterface<any>>>>(
  superClass: T
) => {
  class SizeElement extends superClass {
    /**
     * Adjusts the scale of this component
     * @attr size
     */
    @presetProperty({ reflect: true }) size: SizeOptions;
  }
  return SizeElement as Constructor<SizeInterface> & T;
};
