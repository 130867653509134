import { Presets, CorePalette } from '../../core';

export interface CheckboxPreset {
  color?: CorePalette;
}

export const checkboxPresets: Presets<CheckboxPreset> = {
  default: {},
  cx: {
    color: 'secondary',
  },
  rx: {},
  hw: {},
};
