import { html } from 'lit';
import { property } from 'lit/decorators.js';
import {
  baseStyles,
  defineElement,
  MteContentBase,
  ResponsiveValue,
  ResponsiveValueConverter,
  WaitForFrameworkMixin,
} from '../../core';
import { styles } from './stack.styles';

/**
 * @slot - Default slotted content
 */
@defineElement('mte-stack')
export class MteStack extends WaitForFrameworkMixin(MteContentBase) {
  static styles = [baseStyles, styles];

  /** Sets the `flex-direction` and `align-items` css properties on this element. Defaults to column */
  @property({ reflect: true, converter: ResponsiveValueConverter }) direction: ResponsiveValue<
    undefined | 'row' | 'rowReverse' | 'column' | 'columnReverse' | 'initial' | 'inherit'
  > = 'column';

  /** Shorthand for easily setting `direction="row"` */
  @property({ type: Boolean, reflect: true }) row = false;

  render() {
    return html`${this.instanceStyles}<slot></slot>`;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'mte-stack': MteStack;
  }
}
