import { Constructor, MteElement, PresetElementInterface, presetProperty } from '../';

/** Possible core palette group color values. */
export type CorePalette = 'primary' | 'secondary' | 'tertiary' | 'danger' | undefined;

/**  Possible status palette group color values. */
export type StatusPalette =
  | 'primary'
  | 'secondary'
  | 'tertiary'
  | 'accent'
  | 'warning'
  | 'success'
  | 'danger'
  | undefined;

/** Possible viz palette group color values. */
export type VizPalette = '1' | '2' | '3' | '4' | '5' | '6' | undefined;

export type PaletteGroupColor = CorePalette | StatusPalette | VizPalette;

export declare class ColorInterface<PaletteGroup> {
  /**
   * Adjusts the primary color palette used to theme this component
   * @attr color
   */
  color: PaletteGroup;
}

export enum PaletteGroupOption {
  Core = 'core',
  Status = 'status',
  Viz = 'viz',
}

export const CoreColorMixin = <
  T extends Constructor<MteElement & Partial<PresetElementInterface<any>>>
>(
  superClass: T
) => {
  class ColorElement extends superClass {
    /**
     * Adjusts the primary color palette used to theme this component
     * @attr color
     */
    @presetProperty({ reflect: true }) color: CorePalette;
  }

  return ColorElement as Constructor<ColorInterface<CorePalette>> & T;
};

export const StatusColorMixin = <
  T extends Constructor<MteElement & Partial<PresetElementInterface<any>>>
>(
  superClass: T
) => {
  class ColorElement extends superClass {
    /**
     * Adjusts the primary color palette used to theme this component
     * @attr color
     */
    @presetProperty({ reflect: true }) color: StatusPalette;
  }

  return ColorElement as Constructor<ColorInterface<StatusPalette>> & T;
};

export const VizColorMixin = <
  T extends Constructor<MteElement & Partial<PresetElementInterface<any>>>
>(
  superClass: T
) => {
  class ColorElement extends superClass {
    /**
     * Adjusts the primary color palette used to theme this component
     * @attr color
     */
    @presetProperty({ reflect: true }) color: VizPalette;
  }

  return ColorElement as Constructor<ColorInterface<VizPalette>> & T;
};
