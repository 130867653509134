/* eslint-disable no-empty */
import {
  config as getterConfig,
  setConfig as setterConfig,
  MortarThemeConfig,
} from '@mortar/styles';
import { Subset } from '../types';
import { isSsr, signal } from '../utilities';

export type MteTheme = 'default' | 'pxLight' | 'cxLight' | 'rxLight';

export type MteColorScheme = 'light' | 'dark' | 'system';

const CACHE_TO_KEY = 'mte.theme.cache-to';
const COLOR_SCHEME_KEY = 'mte.theme.global-color-scheme';

class _MteThemeService {
  config = getterConfig;

  private rootObserver?: MutationObserver;
  private themeSignal = signal<MteTheme | null>(this.getGlobalTheme());
  private colorSchemeSignal = signal<MteColorScheme | null>(this.getGlobalColorScheme() ?? 'light');

  constructor() {
    this.initCache();
  }

  /** Configures the `MteThemeService` to cache color-scheme changes to `localStorage`, `sessionStorage`, or neither. */
  public cacheColorSchemeTo(method: 'localStorage' | 'sessionStorage' | 'none') {
    if (!method) {
      return;
    }
    // Clear all keys from all storage locations if none
    if (method === 'none') {
      try {
        localStorage.removeItem(CACHE_TO_KEY);
        localStorage.removeItem(COLOR_SCHEME_KEY);
      } catch (e) {}
      try {
        sessionStorage.removeItem(CACHE_TO_KEY);
        sessionStorage.removeItem(COLOR_SCHEME_KEY);
      } catch (e) {}
      return;
    }
    const currentScheme = this.colorSchemeSignal.get();
    // Set cache method in storage - Try localStorage first
    try {
      localStorage.setItem(CACHE_TO_KEY, method);
      if (currentScheme) {
        localStorage.setItem(COLOR_SCHEME_KEY, currentScheme);
      }
    } catch (e) {
      console.error(
        'MteThemeService: Could not set color scheme cache method to `localStorage`. Attempting fallback to `sessionStorage`. Caching will only happen to sessionStorage.'
      );
      // Try sessionStorage next
      try {
        sessionStorage.setItem(CACHE_TO_KEY, 'sessionStorage');
        if (currentScheme) {
          sessionStorage.setItem(COLOR_SCHEME_KEY, currentScheme);
        }
      } catch (e) {
        console.error(
          'MteThemeService: Could not set color scheme cache method to `localStorage` or `sessionStorage`. Caching not enabled.'
        );
      }
    }
  }

  /** Sets the current global theme on the document root */
  public setGlobalTheme(theme: MteTheme) {
    if (!theme || typeof theme !== 'string') {
      throw new Error('MteThemeService: Global theme param must be a string.');
    }
    this.initObserver();
    if (theme !== 'default') {
      globalThis?.document?.documentElement?.setAttribute('data-mte-theme', theme);
    } else {
      globalThis?.document?.documentElement?.removeAttribute('data-mte-theme');
    }
  }

  /** Sets the current global color-scheme on the document root */
  public setGlobalColorScheme(colorScheme: MteColorScheme) {
    if (!colorScheme || typeof colorScheme !== 'string') {
      throw new Error('MteThemeService: Global colorScheme param must be a string.');
    }
    const scheme = colorScheme.toLowerCase();
    if (scheme === 'dark' || scheme === 'light' || scheme === 'system') {
      globalThis?.document?.documentElement?.setAttribute('data-mte-color-scheme', scheme);
      this.setCacheValue(COLOR_SCHEME_KEY, colorScheme);
    } else {
      globalThis?.document?.documentElement?.removeAttribute('data-mte-color-scheme');
    }
  }

  /** Returns the current global theme from the document root */
  public getGlobalTheme(): MteTheme | null {
    const value = globalThis?.document?.documentElement?.getAttribute('data-mte-theme') as MteTheme;
    if (!['default', 'pxLight', 'cxLight', 'rxLight'].includes(value)) {
      return null;
    } else {
      return value as MteTheme;
    }
  }

  /** Returns the current global color-scheme from the document root */
  public getGlobalColorScheme(): MteColorScheme | null {
    const value = globalThis?.document?.documentElement?.getAttribute(
      'data-mte-color-scheme'
    ) as MteColorScheme;
    if (!['light', 'dark', 'system'].includes(value)) {
      return null;
    } else {
      return value as MteColorScheme;
    }
  }

  /** Returns a reactive signal that will update any time the global theme changes */
  public selectGlobalTheme() {
    this.initObserver();
    return this.themeSignal;
  }

  /** Returns a reactive signal that will update any time the global color-scheme changes */
  public selectGlobalColorScheme() {
    this.initObserver();
    return this.colorSchemeSignal;
  }

  /** Sets the value of all of the given theme variables on the document root */
  public set(
    configFnOrObj:
      | Subset<MortarThemeConfig>
      | ((config: MortarThemeConfig) => Subset<MortarThemeConfig>)
  ) {
    if (typeof configFnOrObj === 'function') {
      configFnOrObj = configFnOrObj(this.config);
    }
    const mappings = this.flattenPaths(configFnOrObj);
    mappings.forEach(([path, val]) => {
      const cssVar = path.reduce((acc, prop) => {
        return acc[prop];
      }, setterConfig);
      globalThis?.document?.documentElement?.style.setProperty(cssVar, `${val}`);
    });
  }

  /** Retrieves the value for the requested theme variable from the document root */
  public get<T>(k1: (themeConfig: MortarThemeConfig) => T): T;
  public get<K1 extends keyof MortarThemeConfig>(k1: K1): MortarThemeConfig[K1];
  /** @internal **/
  public get<K1 extends keyof MortarThemeConfig, K2 extends keyof MortarThemeConfig[K1]>(
    k1: K1,
    k2: K2
  ): MortarThemeConfig[K1][K2];
  /** @internal **/
  public get<
    K1 extends keyof MortarThemeConfig,
    K2 extends keyof MortarThemeConfig[K1],
    K3 extends keyof MortarThemeConfig[K1][K2]
  >(k1: K1, k2: K2, k3: K3): MortarThemeConfig[K1][K2][K3];
  /** @internal **/
  public get<
    K1 extends keyof MortarThemeConfig,
    K2 extends keyof MortarThemeConfig[K1],
    K3 extends keyof MortarThemeConfig[K1][K2],
    K4 extends keyof MortarThemeConfig[K1][K2][K3]
  >(k1: K1, k2: K2, k3: K3, k4: K4): MortarThemeConfig[K1][K2][K3][K4];
  /** @internal **/
  public get<
    K1 extends keyof MortarThemeConfig,
    K2 extends keyof MortarThemeConfig[K1],
    K3 extends keyof MortarThemeConfig[K1][K2],
    K4 extends keyof MortarThemeConfig[K1][K2][K3],
    K5 extends keyof MortarThemeConfig[K1][K2][K3][K4]
  >(k1: K1, k2: K2, k3: K3, k4: K4, k5: K5): MortarThemeConfig[K1][K2][K3][K4][K5];
  /** @internal **/
  public get<
    K1 extends keyof MortarThemeConfig,
    K2 extends keyof MortarThemeConfig[K1],
    K3 extends keyof MortarThemeConfig[K1][K2],
    K4 extends keyof MortarThemeConfig[K1][K2][K3],
    K5 extends keyof MortarThemeConfig[K1][K2][K3][K4],
    K6 extends keyof MortarThemeConfig[K1][K2][K3][K4][K5]
  >(k1: K1, k2: K2, k3: K3, k4: K4, k5: K5, k6: K6): MortarThemeConfig[K1][K2][K3][K4][K5][K6];
  /** @internal **/
  public get<
    K1 extends keyof MortarThemeConfig,
    K2 extends keyof MortarThemeConfig[K1],
    K3 extends keyof MortarThemeConfig[K1][K2],
    K4 extends keyof MortarThemeConfig[K1][K2][K3],
    K5 extends keyof MortarThemeConfig[K1][K2][K3][K4],
    K6 extends keyof MortarThemeConfig[K1][K2][K3][K4][K5]
  >(
    ...keys:
      | [K1]
      | [K1, K2]
      | [K1, K2, K3]
      | [K1, K2, K3, K4]
      | [K1, K2, K3, K4, K5]
      | [K1, K2, K3, K4, K5, K6]
  ): any {
    const [fn] = keys ?? [null];
    if (typeof fn === 'function') {
      const cssVar = (fn as any)(setterConfig);
      return getComputedStyle(globalThis?.documentElement).getPropertyValue(cssVar);
    }
    const cssVar = keys.reduce((acc: any, key) => acc[key], setterConfig as any) as string;
    return getComputedStyle(globalThis?.documentElement).getPropertyValue(cssVar);
  }

  /** Flattens a given object to an array of path keys and its resulting value */
  private flattenPaths(obj: Record<string, any>, path: string[] = []): [string[], any][] {
    return Object.keys(obj).reduce((acc, key) => {
      const nested = obj[key];
      if (typeof nested === 'object') {
        path.push(key);
        Object.assign(acc, this.flattenPaths(nested, path));
      } else {
        path.push(key);
        acc.push([path, nested]);
      }
      return acc;
    }, []);
  }

  private handleMutationEvent = (mutationList, observer) => {
    for (const mutation of mutationList) {
      if (mutation.type === 'attributes') {
        if (mutation.attributeName === 'data-mte-color-scheme') {
          this.colorSchemeSignal.set(this.getGlobalColorScheme());
        } else if (mutation.attributeName === 'data-mte-theme') {
          this.themeSignal.set(this.getGlobalTheme());
        }
      }
    }
  };

  private initCache() {
    if (!isSsr()) {
      this.initObserver();
      try {
        const cacheColorScheme = this.getCacheValue(COLOR_SCHEME_KEY);
        if (cacheColorScheme && cacheColorScheme !== '') {
          this.setGlobalColorScheme(cacheColorScheme as MteColorScheme);
        }
      } catch (e) {}
    }
  }

  private initObserver() {
    if (!isSsr() && !this.rootObserver) {
      this.rootObserver = new MutationObserver(this.handleMutationEvent);
      this.rootObserver.observe(globalThis?.document?.documentElement, {
        attributes: true,
        attributeFilter: ['data-mte-theme', 'data-mte-color-scheme'],
      });
    }
  }

  private getCacheMethod(): 'localStorage' | 'sessionStorage' | 'none' | null {
    // Retrieve cache method
    let cacheMethod: 'localStorage' | 'sessionStorage' | 'none' | null = null;
    // Try localStorage
    try {
      const val = localStorage.getItem(CACHE_TO_KEY);
      if (val) {
        cacheMethod = val as 'localStorage' | 'sessionStorage' | 'none';
      }
    } catch (e) {
      // Try sessionStorage
      try {
        const val = sessionStorage.getItem(CACHE_TO_KEY);
        if (val) {
          cacheMethod = val as 'localStorage' | 'sessionStorage' | 'none';
        }
      } catch (e) {}
    }
    return cacheMethod;
  }

  private setCacheValue(key: string, value: string) {
    const cacheMethod = this.getCacheMethod();
    try {
      if (cacheMethod === 'localStorage') {
        localStorage.setItem(key, value);
      } else if (cacheMethod === 'sessionStorage') {
        sessionStorage.setItem(key, value);
      }
    } catch (e) {}
  }

  private getCacheValue(key: string) {
    const cacheMethod = this.getCacheMethod();
    try {
      if (cacheMethod === 'localStorage') {
        return localStorage.getItem(key);
      } else if (cacheMethod === 'sessionStorage') {
        return sessionStorage.getItem(key);
      }
    } catch (e) {}
    return null;
  }
}

export const MteThemeService = new _MteThemeService();
