import { Constructor, MteElement } from '../';
import { presetProperty } from '../presets/preset.decorator';
import { PresetElementInterface } from '../presets/preset.mixin';

export type RadiusOptions = 'sm' | 'md' | 'lg' | 'none' | undefined;

export declare class RadiusInterface {
  /**
   * Adjusts the border-radius of this component
   * @attr radius
   */
  radius: RadiusOptions;
}

export const RadiusMixin = <
  T extends Constructor<MteElement & Partial<PresetElementInterface<any>>>
>(
  superClass: T
) => {
  class RadiusElement extends superClass {
    /**
     * Adjusts the border-radius of this component
     * @attr radius
     */
    @presetProperty({ reflect: true }) radius: RadiusOptions;
  }
  return RadiusElement as Constructor<RadiusInterface> & T;
};
