import { isPlatform } from '../utilities';

class _MteScrollLockService {
  private previousScrollPosition: { scrollX: number; scrollY: number } = {
    scrollX: null,
    scrollY: null,
  };
  private enabled = false;

  apply() {
    if (this.canEnable()) {
      const isIOS = isPlatform('IOS');
      const root = globalThis.document.documentElement;
      const body = globalThis.document.body;
      const bodyStyle = body.style;

      const scrollbarX = Math.round(root.getBoundingClientRect().left) + root.scrollLeft;
      const paddingProp = scrollbarX ? 'paddingLeft' : 'paddingRight';
      const scrollbarWidth = window.innerWidth - document.documentElement.clientWidth;
      const scrollX = bodyStyle.left
        ? parseFloat(bodyStyle.left)
        : globalThis.scrollX ?? globalThis.pageXOffset;
      const scrollY = bodyStyle.top
        ? parseFloat(bodyStyle.top)
        : globalThis.scrollY ?? globalThis.pageYOffset;

      // Cache initial scroll position so it can be restored later on mobile devices
      this.previousScrollPosition = { scrollX, scrollY };

      bodyStyle.overflow = 'hidden';
      body.classList.add('mte-scroll-lock--active');

      if (scrollbarWidth) {
        bodyStyle[paddingProp] = `${scrollbarWidth}px`;
      }

      // Only iOS doesn't respect `overflow: hidden` on document.body, and this
      // technique has fewer side effects.
      if (isIOS) {
        // iOS 12 does not support `visualViewport`.
        const offsetLeft = globalThis.visualViewport?.offsetLeft || 0;
        const offsetTop = globalThis.visualViewport?.offsetTop || 0;

        Object.assign(bodyStyle, {
          position: 'fixed',
          top: `${-(scrollY - Math.floor(offsetTop))}px`,
          left: `${-(scrollX - Math.floor(offsetLeft))}px`,
          right: '0',
        });
      }

      this.enabled = true;
    }
  }

  remove() {
    if (this.enabled) {
      const isIOS = isPlatform('IOS');
      const bodyStyle = document.body.style;
      const scrollbarX =
        Math.round(document.documentElement.getBoundingClientRect().left) +
        document.documentElement.scrollLeft;
      const paddingProp = scrollbarX ? 'paddingLeft' : 'paddingRight';
      const { scrollX, scrollY } = this.previousScrollPosition;
      this.enabled = false;
      this.previousScrollPosition = { scrollX: null, scrollY: null };

      Object.assign(bodyStyle, {
        overflow: '',
        [paddingProp]: '',
      });
      document.body.classList.remove('mte-scroll-lock--active');

      // Remove iOS-specific workaround styles
      if (isIOS) {
        Object.assign(bodyStyle, {
          position: '',
          top: '',
          left: '',
          right: '',
        });

        // Restore initial scroll position instantly
        globalThis.scrollTo({ left: scrollX, top: scrollY, behavior: 'instant' });
      }
    }
  }

  private canEnable() {
    return !(
      globalThis.document.body.classList.contains('mte-scroll-lock--active') || this.enabled
    );
  }
}

export const MteScrollLockService = new _MteScrollLockService();
