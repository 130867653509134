import { property } from 'lit/decorators.js';
import { LitElement } from 'lit';
import { Constructor } from '../';
import { presetProperty } from '../presets/preset.decorator';

export declare class InverseInterface {
  /** Renders this component with inverse theming */
  inverse: boolean;

  /** Special option to override inverse when it is set by a preset */
  withoutInverse: boolean;
}

export const InverseMixin = <T extends Constructor<LitElement>>(superClass: T) => {
  class InverseElement extends superClass {
    /** Renders this component with inverse theming */
    @presetProperty({ type: Boolean, reflect: true }) inverse = false;

    /** Special option to override inverse when it is set by a preset */
    @property({ type: Boolean, reflect: true }) withoutInverse = false;
  }
  return InverseElement as Constructor<InverseInterface> & T;
};
