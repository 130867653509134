import { MteButton } from './button.element';
import { MteIconButton } from './icon-button.element';

export const registerButtonElements = () => {
  new MteButton();
  new MteIconButton();
};

export * from './button.element';
export * from './icon-button.element';
